@import"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 24px;
    scroll-behavior: smooth;
    font-weight: 400;
    background-color: #f0f0f0 !important;
    padding-right: 0px !important
}

html {
    background-color: #f0f0f0 !important;
    overflow-y: overlay
}

a {
    text-decoration: none !important;
    color: inherit !important
}

.w-img img {
    width: 100%
}

.m-img img {
    max-width: 100%
}

a, .btn, button, span, p, i, input, select, textarea, li, img, svg path, *::after, *::before, .transition-3, h1, h2, h3, h4, h5, h6 {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus, .button:focus {
    text-decoration: none;
    outline: none
}

a:focus, a:hover {
    color: #7d6be0;
    text-decoration: none
}

a {
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0);
    color: #7d6be0 !important
}

.anchorWhite {
    color: #fff !important
}

button:hover {
    cursor: pointer
}

button:focus {
    outline: 0;
    border: 0
}

.uppercase {
    text-transform: uppercase
}

.capitalize {
    text-transform: capitalize
}

h1, h2, h3, h4, h5, h6 {
    color: #2c3941;
    line-height: 24px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 36px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 20px
}

h5 {
    font-size: 16px
}

h6 {
    font-size: 14px
}

ul {
    margin: 0px;
    padding: 0px
}

li {
    list-style: none
}

p {
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
    margin-bottom: 0px
}

*::-moz-placeholder {
    color: #2c3941;
    font-size: 14px;
    opacity: 1
}

*::placeholder {
    color: #2c3941;
    font-size: 14px;
    opacity: 1
}

.fix {
    overflow: hidden
}

.clear {
    clear: both
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.z-index-1 {
    z-index: 1
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible
}

.p-relative {
    position: relative
}

.react-read-more-read-less.react-read-more-read-less-less {
    color: #333 !important;
    font-weight: 500 !important
}

.react-read-more-read-less.react-read-more-read-less-more {
    color: #333 !important;
    font-weight: 500 !important
}

.content_body {
    padding-top: 75px;
    margin-bottom: 80px;
    position: relative
}

.communityInfo_body {
    height: 10vh;
    margin-bottom: 80px;
    position: relative
}

.media_content_body {
    padding-top: 60px;
    margin-bottom: 50px;
    position: relative
}

.myItem {
    background-image: linear-gradient(to right, #7d6be0, #8367da, #8964d4, #8e60cd, #925dc7);
    height: 80px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    line-height: 80px;
    font-weight: 500;
    color: #fff;
    font-size: 22px;
    margin-bottom: 10px;
    cursor: pointer
}

.modal-title {
    font-size: 20px;
    color: #333
}

.MuiContainer-root {
    max-width: 1280px !important
}

.sun-editor .se-toolbar {
    z-index: auto !important
}

.item_center {
    display: flex;
    justify-content: center
}

.item_justify {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.text-end {
    text-align: end
}

.mt-10 {
    margin-top: 10px
}

.mb-10 {
    margin-bottom: 10px
}

.mt-20 {
    margin-top: 20px
}

.d-flex {
    display: flex
}

.align-center {
    align-items: center
}

.width-100 {
    width: 100%
}

.opacity-0 {
    opacity: 0 !important
}

.cursor-pointer {
    cursor: pointer
}

.dotted_border {
    border: 2px #6610f2 dotted
}

.header {
    background-color: #7d6be0;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
}

.header .header_content {
    width: 100%
}

.header .header_content .header_item {
    display: flex
}

.header .header_content .header_item .logo {
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.header .header_content .header_item .logo img {
    max-height: 100%;
    max-width: 100%
}

.header .header_content .header_item .header_search {
    display: flex;
    align-items: center;
    margin-left: 20px
}

.header .header_content .header_item .header_search .search_box {
    max-width: 245px;
    width: 245px;
    position: relative;
    height: 45px
}

.header .header_content .header_item .header_search .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 8px;
    border: 1px solid #dededf;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.header .header_content .header_item .header_search .search_box form .form_control:focus {
    outline: 0
}

.header .header_content .header_item .header_search .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.header .header_content .header_item .header_search .search_box form .form_control:optional {
    color: #636262
}

.header .header_content .header_item .header_search .search_box form i {
    font-size: 20px;
    color: #7d6be0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer
}

.header .header_content .header_item .header_search .notifi_list ul li {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 0 12px
}

.header .header_content .header_item .header_search .notifi_list ul li i {
    font-size: 28px;
    color: #fff
}

.header .header_content .header_item .header_search .notifi_list ul li .counting_overly {
    position: absolute;
    top: -10px;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    text-align: center
}

.header .header_content .header_item .header_search .notifi_list ul li .counting_overly h5 {
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    line-height: 21px
}

.header .header_content .header_item .commuinity_name {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #fff;
    font-weight: 500;
    font-size: 20px
}

.header .header_content .header_item_wrapper {
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
    justify-content: center
}

.header .header_content .header_item_wrapper .header_item_list {
    height: 43px;
    width: 43px;
    background-color: rgba(220, 215, 215, .41);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px
}

.header .header_content .header_item_wrapper .header_item_list .MuiSvgIcon-root {
    font-size: 26px;
    color: #fff
}

.header .header_content .header_item_wrapper .header_item_list img {
    height: 100%;
    width: 65%;
    object-fit: contain
}

.header .header_content .header_item_wrapper2 {
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
    justify-content: end
}

.header .header_content .header_item_wrapper2 .header_item_list {
    height: 43px;
    width: 43px;
    background-color: rgba(220, 215, 215, .41);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px
}

.header .header_content .header_item_wrapper2 .header_item_list .MuiSvgIcon-root {
    font-size: 26px;
    color: #fff
}

.header .header_content .header_item_wrapper2 .header_item_list img {
    height: 28px;
    width: 28px;
    object-fit: contain
}

.header .header_content .header_item_wrapper2 .profile_dashed {
    margin-left: 10px
}

.header .header_content .header_item_wrapper2 .profile_dashed .MuiButtonBase-root {
    padding: 0px !important
}

.header .header_content .header_item_wrapper2 .profile_dashed .profile_img {
    height: 50px;
    width: 50px;
    border: 5px solid rgba(225, 223, 223, .8235294118);
    border-radius: 50%
}

.header .header_content .header_item_wrapper2 .profile_dashed .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper {
    width: 300px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    border-radius: 4px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_profile {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    background-color: rgba(207, 214, 223, .5019607843);
    margin-bottom: 12px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_profile .userName {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    padding-left: 8px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list {
    padding: 6px 6px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list:hover {
    background-color: #f3f3f3;
    border-radius: 5px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list i {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: rgba(207, 214, 223, .5019607843);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list i .MuiSvgIcon-root {
    font-size: 22px;
    color: #333
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list span {
    font-size: 15px
}

.header .header_content .header_item_wrapper2 .mobile_hamburger_icon {
    height: 43px;
    width: 43px;
    border-radius: 50%;
    background-color: rgba(182, 181, 181, .6392156863);
    justify-content: center;
    align-items: center;
    display: none
}

.header .header_content .header_item_wrapper2 .mobile_hamburger_icon .MuiSvgIcon-root {
    font-size: 26px !important;
    color: #fff !important
}

.drawer_Wrapper {
    width: 300px;
    padding: 10px;
    position: relative;
    height: 100vh
}

.drawer_Wrapper .profile_dashed_mob {
    padding: 10px;
    background-color: rgba(223, 221, 221, .8666666667);
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 12px
}

.drawer_Wrapper .profile_dashed_mob .profile_img img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.drawer_Wrapper .profile_dashed_mob .user_name {
    font-size: 19px;
    font-weight: 500;
    color: #333;
    padding-left: 5px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.drawer_Wrapper .my_commuinity {
    display: flex;
    align-items: center;
    padding: 7px 5px
}

.drawer_Wrapper .my_commuinity:hover {
    background-color: #f3f3f3;
    border-radius: 7px
}

.drawer_Wrapper .my_commuinity i .MuiSvgIcon-root {
    font-size: 23px;
    color: #636262
}

.drawer_Wrapper .my_commuinity span {
    padding-left: 6px;
    font-size: 15px;
    color: #636262
}

.drawer_Wrapper .sidebar_list {
    padding-top: 10px
}

.drawer_Wrapper .sidebar_list .sidebar_list_item {
    display: flex;
    align-items: center;
    padding: 9px 10px
}

.drawer_Wrapper .sidebar_list .sidebar_list_item:hover {
    background-color: #f3f3f3;
    border-radius: 7px
}

.drawer_Wrapper .sidebar_list .sidebar_list_item .list_icon img {
    height: 22px;
    width: 22px;
    object-fit: contain
}

.drawer_Wrapper .sidebar_list .sidebar_list_item .list_name {
    font-size: 15px;
    font-weight: 400;
    color: #636262;
    padding-left: 10px
}

.drawer_Wrapper .logout_btn {
    margin: 30px 0;
    width: 280px;
    height: 48px;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    font-size: 17px;
    color: #333;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3
}

.chat_box_icon {
    position: fixed;
    right: 70px;
    bottom: 90px;
    background-color: #fff;
    border-radius: 35px;
    height: 75px;
    width: 75px;
    box-shadow: 4px 8px 8px 8px rgba(197, 194, 194, .1294117647);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer
}

.chat_box_icon:hover {
    box-shadow: 10px 10px 8px #eceaea
}

.chat_box_icon .chat_button {
    display: flex;
    align-items: center;
    justify-content: center
}

.chat_box_icon .chat_button img {
    height: 45px;
    width: 45px;
    object-fit: contain
}

.chat_box_icon .chat_button span {
    margin-left: 5px
}

.side-menu {
    position: fixed;
    width: 270px;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 0 10px
}

.side-menu .side_wrapper {
    margin-top: 90px
}

.side-menu .profile_main {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    transition: all linear .3s;
    border-radius: 13px;
    overflow: hidden;
    height: 250px;
    position: relative
}

.side-menu .profile_main .cover_photo {
    width: 100%;
    height: 120px;
    overflow: hidden
}

.side-menu .profile_main .cover_photo img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.side-menu .profile_main .profilr_debox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 12px
}

.side-menu .profile_main .profilr_debox .image_container {
    margin-top: -68px
}

.side-menu .profile_main .profilr_debox .image_container .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    border: 3px solid #fff;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box
}

.side-menu .profile_main .profilr_debox .image_container .profile_img img {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    object-fit: cover
}

.side-menu .profile_main .profilr_debox .followers_Box {
    text-align: center
}

.side-menu .profile_main .profilr_debox .followers_Box .followerIn_number {
    font-size: 18px;
    color: #333;
    font-weight: 600
}

.side-menu .profile_main .profilr_debox .followers_Box .f_Title {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 16px
}

.side-menu .profile_main .user_control {
    padding: 5px 0
}

.side-menu .profile_main .user_control .user_name {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}

.side-menu .profile_main .user_control .user_name a {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.side-menu .profile_main .user_control .user_name a:hover {
    transition: all linear .5s
}

.side-menu .profile_main .user_control .user_name i {
    margin-left: 7px
}

.side-menu .profile_main .user_control .user_name i .MuiSvgIcon-root {
    font-size: 18px;
    color: #4a4a4a
}

.side-menu .profile_main .user_control .user_profile_name {
    font-size: 13px;
    color: rgba(99, 99, 99, .9333333333);
    font-weight: 400;
    text-align: center;
    line-height: 20px;
    font-family: "Open Sans", sans-serif
}

.side-menu .profile_main .user_control .user_shortDiscription {
    font-size: 14px;
    color: #888;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    padding-top: 15px
}

.side-menu .profile_main .user_control .profileBtn {
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    background-color: #7d6be0;
    margin-top: 12px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    cursor: pointer
}

.side-menu .profile_main .user_control .profileBtn a {
    color: #fff !important
}

.side-menu .profile_main .user_control .profileBtn:hover {
    background-color: rgba(62, 47, 146, .8549019608)
}

.side-menu .sidebar_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    transition: all linear .3s;
    border-radius: 13px;
    overflow: hidden;
    padding: 10px;
    margin-top: 10px
}

.side-menu .sidebar_list ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.side-menu .sidebar_list ul li {
    display: flex;
    align-items: center;
    margin: 3px 4px;
    background-color: rgba(3, 40, 141, .0980392157);
    border-radius: 5px;
    height: 38px;
    padding: 0 10px
}

.side-menu .sidebar_list ul li span {
    padding-right: 5px;
    display: flex;
    align-items: center
}

.side-menu .sidebar_list ul li span img {
    max-width: 21px
}

.side-menu .sidebar_list ul li span .broadcast-side-img {
    max-width: 60px
}

.side-menu .sidebar_list ul li span {
    font-weight: 500;
    color: #888;
    font-size: 13px
}

.side-menu .sidebar_list ul li:hover {
    background-color: rgba(100, 111, 139, .3803921569)
}

.side-menu .sidebar_list ul li:hover span {
    color: #333
}

.side-menu .Commuinity_Detail {
    margin-top: 10px
}

.side-menu .Commuinity_Detail ul {
    text-align: center
}

.side-menu .Commuinity_Detail ul li {
    display: inline-block;
    font-size: 14px;
    color: #888;
    font-weight: 400;
    padding: 0 14px;
    cursor: pointer
}

.side-menu .Commuinity_Detail ul li:hover {
    color: #7d6be0
}

.backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    cursor: pointer;
    margin-bottom: 10px
}

.backArrow:hover {
    color: #333
}

.placeholder_text {
    text-align: center;
    color: rgba(0, 0, 0, .26);
    font-size: 20px;
    font-weight: 500;
    padding-top: 50px
}

.upload_loader {
    background-color: rgba(0, 0, 0, .6);
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999
}

.upload_loader .upload_loader_container h5 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    padding-top: 15px
}

.loader-5 {
    margin: 0 auto;
    height: 60px;
    width: 60px;
    -webkit-animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-1 {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes loader-5-1 {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.loader-5::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(24px, 0, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-2 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(24px, 0, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-3 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(-24px, 0, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-3 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(-24px, 0, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5 span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px
}

.loader-5 span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-4 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(0, 24px, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-4 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(0, 24px, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5 span::after {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-5 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(0, -24px, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-5 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(0, -24px, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.noticeHome .notice_heading {
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #7d6be0
}

.noticeHome .notice_heading .notice_header {
    font-size: 20px;
    font-weight: 500;
    color: #fff
}

.noticeHome .notice_heading .noticeAdder {
    height: 36px;
    width: 36px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center
}

.noticeHome .notice_heading .noticeAdder i {
    line-height: 37px;
    color: #7d6be0;
    font-size: 20px
}

.notice_item {
    background-color: #fff;
    padding: 20px 20px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-top: 13px;
    border-radius: 13px
}

.notice_item .notice_Title {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
    color: #333
}

.notice_item .profile_bio2 {
    display: flex;
    align-items: center;
    padding-top: 20px
}

.notice_item .profile_bio2 .profile_img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    overflow: hidden
}

.notice_item .profile_bio2 .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notice_item .profile_bio2 .profile_info {
    padding-left: 6px;
    width: 100%
}

.notice_item .profile_bio2 .profile_info ul {
    display: flex;
    justify-content: space-between
}

.notice_item .profile_bio2 .profile_info ul li {
    font-size: 13px;
    color: #888;
    font-size: 400
}

.notice_item .profile_bio2 .profile_info ul li .profile_user_name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 15px
}

.notice_item .profile_bio2 .profile_info ul .profile_title {
    color: #7d6be0;
    display: inline;
    padding: 2px 15px;
    background-color: rgba(123, 106, 224, .7019607843);
    border-radius: 15px;
    color: #fff
}

.notice_item .profile_bio2 .profile_info ul .profile_title i {
    font-size: 13px;
    cursor: pointer
}

.notice_item .profile_bio2 .profile_info .post_time {
    font-size: 12px;
    line-height: 6px;
    color: #5c5c5c;
    font-weight: 400
}

.notice_item .noticeContent {
    padding-top: 12px;
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 22px
}

.notice_item .noticeItem_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px
}

.notice_item .noticeItem_footer .noticeItem_footer_left {
    display: flex;
    align-items: center
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one {
    padding: 4px 12px;
    background-color: rgba(141, 123, 242, .2980392157);
    border-radius: 5px;
    margin-right: 12px;
    cursor: pointer
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one i {
    color: #5c5c5c
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one span {
    padding-left: 5px;
    font-size: 14px
}

.notice_item .noticeResponse {
    background-color: #ecebf6;
    padding: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 12px;
    display: flex;
    align-items: center
}

.notice_item .noticeResponse .commnetorProfile {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    overflow: hidden
}

.notice_item .noticeResponse .commnetorProfile img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notice_item .noticeResponse .commentor_placehold {
    width: 100%;
    margin-left: 7px;
    height: 38px
}

.notice_item .noticeResponse .commentor_placehold .form_control {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    height: 38px;
    padding: 13px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    font-size: 14px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.notice_item .noticeResponse .commentor_placehold .form_control:focus {
    outline: 0
}

.notice_item .noticeResponse .commentor_placehold .form_control::placeholder {
    font-size: 13px;
    color: #aeacac;
    font-weight: 400
}

.notice_item .noticeResponse .commentor_placehold .form_control:optional {
    color: #636262
}

.notice_Image_board {
    height: 450px;
    width: 100%;
    border-radius: 13px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.notice_Image_board .notice_Image_Top {
    height: 45%;
    padding: 10px
}

.notice_Image_board .notice_Image_Top .NoticeImg {
    height: 180px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.notice_Image_board .notice_Image_Top .NoticeImg img {
    max-height: 100%;
    max-width: 100%
}

.notice_Image_board .notice_Image_Bottom {
    height: 55%;
    background-color: #7d6be0;
    padding: 14px;
    text-align: center
}

.notice_Image_board .notice_Image_Bottom .notice_announce {
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    line-height: 42px;
    text-transform: uppercase;
    padding-top: 28px
}

.notice_Image_board .notice_Image_Bottom .notice_announce span {
    font-size: 24px;
    font-weight: 500
}

.notice_Image_board .notice_Image_Bottom p {
    font-size: 14px;
    color: #f3f3f3;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    padding-top: 10px
}

.mamber_hedingSIde {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px
}

.mamber_hedingSIde .headingSection {
    font-size: 20px;
    color: #333;
    font-weight: 500
}

.mamber_hedingSIde .headingSection span {
    margin-right: 7px;
    cursor: pointer
}

.mamber_hedingSIde .mamber_hedingSide_right {
    display: flex;
    align-items: center
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box {
    margin-left: 20px;
    width: 220px;
    position: relative
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box .form_control {
    width: 100%;
    height: 40px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 8px;
    border: 1px solid #dededf;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box .form_control:focus {
    outline: 0
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box .form_control:optional {
    color: #636262
}

.mamber_hedingSIde .mamber_hedingSide_right .search_box .MuiSvgIcon-root {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888
}

.mamber_hedingSIde .mamber_hedingSide_right .addMamber_btn {
    padding: 6px 16px;
    background-color: #7d6be0;
    border-radius: 6px;
    color: #fff !important;
    font-size: 14px
}

.mamber .mamber_wrapper .memberItem {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    height: 270px;
    border-radius: 12px
}

.mamber .mamber_wrapper .memberItem .user_info .user_img {
    height: 82px;
    width: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto
}

.mamber .mamber_wrapper .memberItem .user_info .user_img img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%
}

.mamber .mamber_wrapper .memberItem .user_info .user_name {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding: 10px 0 0 0;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.mamber .mamber_wrapper .memberItem .user_info .join_date {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    text-align: center;
    padding-bottom: 10px
}

.mamber .mamber_wrapper .memberItem .icon_list {
    display: flex;
    justify-content: center
}

.mamber .mamber_wrapper .memberItem .icon_list .icon {
    height: 37px;
    width: 37px;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .icon_list .icon .MuiSvgIcon-root {
    font-size: 21px;
    color: #333
}

.mamber .mamber_wrapper .memberItem .icon_list .icon img {
    height: 24px;
    width: 24px;
    object-fit: contain
}

.mamber .mamber_wrapper .memberItem .owner_member {
    text-align: center;
    background-color: #7d6be0;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase
}

.mamber .mamber_wrapper .memberItem .active_member {
    text-align: center;
    color: #888;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase
}

.mamber .mamber_wrapper .memberItem .memberApproved_btn {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    margin: 0 auto
}

.mamber .mamber_wrapper .memberItem .memberApproved_btn .MuiButtonBase-root {
    font-size: 12px
}

.mamber .mamber_wrapper .memberItem .memberCard_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_left {
    width: 85%
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right {
    width: 15%;
    display: flex;
    justify-content: end;
    position: relative
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right:hover .member_dropdown_Plate {
    display: block
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown {
    height: 34px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown:hover {
    background-color: rgba(232, 230, 230, .6745098039)
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate {
    width: 180px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: -5px;
    bottom: 26px;
    display: none;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    overflow: hidden
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate ul li {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate ul li:hover {
    background-color: #f3f3f3
}

.MemberSearch_body {
    background-color: #fff;
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.MemberSearch_body .memberSearch_wrapper {
    position: relative;
    width: 450px;
    margin-top: 20px
}

.MemberSearch_body .memberSearch_wrapper .form_contorl {
    height: 48px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    outline: 0;
    padding: 0 15px;
    font-size: 15px
}

.MemberSearch_body .memberSearch_wrapper .form_contorl::placeholder {
    color: #888
}

.MemberSearch_body .memberSearch_wrapper i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}

.MemberSearch_body .memberSearch_wrapper i .MuiSvgIcon-root {
    color: #888
}

.addMember_card {
    width: 540px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px
}

.dropdown_menuTab {
    width: 200px;
    padding: 0 6px
}

.dropdown_menuTab ul li {
    padding: 4px 5px;
    font-size: 15px;
    cursor: pointer
}

.dropdown_menuTab ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px
}

.dropdown_menuTab ul li:hover {
    background-color: rgba(221, 217, 217, .6941176471)
}

.leave_btn {
    width: 100%;
    background-color: #7d6be0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px
}

.home_banner {
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 12px
}

.home_banner img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.lounge {
    padding-bottom: 22px
}

.lounge .O_user_list {
    margin-bottom: 12px
}

.lounge .O_user_list .Other_User {
    height: 128px;
    width: 90px;
    padding: 10px 6px;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #d5d5d5;
    cursor: pointer
}

.lounge .O_user_list .Other_User .userImg_content {
    height: 72px;
    width: 72px;
    margin: 0 auto;
    border-radius: 50%
}

.lounge .O_user_list .Other_User .userImg_content img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.lounge .O_user_list .Other_User .userName {
    font-size: 12px;
    color: #888;
    font-weight: 500;
    line-height: 16px;
    padding-top: 4px
}

.lounge .O_user_list .swiper .swiper-wrapper .swiper-slide {
    width: 96px !important
}

.lounge .O_user_list .swiper .swiper-button-next {
    right: 0;
    height: 35px;
    width: 35px;
    background-color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 50%
}

.lounge .O_user_list .swiper .swiper-button-next::after {
    font-size: 18px;
    color: #333
}

.lounge .O_user_list .swiper .swiper-button-prev {
    left: 0;
    height: 35px;
    width: 35px;
    background-color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 50%
}

.lounge .O_user_list .swiper .swiper-button-prev::after {
    font-size: 18px;
    color: #333
}

.lounge .lounge_right_fixed {
    position: relative;
    width: 100%
}

.lounge .lounge_right_fixed .lounge_right_sticky {
    position: fixed;
    top: 75px;
    width: 21%
}

.lounge .upload_box_container {
    width: 100%;
    padding: 18px 18px 18px 18px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 20px;
    cursor: pointer
}

.lounge .upload_box_container .upload_box_top {
    display: flex
}

.lounge .upload_box_container .upload_box_top .uploader_profile {
    width: 45px;
    height: auto
}

.lounge .upload_box_container .upload_box_top .uploader_profile img {
    height: 44px;
    width: 44px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover
}

.lounge .upload_box_container .upload_box_top .uploader_input {
    width: 100%;
    margin-left: 8px
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control:focus {
    outline: 0
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control:optional {
    color: #636262
}

.lounge .upload_box_container .upload_box_bottom {
    padding-top: 5px;
    display: flex;
    justify-content: flex-end
}

.lounge .upload_box_container .upload_box_bottom .dec_item {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    display: flex;
    align-items: center
}

.lounge .upload_box_container .upload_box_bottom .dec_item .MuiSvgIcon-root {
    font-size: 22px
}

.lounge .upload_box_container .upload_box_bottom .photo_c .MuiSvgIcon-root {
    color: #378fe9
}

.lounge .upload_box_container .upload_box_bottom .tag_c .MuiSvgIcon-root {
    color: #c37d16
}

.lounge .upload_box_container .upload_box_bottom .location_c .MuiSvgIcon-root {
    color: #5f9b41
}

.longue_up {
    width: 100%;
    padding: 18px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 30px
}

.longue_up h4 {
    font-size: 20px;
    color: #7d6be0;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 8px;
    display: inline-block
}

.longue_up .profile_bio_of_upload {
    display: flex
}

.longue_up .profile_bio_of_upload .profile_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden
}

.longue_up .profile_bio_of_upload .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_up .profile_bio_of_upload .upload_item {
    padding-left: 6px;
    width: 100%
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    height: 70px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control:focus {
    outline: 0
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control:optional {
    color: #636262
}

.UploadModal_Title {
    text-align: center
}

.MuiDialog-paper {
    background-color: none !important;
    overflow: visible
}

.dialog_header {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(202, 221, 235, .937);
    position: relative;
    width: 100%
}

.dialog_header .closeBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    z-index: 3;
    cursor: pointer !important
}

.dialog_header .mediaBack_btn {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    background-color: #e3e2ec;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.dialog_header .mediaBack_btn .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center
}

.upload_input_area {
    max-height: 360px;
    overflow-y: hidden;
    overflow-x: hidden
}

.upload_input_area .Upload_textarea {
    width: 550px; 
    border: none;
    border-bottom: solid 1px #ccc;
}

.upload_input_area .Upload_textarea::placeholder {
    font-size: 22px;
    color: #888;
    font-weight: 400
}

.upload_input_area .Upload_textarea:focus-visible {
    outline: 0 !important
}

.MuiDialog-paper {
    border-radius: 10px !important;
    overflow: visible !important
}

.dialogContent-1 {
    background-color: #fff;
    border-radius: 12px
}

.dialogContent-2 {
    background-color: #fff;
    animation: scale-display .3s;
    border-radius: 12px
}

.dialogContent-2 .mediaAdd_Body .mediaAdder {
    width: 500px;
    padding: 20px;
    max-height: 460px;
    overflow-y: scroll;
    min-height: 260px
}

.dialogContent-2 .TagBody {
    width: 440px;
    min-height: 280px;
    max-height: 300px;
    overflow-y: scroll
}

.dialogContent-2 .TagBody .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.dialogContent-2 .TagBody .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid rgba(0, 0, 0, 0)
}

.dialogContent-2 .TagBody .css-1q60rmi-MuiAutocomplete-endAdornment {
    display: none
}

@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0)
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

.dialog_footer {
    width: 100%;
    padding: 0 13px 13px 13px;
    position: relative
}

.dialog_footer .emoji_picker {
    position: absolute;
    right: -200px;
    top: -249px;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239)
}

.dialog_footer .upload_attached {
    padding-top: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.dialog_footer .upload_attached ul {
    display: flex
}

.dialog_footer .upload_attached ul li {
    cursor: pointer;
    font-size: 16px;
    padding: 2px 15px;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 15px;
    margin-right: 12px;
    display: flex;
    align-items: center
}

.dialog_footer .upload_attached ul li:hover {
    background-color: #ebe9e9
}

.dialog_footer .upload_attached ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 4px
}

.dialog_footer .upload_attached ul li .small-text {
    font-size: 12px
}

.dialog_footer .uploader_btn_area {
    margin-top: 20px
}

.dialog_footer .uploader_btn_area .btn {
    width: 100%;
    color: #fff;
    background-color: #378fe9
}

.longue_item {
    width: 100%;
    padding: 6px 0 4px 0;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 10px
}

.longue_item .uploaded_img_container {
    position: relative;
    overflow: hidden
}

.longue_item .uploaded_img_container .single_img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 0
}

.longue_item .uploaded_img_container .single_img img {
    max-height: 600px;
    max-width: 100%;
    z-index: 1;
    cursor: pointer
}

.longue_item .uploaded_img_container .image_overly {
    position: absolute;
    transform: scale(1.05);
    filter: blur(15px);
    -webkit-filter: blur(15px);
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: .8;
    top: 0;
    left: 0
}

.longue_item .uploaded_img_container .image_overly img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_item .uploadede_item {
    position: relative;
    overflow: hidden;
    margin-top: 2px;
    border-top: 1px solid #d5d5d5
}

.longue_item .uploadede_item .image_container {
    height: 220px;
    width: 100%;
    overflow: hidden;
    cursor: pointer
}

.longue_item .uploadede_item .image_container img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_item .uploadede_item .image_container .extra_img_overly2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.longue_item .uploadede_item .image_container .extra_img_overly2 span {
    color: #fff;
    font-weight: 400;
    font-size: 40px
}

.longue_item .extra_img_relative {
    position: relative
}

.longue_item .uploaded_Video_container {
    width: 100%;
    overflow: hidden
}

.uploaded_Video_container .video-react {
    height: 420px !important;
    padding-top: 0px !important
}

.uploaded_Video_container .video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 420px !important
}

.profile_bio {
    display: flex;
    align-items: center;
    padding: 8px 15px
}

.profile_bio .profile_img {
    display: flex;
    width: 55px
}

.profile_bio .profile_img img {
    height: 40px !important;
    width: 40px !important;
    object-fit: cover;
    border-radius: 50%
}

.profile_bio .profile_info {
    padding-left: 2px;
    width: 100%
}

.profile_bio .profile_info .userName {
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.profile_bio .profile_info .userName a {
    font-size: 16px;
    color: #333;
    font-weight: 500
}

.profile_bio .profile_info .userName span {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    padding-left: 10px
}

.profile_bio .profile_info .post_timing {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 16px
}

.profile_bio .profile_info .post_timing .MuiSvgIcon-root {
    font-size: 16px
}

.profile_bio .postInfo {
    display: flex;
    align-items: center
}

.profile_bio .postInfo .post_back_btn {
    height: 40px;
    width: 40px;
    background-color: rgba(231, 228, 228, .8039215686);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px
}

.profile_bio .postInfo .post_back_btn .MuiSvgIcon-root {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888
}

.profile_bio .postInfo .dropmenus {
    z-index: 9999
}

.LoungeItemContent {
    padding-bottom: 3px
}

.LoungeItemContent .loungeContent {
    padding: 12px 15px 0 15px;
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 22px;
    white-space: pre-line
}

.LoungeItemContent .loungeContent .content {
    white-space: pre-line
}

.LoungeItemContent .loungeItem_footer {
    position: relative
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 5px 10px
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left {
    display: flex
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left .statusUpdate_item {
    font-size: 13px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    color: #888;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left .statusUpdate_item .MuiSvgIcon-root {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #42ba96
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_right .statusUpdate_item {
    font-size: 13px;
    cursor: pointer;
    color: #333
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status {
    margin-top: 2px;
    border-top: 1px solid #d5d5d5;
    padding: 5px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one .MuiSvgIcon-root {
    font-size: 19px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one:hover {
    background-color: rgba(3, 40, 141, .062745098);
    transition: all linear .5s;
    border-radius: 4px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one span {
    padding-left: 5px;
    font-size: 14px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one .emoji_icon {
    padding-left: 0px;
    font-size: 24px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left .footerLeft_one {
    padding: 4px 12px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left .footerLeft_one span {
    padding-left: 5px;
    font-size: 14px
}

.css-advny6-MuiPopper-root.MuiPopper-root {
    z-index: 2
}

.MuiPopper-root {
    z-index: 2 !important
}

.MuiPopper-root .dropdown_menu_item {
    font-size: 15px !important
}

.MuiPopper-root .dropdown_menu_item .MuiSvgIcon-root {
    font-size: 19px;
    margin-right: 2px
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-size: 13px
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px
}

.postView_title {
    text-align: center
}

.postView_container {
    height: auto;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 6px 0px 15px 0px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.postView_container .postView_top {
    padding-bottom: 3px
}

.postView_container .postView_container_Top .postViewModal_container_Image {
    height: 580px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333
}

.postView_container .postView_container_Top .postViewModal_container_Image img {
    max-height: 100%;
    max-width: 100%
}

.postView_container .postView_container_Bottom {
    padding-top: 6px;
    position: relative
}

.postView_container .postView_container_Bottom .postViewModal_middle .modal_comment_area {
    padding-right: 10px
}

.comment_input_main .comment_input_container {
    width: 100%;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px
}

.comment_input_main .comment_input_container .comment_input_area {
    max-height: 220px;
    overflow-y: scroll
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea {
    padding: 10px 10px 5px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    width: 100%;
    font-size: 14px
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.comment_input_main .comment_input_container .comment_attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 2px 8px;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
}

.comment_input_main .comment_input_container .comment_attachment ul {
    display: flex;
    align-items: center
}

.comment_input_main .comment_input_container .comment_attachment ul li {
    padding-right: 8px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer
}

.comment_input_main .comment_input_container .comment_attachment ul li .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px
}

.comment_input_main .comment_input_container .comment_attachment ul li .comment_emoji_picker {
    position: absolute;
    bottom: -260px;
    right: -290px;
    z-index: 999;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239)
}

.comment_input_main .comment_input_container .comment_attachment .send_btn .MuiSvgIcon-root {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center
}

.comment_input_main .comment_input_container .comment_media_visiable {
    height: 60px;
    width: 90px;
    margin-left: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.comment_input_main .comment_input_container .comment_media_visiable img {
    max-width: 100%;
    max-height: 100%
}

.comment_input_main .comment_input_container .comment_media_visiable .comment_media_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3)
}

.comment_input_main .comment_input_container .comment_media_visiable .comment_media_overly .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer
}

.comment_container {
    padding-right: 18px;
    max-height: 330px;
    overflow-x: hidden;
    overflow-y: scroll
}

.comment_container ul {
    margin-left: 20px
}

.comment_container .comment_ul li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative
}

.comment_container .comment_ul li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    width: 20px;
    height: 40px
}

.comment_container .comment_ul li:after {
    position: absolute;
    content: "";
    top: 29px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
    width: 20px;
    height: 100%
}

.comment_container .comment_ul li:last-child:after {
    display: none
}

.comment_container .comment_ul li .comment_wrapper {
    width: 100%;
    height: auto;
    background-color: #eee;
    border-radius: 8px;
    padding: 10px 10px 6px 10px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top {
    display: flex;
    justify-content: space-between
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left {
    display: flex
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .user_pic {
    width: 35px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .user_pic img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .left_info .userName {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .left_info .createDate {
    font-size: 11px;
    font-weight: 400;
    color: #888;
    line-height: 14px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list {
    padding: 3px 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list:hover {
    background-color: #eee
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list .MuiSvgIcon-root {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px
}

.comment_container .comment_ul li .comment_wrapper .comment_box {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    line-height: 18px;
    padding-top: 5px;
    white-space: pre-line
}

.comment_container .comment_ul li .comment_wrapper .comment_media .ant-image {
    height: 160px;
    max-height: 160px;
    width: auto !important;
    margin-top: 8px
}

.comment_container .comment_ul li .comment_wrapper .comment_media .ant-image img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.comment_container .comment_ul li .comment_wrapper .comment_footer {
    padding-top: 6px
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left {
    display: flex
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left span {
    margin-right: 10px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left .footer_react_item {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-right: 15px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left .footer_react_item:hover {
    color: #000
}

.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px
}

.reply_input_plate {
    width: 100%;
    background-color: #eee;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    background-color: #fff
}

.reply_input_plate .reply_input_area {
    max-height: 220px;
    overflow-y: scroll
}

.reply_input_plate .reply_input_area .comment_textarea {
    padding: 10px 10px 5px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border: none;
    width: 100%;
    font-size: 14px
}

.reply_input_plate .reply_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.reply_input_plate .reply_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.reply_input_plate .reply_attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 1px 8px
}

.reply_input_plate .reply_attachment .reply_attachment_left {
    display: flex
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item {
    padding-right: 8px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item .reply_emoji_picker {
    position: absolute;
    bottom: -260px;
    right: -280px;
    z-index: 999;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    cursor: pointer
}

.reply_input_plate .reply_attachment .send_btn .MuiSvgIcon-root {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center
}

.reply_input_plate .reply_media_visiable {
    height: 60px;
    width: 90px;
    margin-left: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.reply_input_plate .reply_media_visiable img {
    max-width: 100%;
    max-height: 100%
}

.reply_input_plate .reply_media_visiable .reply_media_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3)
}

.reply_input_plate .reply_media_visiable .reply_media_overly .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer
}

.emojis_container {
    display: inline-block;
    line-height: .5;
    position: absolute;
    bottom: 30px;
    left: 10px
}

.emojis_container .emojiTab {
    padding: 15px 25px;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
    left: 10px;
    z-index: 5 !important
}

.emojis_container .emojiTab .singleEmoji {
    font-size: 35px;
    padding: 0 4px;
    cursor: pointer
}

.comment_react_container {
    display: inline-block;
    bottom: 30px;
    left: 10px
}

.comment_react_container .comment_emoji_plate {
    padding: 4px 10px 2px 10px;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    display: flex;
    align-items: center
}

.comment_react_container .comment_emoji_plate .comment_emoji_icon {
    font-size: 19px;
    padding: 0 4px;
    cursor: pointer
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    height: 50px;
    width: 50px;
    background: rgba(243, 243, 243, .737254902);
    border-radius: 50%
}

.swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
    font-size: 20px;
    color: #000
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    height: 50px;
    width: 50px;
    background: rgba(243, 243, 243, .737254902);
    border-radius: 50%
}

.swiper-button-prev::after, .swiper-rtl .swiper-button-next::after {
    font-size: 20px;
    color: #000
}

.dialog_header_content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer
}

.even_wrapper h4 {
    padding-bottom: 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.event_item {
    margin-top: 5px;
    height: auto;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative
}

.event_item a {
    cursor: pointer
}

.event_item .event_title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 28px;
    color: #000;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 0
}

.event_item .event_title .avatar_tittle {
    padding: 0px 10px
}

.event_item .dropDown {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #f3f3f3;
    border-radius: 50%;
    cursor: pointer
}

.event_item .dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.event_item .eventTime {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;
    font-weight: 400;
    padding-top: 3px
}

.event_item .eventTime .MuiSvgIcon-root {
    font-size: 20px;
    color: #333;
    margin-right: 3px
}

.event_item .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px
}

.event_item .event_details p {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 20px;
    padding-top: 15px
}

.event_item_two {
    margin-top: 5px;
    height: 127px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative
}

.event_item_two .event_title {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 22px
}

.event_item_two .dropDown {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: 50%;
    cursor: pointer
}

.event_item_two .dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.event_item_two .eventTime {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #333;
    font-weight: 400;
    padding-top: 3px
}

.event_item_two .eventTime .MuiSvgIcon-root {
    font-size: 16px;
    color: #333;
    margin-right: 3px
}

.event_item_two .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 13px
}

.event_item_two .eventTime .event_locat {
    margin-left: 5px
}

.event_details_body {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    position: relative;
    overflow: hidden
}

.event_details_body .event_details_top {
    height: 220px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    position: relative;
    z-index: 1
}

.event_details_body .event_details_top::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: -1
}

.event_details_body .event_details_top .details_header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.event_details_body .event_details_top .details_header h4 {
    font-size: 15px;
    color: #fff;
    font-weight: 300
}

.event_details_body .event_details_top .details_header .details_header_right {
    display: flex;
    align-items: center
}

.event_details_body .event_details_top .details_header .details_header_right .dropDown_icon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(243, 243, 243, .231372549);
    border-radius: 50%;
    cursor: pointer;
    margin-left: 7px
}

.event_details_body .event_details_top .details_header .details_header_right .dropDown_icon .MuiSvgIcon-root {
    font-size: 22px;
    color: #fff
}

.event_details_body .event_details_top .event_info {
    border-top: 1px solid rgba(243, 243, 243, .2431372549);
    margin-top: 12px
}

.event_details_body .event_details_top .event_info .event_title {
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    line-height: 34px;
    padding-top: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.event_details_body .event_details_top .event_info .eventTime {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    padding-top: 3px
}

.event_details_body .event_details_top .event_info .eventTime .MuiSvgIcon-root {
    font-size: 20px;
    color: #fff;
    margin-right: 3px
}

.event_details_body .event_details_top .event_info .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px
}

.event_details_body .event_details_top .event_info .event_location {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #278783;
    font-weight: 400;
    padding-top: 3px;
    padding: 2px 10px;
    background-color: #fff;
    position: absolute;
    bottom: 12px;
    right: 20px;
    border-radius: 4px
}

.event_details_body .event_details_top .event_info .event_location .MuiSvgIcon-root {
    font-size: 18px;
    color: #278783;
    margin-right: 3px
}

.event_details_body .event_details_top .event_info .event_flipbook {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #278783;
    font-weight: 400;
    padding-top: 3px;
    padding: 2px 10px;
    background-color: #fff;
    position: absolute;
    bottom: 50px;
    right: 20px;
    border-radius: 4px;
    cursor: pointer
}

.event_details_body .event_details_top .event_info .event_flipbook .MuiSvgIcon-root {
    font-size: 18px;
    color: #278783;
    margin-right: 3px
}

.event_details_body .invite_section {
    padding: 20px;
    border-bottom: 1px solid #d5d5d5
}

.event_details_body .invite_section .invite_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.event_details_body .invite_section .invite_top .invite_top_left {
    display: flex;
    align-items: center
}

.event_details_body .invite_section .invite_top .invite_top_left .member_div {
    display: flex;
    align-items: center
}

.event_details_body .invite_section .invite_top .invite_top_left span {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    cursor: pointer
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn {
    height: 35px;
    padding: 0 15px;
    border: 1px solid #1c777a;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #1c777a;
    cursor: pointer
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn:hover {
    background-color: #1c777a;
    color: #fff
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn:hover .MuiSvgIcon-root {
    color: #fff
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn .MuiSvgIcon-root {
    font-size: 20px;
    color: #1c777a
}

.event_details_body .event_details {
    padding: 20px
}

.event_details_body .event_details p {
    font-size: 14px;
    line-height: 20px;
    color: #888;
    text-align: start
}

.event_details_body .details_file_container {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-wrap: wrap
}

.event_details_body .details_file_container .file_tab {
    height: 180px;
    width: 180px;
    margin: 15px
}

.event_details_body .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.event_details_body .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.event_details_body .details_file_container .file_tab img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.event_create {
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    z-index: -1
}

.event_create .event_form {
    padding-top: 20px;
    z-index: -1
}

.event_create .event_form .input_field {
    width: 100%;
    position: relative
}

.event_create .event_form .input_field .form_cotrol {
    height: 50px;
    background-color: rgba(223, 222, 222, .2901960784);
    border: 1px solid #d5d5d5;
    width: 100%;
    border-radius: 6px;
    padding: 10px 12px
}

.event_create .event_form .input_field .form_cotrol:focus {
    outline: 0
}

.event_create .event_form .input_field .form_cotrol::placeholder {
    color: rgba(129, 128, 128, .8078431373)
}

.event_create .event_form .eventSubmit_btn {
    height: 40px;
    background-color: #1976d2;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer
}

.event_create .event_form .field_heading {
    padding-bottom: 12px
}

.addGuest_wrapper {
    padding-top: 10px
}

.addGuest_wrapper h5 {
    font-size: 19px;
    font-weight: 500;
    color: #333
}

.addGuest_wrapper .invite_form {
    width: 100%;
    position: relative;
    margin-top: 10px
}

.addGuest_wrapper .invite_form .form_cotrol {
    height: 48px;
    background-color: rgba(233, 232, 232, .7490196078);
    width: 100%;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 10px 12px
}

.addGuest_wrapper .invite_form .form_cotrol:focus {
    outline: 0
}

.addGuest_wrapper .invite_form .form_cotrol::placeholder {
    color: rgba(129, 128, 128, .8078431373);
    font-size: 13px
}

.addGuest_wrapper .invite_form .addBtn {
    height: 33px;
    padding: 0 18px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    background-color: #1976d2;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.addGuest_wrapper .guestList_ShortView {
    padding-top: 10px;
    padding-left: 6px
}

.addGuest_wrapper .guestList_ShortView .MuiAvatarGroup-root {
    justify-content: start
}

.reviewsWrapper {
    padding: 20px;
    border-top: 1px solid #d5d5d5;
    margin-top: 20px
}

.reviewsWrapper .review_add_field {
    margin: 20px 0 10px 0
}

.reviewsWrapper .reviews_heading {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.reviewsWrapper .reviews_heading h4 {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.reviewsWrapper .submit_riview {
    display: flex;
    justify-content: end
}

.reviewsWrapper .submit_riview .review_submit_btn {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 6px 15px;
    background-color: #2e8f87;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer
}

.reviewsWrapper .review_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    padding: 18px 0
}

.reviewsWrapper .review_item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.reviewsWrapper .review_item .review_item_wrapper {
    display: flex
}

.reviewsWrapper .review_item .review_item_wrapper .reviewer_img img {
    height: 40px;
    width: 40px;
    object-fit: cover
}

.reviewsWrapper .review_item .review_item_wrapper .review_content {
    padding-left: 6px
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .reviewr_info {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .reviewr_info span {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    padding-left: 6px
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .review_massage {
    font-size: 14px;
    color: rgba(78, 77, 77, .8901960784);
    font-weight: 400;
    line-height: 21px
}

.reviewsWrapper .review_item .memberCard_footer_right {
    display: flex;
    justify-content: end;
    position: relative
}

.reviewsWrapper .review_item .memberCard_footer_right:hover .member_dropdown_Plate {
    display: block
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown {
    height: 34px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    cursor: pointer
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown:hover {
    background-color: rgba(232, 230, 230, .6745098039)
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate {
    width: 180px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: -5px;
    bottom: 30px;
    display: none;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    overflow: hidden
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate ul li {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate ul li:hover {
    background-color: #f3f3f3
}

.invite_dialouge_title {
    text-align: center
}

.invite_dialouge_body {
    min-height: 300px
}

.invite_dialouge_body .inviteSearch_wrapper {
    position: relative;
    width: 450px
}

.invite_dialouge_body .inviteSearch_wrapper .form_contorl {
    height: 48px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    outline: 0;
    padding: 0 15px;
    font-size: 15px
}

.invite_dialouge_body .inviteSearch_wrapper .form_contorl::placeholder {
    color: #888
}

.invite_dialouge_body .inviteSearch_wrapper i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}

.invite_dialouge_body .inviteSearch_wrapper i .MuiSvgIcon-root {
    color: #888
}

.invite_dialouge_body .invited_people_list {
    padding: 12px 0
}

.invite_dialouge_body .invited_people_list h5 {
    font-size: 18px;
    color: #888;
    font-weight: 400;
    padding-top: 5px
}

.invite_dialouge_body .invited_people_list ul {
    padding: 5px 10px 5px 15px
}

.invite_dialouge_body .invited_people_list ul li {
    list-style: decimal;
    margin-top: 10px
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap .people_name {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap .invite_status {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background-color: #278783;
    border-radius: 35px;
    height: 28px;
    padding: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center
}

.selector_body {
    width: 570px;
    min-height: 450px;
    padding: 20px
}

.invite_btn_disable {
    padding: 3px 20px !important;
    background-color: rgba(154, 141, 229, .6705882353) !important;
    border-radius: 35px !important
}

.invite_modal_btn {
    padding: 3px 20px !important;
    background-color: #7d6be0 !important;
    border-radius: 35px !important;
    color: #fff !important
}

.invite_cancel_btn {
    padding: 3px 20px !important;
    background-color: #fff !important;
    border-radius: 35px !important;
    color: #333 !important;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647) !important;
    margin-left: 12px !important
}

.invited_list_container {
    background-color: #fff;
    padding: 20px 20px 2px 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.invited_list_container .invited_list_header .table_heading {
    font-size: 16px;
    color: #333;
    font-weight: 500
}

.invited_list_container .invited_list_header .text_end {
    text-align: end
}

.invited_list_container .invited_list_header .text_center {
    text-align: center
}

.invited_list_container .table_content {
    padding: 10px 0
}

.invited_list_container .table_content .table_content_item {
    padding: 8px 0
}

.invited_list_container .table_content .table_content_item .table_Item_name {
    display: flex;
    align-items: center
}

.invited_list_container .table_content .table_content_item .table_Item_name .item_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 5px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.invited_list_container .table_content .table_content_item .table_Item_email {
    font-size: 13px;
    color: #333;
    font-weight: 400
}

.invited_list_container .table_content .table_content_item .item_status {
    text-align: center
}

.invited_list_container .table_content .table_content_item .item_action {
    text-align: end
}

.comment-box .comment-head {
    display: flex;
    justify-content: space-between
}

.event_image_place {
    height: 130px;
    width: 100%;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin-right: 15px
}

.event_image_place img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px
}

.event_image_place .event_image_place_overly {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px
}

.event_image_place .event_image_place_overly .img_cross_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #888;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.event_image_place .event_image_place_overly .img_cross_btn .MuiSvgIcon-root {
    font-size: 16px
}

.classified_top_section .classified_top_img {
    height: 220px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden
}

.classified_top_section .classified_top_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_top_section .classified_top_img .classified_top_imgOverly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt h4 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-align: center
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search {
    width: 600px;
    margin: 0 auto
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form {
    margin-top: 20px
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar {
    position: relative;
    width: 100%
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    color: #333;
    border: 1px solid #d5d5d5;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control:focus {
    outline: 0
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control::placeholder {
    font-size: 16px;
    color: #aeacac;
    font-weight: 400
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control:optional {
    color: #636262
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search .search_hint {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    padding-top: 12px;
    padding-left: 5px
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search .search_hint span {
    font-weight: 400;
    color: #f3f3f3;
    cursor: pointer;
    margin-right: 15px;
    border-bottom: 1px solid rgba(243, 243, 243, .431372549);
    padding-bottom: 1px
}

.classified_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}

.classified_heading .section_name {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.classified_cetegory_list {
    padding-top: 20px;
    padding-bottom: 25px
}

.classified_cetegory_list .list_category_item {
    height: 115px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.classified_cetegory_list .list_category_item .list_item_content {
    text-align: center
}

.classified_cetegory_list .list_category_item .list_item_content .category_img {
    height: 42px;
    width: 42px;
    margin: 0 auto
}

.classified_cetegory_list .list_category_item .list_item_content .category_img img {
    max-width: 100%;
    max-height: 100%
}

.classified_cetegory_list .list_category_item .list_item_content .categoryName {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    padding-top: 7px
}

.classified_cetegory_list .list_category_item .list_item_content .post_counting {
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 500
}

.classiffied_add_btn {
    padding-bottom: 10px
}

.classified_create {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 22px 20px 25px 20px
}

.classified_create h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 24px
}

.classified_create .classified_create_form {
    padding-top: 20px
}

.classified_create .classified_create_form .form_rowing {
    display: flex;
    align-items: center
}

.classified_create .classified_create_form .form_rowing .MuiFormLabel-root {
    font-weight: 600;
    margin-right: 20px
}

.classified_create .classified_create_form .field_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding-bottom: 10px
}

.classified_create .classified_create_form .classified_images .classified_img_container {
    height: 100px;
    width: 100px
}

.classified_submit {
    margin-top: 15px
}

.classified_item {
    width: 100%;
    background-color: #fff;
    position: relative;
    height: 330px;
    box-shadow: 0px 1px 1px 1px rgba(128, 127, 127, .1294117647);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer
}

.classified_item .classified_item_img {
    height: 230px;
    width: 100%
}

.classified_item .classified_item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_item .item_content {
    padding: 12px
}

.classified_item .item_content .post_title {
    font-size: 16px;
    font-weight: 500;
    color: #5c5c5c;
    line-height: 24px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.classified_item .post_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0
}

.classified_item .post_bottom .post_amount {
    font-size: 18px;
    font-weight: 600;
    color: #333
}

.classified_item .post_bottom .cart_icon {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_item .post_bottom .cart_icon:hover {
    background-color: #7d6be0
}

.classified_item .post_bottom .cart_icon:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_item .post_bottom .cart_icon .MuiSvgIcon-root {
    font-size: 20px
}

.classified_item .post_bottom .classified_option {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_item .post_bottom .classified_option:hover {
    background-color: #7d6be0
}

.classified_item .post_bottom .classified_option:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_item .post_bottom .classified_option .MuiSvgIcon-root {
    font-size: 20px
}

.classified_itemLayout2 {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    border: 1px solid #d5d5d5;
    cursor: pointer
}

.classified_itemLayout2 .classified_item_img {
    height: 120px;
    width: 40%
}

.classified_itemLayout2 .classified_item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_itemLayout2 .item_content {
    padding: 12px;
    width: 60%;
    position: relative
}

.classified_itemLayout2 .item_content .post_title {
    font-size: 16px;
    font-weight: 500;
    color: #5c5c5c;
    line-height: 24px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.classified_itemLayout2 .item_content .post_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0
}

.classified_itemLayout2 .item_content .post_bottom .post_amount {
    font-size: 18px;
    font-weight: 600;
    color: #333
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon:hover {
    background-color: #7d6be0
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon .MuiSvgIcon-root {
    font-size: 18px
}

.d_top {
    margin-top: 45px
}

.d_top h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    padding-bottom: 5px
}

.classified_item_detail {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    width: 100%;
    border-radius: 12px;
    padding: 20px
}

.classified_item_detail .classified_item_Img_container {
    padding-top: 5px
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide {
    height: 400px;
    width: 100%;
    background-color: rgba(236, 235, 246, .662745098);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #d5d5d5
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .navigate_img {
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    height: 100px;
    width: 100%;
    object-fit: cover
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide-thumb-active {
    border: 1px solid #d5d5d5
}

.classified_item_detail .classified_item_Img_container .classified_img_navigate_content {
    padding-top: 15px
}

.classified_item_detail .swipper_item_content h4 {
    font-size: 21px;
    font-weight: 500;
    color: #61666f;
    line-height: 32px
}

.classified_item_detail .swipper_item_content .product_price {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    padding-top: 4px
}

.classified_item_detail .swipper_item_content .product_price span {
    font-size: 18px
}

.classified_item_detail .swipper_item_content .seller_profile {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;
    color: #333;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer
}

.classified_item_detail .swipper_item_content .seller_profile:hover .MuiSvgIcon-root {
    color: #333
}

.classified_item_detail .swipper_item_content .seller_profile .MuiSvgIcon-root {
    color: #7d6be0;
    margin-right: 3px
}

.classified_item_detail .swipper_item_content .item_detail_list ul li {
    font-size: 14px;
    font-weight: 400;
    color: rgba(81, 81, 81, .7607843137);
    word-wrap: break-word;
    padding-top: 10px
}

.classified_item_detail .swipper_item_content .item_detail_list ul li .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px
}

.classified_item_detail .swipper_item_content .item_detail_list ul li span {
    color: #333;
    font-weight: 500;
    padding-left: 5px;
    font-size: 15px;
    word-wrap: break-word;
    padding-left: 4px
}

.classified_item_detail .swipper_item_content .item_detail_list .order_btn {
    height: 38px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    background-color: #2e7d32;
    border-radius: 35px;
    margin: 30px auto 0;
    cursor: pointer
}

.classified_item_detail .swipper_item_content .item_detail_list .order_btn:hover {
    background-color: #109817
}

.classified_item_detail .item_about {
    padding-top: 25px
}

.classified_item_detail .item_about h4 {
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.classified_item_detail .item_about p {
    font-size: 15px;
    color: #888;
    line-height: 23px
}

.classified_item_detail .bidding_container .bidding_btn {
    height: 42px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #2e7d32;
    border-radius: 35px;
    margin: 0 auto;
    cursor: pointer
}

.classified_item_detail .bidding_container .bidding_btn:hover {
    background-color: #109817
}

.top_classified_navigate {
    padding-bottom: 12px
}

.top_classified_navigate ul {
    display: flex;
    align-items: center
}

.top_classified_navigate ul li {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #888;
    font-weight: 400;
    padding-right: 15px;
    cursor: pointer
}

.top_classified_navigate ul .active {
    color: #333;
    font-size: 14px;
    font-weight: 500
}

.classified_order_form {
    width: 540px
}

.bid_form {
    width: 300px
}

.bidding_container .bidding_item {
    width: 700px;
    background-color: #ecebf6;
    border-radius: 10px;
    margin-top: 22px;
    padding: 12px;
    position: relative
}

.bidding_container .bidding_item .item_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid rgba(195, 195, 195, .6039215686)
}

.bidding_container .bidding_item .item_top:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.bidding_container .bidding_item .item_top .top_left {
    display: flex
}

.bidding_container .bidding_item .item_top .top_left .user_img img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.bidding_container .bidding_item .item_top .top_left .use_info {
    padding-left: 6px
}

.bidding_container .bidding_item .item_top .top_left .use_info .userName {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px
}

.bidding_container .bidding_item .item_top .top_left .use_info .time {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    line-height: 16px
}

.bidding_container .bidding_item .item_top .bidamount {
    font-size: 20px;
    font-weight: 600;
    color: #333
}

.bidding_container .bidding_item .item_top .bidamount span {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    padding-right: 10px
}

.bidding_container .bidding_item .item_top .top_right .MuiSvgIcon-root {
    color: #888
}

.classified_orderList_container {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 22px 20px 20px 20px
}

.classified_orderList_container h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333
}

.order_list_wrapper {
    padding-top: 10px
}

.order_list_wrapper .order_list_wrap {
    border-bottom: 1px dashed #d5d5d5;
    padding: 20px 0
}

.order_list_wrapper .order_list_wrap:last-child {
    border-bottom: 1px dashed rgba(0, 0, 0, 0)
}

.order_list_wrapper .order_list_wrap .list_top {
    display: flex;
    justify-content: space-between
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    display: flex
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id .MuiSvgIcon-root {
    font-size: 22px;
    margin-right: 5px;
    color: #333
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id .o_name {
    margin-left: 5px
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id .status_update {
    padding: 1px 10px;
    background-color: #109817;
    color: #fff;
    border-radius: 35px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400
}

.order_list_wrapper .order_list_wrap .list_top .list_top_right {
    font-size: 24px;
    font-weight: 700;
    color: #000
}

.order_list_wrapper .order_list_wrap .update_item_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-left: 22px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_left {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    display: flex;
    align-items: center
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_left .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px;
    color: #888
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right {
    display: flex
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .print_btn {
    font-size: 14px;
    color: #888;
    border: 1px solid #d5d5d5;
    padding: 1px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .dteilas_btn {
    font-size: 14px;
    color: #fff;
    background-color: #7d6be0;
    border: 1px solid #d5d5d5;
    padding: 1px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .item_action_btn {
    font-size: 14px;
    color: #888;
    border: 1px solid #d5d5d5;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .order_content {
    display: flex;
    align-items: center;
    padding-left: 22px;
    padding-top: 12px
}

.order_list_wrapper .order_list_wrap .order_content .item_img {
    height: 50px;
    width: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center
}

.order_list_wrapper .order_list_wrap .order_content .item_img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 4px
}

.order_list_wrapper .order_list_wrap .order_content .item_info {
    padding-left: 5px
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_location {
    font-size: 13px;
    font-weight: 400;
    color: #888
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_location .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.order_info_wrapper {
    padding-top: 50px
}

.order_info_wrapper h5 {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    display: inline-block
}

.order_info_wrapper .order_info_wrap {
    padding-top: 12px
}

.order_info_wrapper .order_info_wrap .info_item {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #888;
    width: 100%;
    padding: 7px 0
}

.order_info_wrapper .order_info_wrap .info_item .info_left {
    font-size: 16px;
    font-weight: 400;
    color: #888;
    width: 20%
}

.order_info_wrapper .order_info_wrap .info_item .info_right {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    width: 80%;
    word-wrap: break-word
}

.order_info_wrapper .order_info_wrap .info_item .info_right span {
    margin-right: 12px
}

.classifiedPost_dropDown {
    width: 200px;
    padding: 0 6px
}

.classifiedPost_dropDown ul li {
    padding: 4px 5px;
    font-size: 15px;
    cursor: pointer
}

.classifiedPost_dropDown ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px
}

.classifiedPost_dropDown ul li:hover {
    background-color: rgba(221, 217, 217, .6941176471)
}

.order_detail_header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.job_filter {
    width: 100%;
    height: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    position: fixed;
    width: 300px
}

.job_filter .job_filter_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    padding: 10px 15px
}

.job_filter .job_filter_top .job_filter_top_left {
    font-size: 17px;
    color: #333;
    font-weight: 500
}

.job_filter .job_filter_top .job_filter_top_right {
    font-size: 14px;
    color: #ef1017;
    font-weight: 500
}

.job_filter .job_filter_Content {
    padding: 10px
}

.job_filter .job_filter_Content .job_filter_item {
    padding: 10px 0
}

.job_filter .job_filter_Content .job_filter_item h4 {
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 12px
}

.job_filter .job_filter_Content .job_filter_item .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px !important
}

.job_filter .job_filter_Content .job_filter_item .salaryRange_slider {
    padding-top: 30px
}

.job_filter .job_filter_Content .item_border {
    border-bottom: 1px solid #d5d5d5
}

.jobHome {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.jobHome_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 12px 0
}

.jobHome_heading .heading_left {
    font-size: 19px;
    color: #333;
    font-weight: 500
}

.resource {
    height: 300px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.section_headear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}

.section_headear h4 {
    font-size: 24px;
    font-weight: 500;
    color: #333
}

.section_headear .btns_row {
    display: flex;
    align-items: center
}

.section_headear .btns_row .Btn_one {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7d6be0;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    margin-left: 15px;
    border: 1px solid #7d6be0;
    cursor: pointer
}

.section_headear .btns_row .Btn_one:hover {
    background-color: #fff;
    color: #7d6be0
}

.section_headear .btns_row .Btn_two {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    color: #7d6be0;
    border: 1px solid #7d6be0;
    font-size: 15px;
    margin-left: 15px;
    cursor: pointer
}

.section_headear .btns_row .Btn_two:hover {
    background-color: #7d6be0;
    color: #fff
}

.resource_item {
    height: auto;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 12px;
    position: relative;
    margin-bottom: 20px
}

.resource_item:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.resource_item .resource_item_top {
    display: flex;
    justify-content: space-between
}

.resource_item .resource_title {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer
}

.resource_item .resource_item_details {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    padding-top: 8px;
    text-align: start;
    cursor: pointer
}

.resource_item .resource_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 10px 12px
}

.resource_item .resource_footer .res_footer_left {
    display: flex;
    align-items: center
}

.resource_item .resource_footer .res_footer_left .admin_profile img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 50%
}

.resource_item .resource_footer .res_footer_left .admin_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 3px
}

.resource_item .resource_footer .res_footer_right .resource_date {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.resource_item .resource_footer .res_footer_right .resource_date .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 1px
}

.resource_item .resource_footer .res_footer_right .admin_dropdown {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer
}

.resource_item .resource_footer .res_footer_right .admin_dropdown .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 1px;
    color: #888
}

.resource_item_2 {
    height: 380px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 12px;
    position: relative;
    display: flex;
    flex-direction: column
}

.resource_item_2 .action_list {
    display: flex
}

.resource_item_2 .action_list i {
    margin-left: 5px;
    height: 35px;
    width: 35px;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%
}

.resource_item_2 .action_list i:hover {
    background-color: #f3f3f3
}

.resource_item_2 .action_list i .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.resource_item_2:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.resource_item_2 .Item_image {
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 8px
}

.resource_item_2 .Item_image img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.resource_item_2 h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    margin-top: 10px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.resource_item_2 h5 {
    font-size: 16px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.resource_item_2 .card_details {
    text-align: center
}

.resource_item_2 .card_details p {
    text-align: center;
    color: #888;
    font-size: 13px;
    font-weight: 400
}

.resource_item_2 .create_owner {
    font-size: 15px;
    font-weight: 400;
    color: #888;
    line-height: 22px
}

.resource_item_2 .create_owner span {
    text-decoration-color: #cc9752;
    text-decoration-line: underline
}

.resource_item_2 .item_Bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px
}

.resource_item_2 .create_date {
    font-size: 15px;
    font-weight: 500;
    color: #333
}

.resource_item_2 .create_date span {
    font-weight: 400;
    font-size: 14px;
    color: #888
}

.resource_item_2 .recomment_overview {
    padding: 10px 0 6px 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.resource_item_2 .recomment_overview .avatar_size {
    height: 30px;
    width: 30px
}

.resource_item_2 .recomment_overview .star_mark {
    display: flex;
    align-items: center
}

.resource_item_2 .recomment_overview .star_mark .MuiBox-root {
    width: auto
}

.representative_item_2 {
    align-items: center;
    position: relative;
    height: 350px
}

.representative_item_2 .card_mid {
    position: absolute;
    bottom: 60px;
    left: 0;
    text-align: center;
    width: 100%
}

.resource_create {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.resource_create .resource_form {
    padding-top: 20px
}

.resource_create .resource_form .field_heading {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.resource_create .resource_form .resource_files_container {
    padding-top: 10px
}

.resource_create .resource_form h4 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding: 10px 0 0 0
}

.recommended_body {
    overflow: hidden;
    width: 100%
}

.resource_details {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    width: 100%;
    padding: 25px;
    position: relative
}

.resource_details .admin_dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 38px;
    width: 38px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.resource_details .admin_dropdown .MuiSvgIcon-root {
    color: #333;
    font-size: 20px
}

.resource_details .resource_admin_info {
    display: flex;
    align-items: center
}

.resource_details .resource_admin_info .admin_profile img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 50%
}

.resource_details .resource_admin_info .admin_post_info {
    padding-left: 3px
}

.resource_details .resource_admin_info .admin_post_info .adminName {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.resource_details .resource_admin_info .admin_post_info .postDate {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.resource_details h4 {
    font-size: 26px;
    font-weight: 500;
    color: #333;
    line-height: 35px;
    padding-bottom: 10px
}

.resource_details p {
    font-size: 15px;
    color: #888;
    line-height: 22px;
    font-weight: 400;
    text-align: start
}

.resource_details h5 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding-top: 40px
}

.resource_details .details_file_container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap
}

.resource_details .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.resource_details .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.compnent_heading {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    padding-bottom: 20px
}

.image_list_container {
    display: flex
}

.image_list_container .image_place {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin-right: 15px
}

.image_list_container .image_place img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px
}

.image_list_container .image_place .img_place_overly {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px
}

.image_list_container .image_place .img_place_overly .img_cross_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #888;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.image_list_container .image_place .img_place_overly .img_cross_btn .MuiSvgIcon-root {
    font-size: 16px
}

.image_list_container .image_place_addMore {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer
}

.Resource_uploadBox {
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, .02);
    margin: .5em 0;
    position: relative;
    border-radius: 10px;
    border: 1px dashed #d9d9d9
}

.Resource_uploadBox:hover {
    background: #ecebf6
}

.Resource_uploadBox input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.Resource_uploadBox h1 {
    text-align: center;
    color: #333;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: 400
}

.create_resource {
    height: 50px;
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #7d6be0;
    color: #7d6be0;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center
}

.rating_container {
    width: 100%;
    border-top: 1px solid rgba(195, 195, 195, .4980392157);
    margin-top: 70px
}

.rating_container .section_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0
}

.rating_container .section_top .sec_title {
    font-size: 20px;
    font-weight: 500;
    color: #333
}

.rating_container .section_top .rating_add {
    padding: 8px 20px;
    background-color: #7d6be0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 35px;
    cursor: pointer
}

.rating_container .rating_item {
    display: flex;
    border-bottom: 1px solid rgba(195, 195, 195, .3529411765);
    padding: 16px 0
}

.rating_container .rating_item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.rating_container .rating_item .rating_content {
    padding-left: 10px;
    width: 100%
}

.rating_container .rating_item .rating_content .content_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.rating_container .rating_item .rating_content .content_top .action_btn i {
    margin-left: 10px
}

.rating_container .rating_item .rating_content .user_name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.rating_container .rating_item .rating_content .review_text {
    font-size: 15px;
    font-weight: 400;
    color: #888;
    line-height: 22px
}

.business_wrapper {
    padding-top: 0px
}

.business_item {
    height: auto;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative;
    display: flex
}

.business_item .business_item_img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 6px
}

.business_item .business_item_img img {
    height: 160px;
    width: 180px;
    object-fit: contain
}

.business_item .bisiness_item_content {
    width: 100%;
    padding-left: 10px
}

.business_item:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.business_item .admin_dropdown {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.business_item .admin_dropdown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.business_item .resource_title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 28px;
    padding-top: 4px;
    cursor: pointer
}

.business_item .business_item_details p {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    padding-top: 8px;
    text-align: start;
    cursor: pointer;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.business_item .business_item_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 15px 15px
}

.business_item .business_item_footer .business_item_left {
    display: flex;
    align-items: center
}

.business_item .business_item_footer .business_item_left .admin_profile img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 50%
}

.business_item .business_item_footer .business_item_left .admin_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 3px
}

.business_item .business_item_footer .business_item_right {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.business_item .business_item_footer .business_item_right .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 2px
}

.business_item .business_type {
    padding: 1px 25px;
    border-radius: 35px;
    background-color: #ecebf6;
    font-size: 13px;
    color: #7d6be0;
    display: inline-block
}

.business_item .b_type_list .business_d_type {
    padding: 1px 15px;
    border-radius: 35px;
    background-color: #ecebf6;
    font-size: 13px;
    color: #7d6be0;
    display: inline-block;
    margin-right: 12px;
    margin-top: 8px
}

.business_item_2 {
    height: 380px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px
}

.business_item_2:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.business_item_2 h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    margin-top: 10px
}

.business_item_2 h5 {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 20px
}

.business_item_2 .create_owner {
    font-size: 15px;
    font-weight: 400;
    color: #888;
    line-height: 22px
}

.business_item_2 .create_date {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #333
}

.business_item_2 .create_date span {
    font-weight: 400;
    font-size: 14px;
    color: #888
}

.recomment_overview {
    padding: 10px 0 6px 0;
    display: flex;
    align-items: center;
    justify-content: end
}

.recomment_overview .star_mark {
    display: flex;
    align-items: center
}

.business_details {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    width: 100%;
    padding: 25px;
    position: relative
}

.business_details .b_details_top .business_Title {
    font-size: 26px;
    font-weight: 500;
    color: #333;
    line-height: 35px;
    padding-bottom: 10px
}

.business_details .b_details_top .hire_btn {
    display: flex;
    justify-content: end
}

.business_details .admin_dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 38px;
    width: 38px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.business_details .admin_dropdown .MuiSvgIcon-root {
    color: #333;
    font-size: 20px
}

.business_details .resource_admin_info {
    display: flex;
    align-items: center
}

.business_details .resource_admin_info .admin_profile img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 50%
}

.business_details .resource_admin_info .admin_post_info {
    padding-left: 3px
}

.business_details .resource_admin_info .admin_post_info .adminName {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.business_details .resource_admin_info .admin_post_info .postDate {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.business_details p {
    font-size: 14px;
    color: #888;
    line-height: 22px;
    font-weight: 400;
    text-align: start
}

.business_details h6 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding-top: 40px
}

.business_details .details_file_container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap
}

.business_details .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.business_details .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.service_requestBody {
    margin-top: 20px
}

.request_item {
    height: 380px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative
}

.request_item .delet_btn {
    position: absolute;
    right: 12px;
    z-index: 3;
    top: 12px;
    height: 37px;
    width: 37px;
    background-color: rgba(0, 0, 0, .3);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    cursor: pointer
}

.request_item .delet_btn .MuiSvgIcon-root {
    color: #fff
}

.request_item .item_img {
    height: 180px;
    width: 100%
}

.request_item .item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.request_item .item_details_rev {
    font-size: 14px;
    color: #888;
    display: block;
    display: -webkit-box;
    height: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-top: 10px
}

.request_item .user_info {
    display: flex;
    align-items: center;
    margin-top: 8px
}

.request_item .user_info .user_name {
    margin-left: 6px;
    color: #333;
    font-size: 16px
}

.request_item .item_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px
}

.request_item .item_bottom .budget {
    font-size: 22px;
    font-weight: 600;
    color: #333
}

.request_item .item_bottom .req_date {
    font-size: 14px;
    color: #888
}

.service_request_details {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px;
    position: relative
}

.service_request_details .details_top_info {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.service_request_details .details_top_info .user_info {
    display: flex;
    align-items: center
}

.service_request_details .details_top_info .user_info .user_name {
    margin-left: 6px;
    color: #333;
    font-size: 16px
}

.service_request_details .details_top_info .info_date {
    font-size: 14px;
    color: #888
}

.service_request_details .details_text {
    font-size: 14px;
    color: #888;
    margin-top: 16px;
    margin-bottom: 20px
}

.xs-none {
    display: block !important
}

.lg-none {
    display: none !important
}

.representative {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 15px 15px
}

.representative .r_mamber {
    height: 250px;
    background-color: #ecebf6;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    padding: 25px 12px;
    margin-top: 15px;
    cursor: pointer;
    position: relative
}

.representative .r_mamber .man_category {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px
}

.representative .r_mamber .man_category img {
    height: 30px;
    width: 30px
}

.representative .r_mamber .man_profile {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto
}

.representative .r_mamber .man_profile img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.representative .r_mamber .m_name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding-top: 20px
}

.representative .r_mamber .man_title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333;
    text-align: center;
    padding-top: 7px
}

.representetive-img {
    height: 280px;
    width: 100%
}

.representetive-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notification .notification_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    height: 720px;
    border-radius: 13px;
    padding: 15px;
    overflow: hidden
}

.notification .notification_list .notification_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px
}

.notification .notification_list .notification_top .notification_top_left {
    font-size: 20px;
    color: #333;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    font-weight: 500
}

.notification .notification_list .notification_top .notification_top_right .nav .nav-link {
    margin-left: 15px;
    display: block;
    font-size: 16px;
    padding: 0px;
    color: #333
}

.notification .notification_list .notification_body {
    margin-top: 20px
}

.notification .notification_list .notification_body .notification_item {
    height: 105px;
    width: 100%;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 7px;
    padding: 12px;
    margin-top: 10px
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 {
    display: flex;
    align-items: center
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info {
    padding-left: 6px;
    width: 100%
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul {
    display: flex;
    justify-content: space-between
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul li {
    font-size: 13px;
    color: #888;
    font-size: 400
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul li .profile_user_name {
    font-size: 17px;
    color: #333;
    font-weight: 500
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul .post_time {
    color: #7d6be0
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul .post_time i {
    font-size: 13px;
    cursor: pointer
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info .profile_title {
    font-size: 12px;
    line-height: 14px;
    color: #7d6be0;
    font-weight: 500
}

.notification .notification_list .notification_body .notification_item .notify_text {
    font-size: 13px;
    line-height: 18px;
    color: #888;
    text-align: justify
}

.notfound_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999
}

.notfound_wrapper .errorImg {
    height: 480px;
    width: auto
}

.notfound_wrapper .errorImg img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.notfound_wrapper .cheking_div {
    font-size: 20px;
    color: #333;
    font-weight: 500
}

.search_for_commuinityBody {
    padding: 15px;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    height: 900px
}

.search_for_commuinityBody .search_for_commuinity {
    max-width: 500px;
    margin: 0 auto
}

.search_for_commuinityBody .search_for_commuinity form {
    margin-top: 20px
}

.search_for_commuinityBody .search_for_commuinity form .searchBar {
    position: relative;
    width: 100%
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    color: #333;
    border: 1px solid #d5d5d5;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control:focus {
    outline: 0
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control::placeholder {
    font-size: 16px;
    color: #aeacac;
    font-weight: 400
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control:optional {
    color: #636262
}

.search_for_commuinityBody .search_for_commuinity form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.search_for_commuinityBody .commuinity_list {
    background-color: #ecebf6;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    border: 1px solid #d5d5d5
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top {
    display: flex
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .comuinity_img {
    height: 70px;
    width: 70px;
    border-radius: 6px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .comuinity_img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text {
    padding-left: 10px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text .community_name {
    font-size: 17px;
    font-weight: 500;
    color: #888;
    line-height: 22px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text .commuinity_hint {
    font-size: 13px;
    font-weight: 400;
    color: rgba(51, 51, 51, .6588235294);
    line-height: 18px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_bottom {
    padding: 8px;
    background-color: rgba(123, 106, 224, .7019607843);
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer
}

.search_for_commuinityBody .commuinity_list .commuinity_list_bottom:hover {
    background-color: #7d6be0;
    color: #fff
}

.MoreComuinity_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 12px
}

.MoreComuinity_list .list_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.MoreComuinity_list .commuinity_list {
    background-color: #ecebf6;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid #d5d5d5
}

.MoreComuinity_list .commuinity_list h5 {
    text-align: center;
    color: rgba(0, 0, 0, .26)
}

.MoreComuinity_list .commuinity_list .commuinity_list_top {
    display: flex
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .comuinity_img {
    height: 55px;
    width: 55px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .comuinity_img img {
    width: 53px;
    height: 53px;
    border-radius: 6px;
    object-fit: cover
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text {
    padding-left: 10px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text .community_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text .commuinity_hint {
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, .6588235294);
    line-height: 18px
}

.MoreComuinity_list .commuinity_list .commuinity_list_bottom {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    background-color: #7d6be0;
    margin-top: 10px;
    cursor: pointer
}

.MoreComuinity_list .commuinity_list .commuinity_list_bottom:hover {
    background-color: #6050b5;
    transition: all linear .2s
}

.MoreComuinity_list .commuinity_list .job_createBtn {
    width: 100%;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 14px;
    background-color: #fff;
    margin-top: 12px
}

.MoreComuinity_list .seeMore_btn {
    text-align: center;
    font-size: 16px;
    color: #888;
    font-weight: 500;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d5d5d5;
    margin-top: 12px;
    cursor: pointer
}

.MoreComuinity_list .seeMore_btn:hover {
    background-color: #ecebf6
}

.card_t {
    color: #0e0d0d;
    font-size: 24px;
    font-weight: 600
}

.Card_container .card_wrap {
    border-radius: 12px !important;
    background-color: #fff;
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, .24) 0px 3px 8px
}

.Card_container .card_wrap:hover {
    box-shadow: rgba(50, 50, 93, .25) 0px 6px 12px -2px, rgba(0, 0, 0, .3) 0px 3px 7px -3px
}

.Card_container .card_wrap span {
    font-size: 18px;
    font-weight: 400;
    color: #484747;
    word-break: break-all;
    line-height: 24px
}

.Card_container .card_wrap ul li {
    font-size: 18px;
    font-weight: 500;
    color: #585858;
    word-break: break-all
}

.CartEmpty_body {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -40%)
}

.CartEmpty_body .cartingIcon {
    height: 85px;
    width: 85px;
    background-color: rgba(216, 189, 252, .6039215686);
    border-radius: 50%;
    text-align: center;
    margin: 0 auto
}

.CartEmpty_body .cartingIcon i {
    font-size: 34px;
    line-height: 85px;
    color: rgba(123, 106, 224, .7019607843)
}

.CartEmpty_body .empty_massage {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    padding: 12px 0 5px 0
}

.CartEmpty_body p {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    line-height: 22px;
    text-align: center
}

.CartEmpty_body .com_info_body {
    padding: 12px
}

.CartEmpty_body .com_info_body .com_details {
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #343333;
    line-height: 24px;
    border: 1px solid rgba(195, 195, 195, .5058823529);
    border-radius: 10px
}

.CartEmpty_body .com_info_body .com_info_wrap {
    padding: 30px 12px 20px 12px
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item {
    font-size: 15px;
    font-weight: 400;
    color: #6c6969;
    padding-top: 6px
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item span {
    font-size: 16px;
    font-weight: 500;
    color: #171717;
    padding-left: 5px
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item_2 {
    display: flex;
    padding-top: 6px
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item_2 .item_lebel {
    font-size: 15px;
    font-weight: 400;
    color: #6c6969;
    width: 20%
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item_2 .info_item_bold {
    font-size: 16px;
    font-weight: 500;
    color: #171717;
    padding-left: 5px;
    width: 80%
}

.CartEmpty_body .com_info_body .com_info_wrap .info_item_title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding: 25px 0 8px 0;
    display: inline-block;
    border-bottom: 1px dashed rgba(195, 195, 195, .8274509804);
    margin-top: 25px;
    margin-bottom: 5px
}

.user_dashBoard {
    margin-bottom: 12px;
    overflow: hidden
}

.ConnectWith {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px
}

.ConnectWith .list_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding-bottom: 5px
}

.ConnectWith .connectWith_item {
    height: 100px;
    background-color: #ecebf6;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 10px;
    margin-top: 8px;
    position: relative
}

.ConnectWith .connectWith_item h5 {
    text-align: center;
    color: rgba(0, 0, 0, .26)
}

.ConnectWith .connectWith_item .job_createBtn {
    width: 100%;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 14px;
    background-color: #fff;
    margin-top: 12px
}

.ConnectWith .connectWith_item .item_content {
    display: flex
}

.ConnectWith .connectWith_item .item_content .connectWith_left {
    height: 60px;
    width: 60px
}

.ConnectWith .connectWith_item .item_content .connectWith_left img {
    height: 57px;
    width: 57px;
    object-fit: cover;
    border-radius: 6px
}

.ConnectWith .connectWith_item .item_content .connectWith_right {
    padding-left: 10px
}

.ConnectWith .connectWith_item .item_content .connectWith_right .connector_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.ConnectWith .connectWith_item .item_content .connectWith_right .connectro_commuinity_name {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    line-height: 18px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup {
    display: flex;
    padding-top: 5px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .Connect_btn {
    padding: 0 14px;
    height: 26px;
    line-height: 26px;
    background-color: #7d6be0;
    text-align: center;
    font-size: 9px;
    color: #fff;
    font-weight: 400;
    border-radius: 15px;
    cursor: pointer;
    margin-right: 5px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .notConnect_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: rgba(226, 15, 15, .479);
    text-align: center;
    cursor: pointer;
    margin-left: 6px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .notConnect_btn i {
    font-size: 16px;
    color: #888;
    line-height: 30px
}

.ConnectWith .seeMore_btn {
    text-align: center;
    font-size: 16px;
    color: #888;
    font-weight: 500;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d5d5d5;
    margin-top: 12px;
    cursor: pointer
}

.ConnectWith .seeMore_btn:hover {
    background-color: #ecebf6
}

.userCard {
    height: 300px;
    width: 100%;
    background-color: #fff;
    border-radius: 13px;
    margin-bottom: 20px;
    border: 1px solid #d5d5d5;
    padding: 12px;
    position: relative
}

.userCard .userProfile {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    margin: 0 auto
}

.userCard .userProfile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #ecebf6
}

.userCard .userCard_content {
    padding-top: 10px
}

.userCard .userCard_content .userName {
    font-size: 17px;
    color: #333;
    font-weight: 500;
    line-height: 22px
}

.userCard .userCard_content .userDesignation {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    padding: 4px 18px;
    background-color: #ecebf6;
    border-radius: 15px;
    margin: 4px 0
}

.userCard .userCard_content .communityName {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 16px;
    padding-top: 2px
}

.userCard .userCard_content .userCard_footerBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_1 {
    height: 38px;
    width: 70%;
    background-color: #7d6be0;
    color: #fff;
    text-align: center;
    line-height: 38px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_2 {
    height: 38px;
    width: 25%;
    background-color: rgba(3, 40, 141, .062745098);
    color: #fff;
    text-align: center;
    line-height: 38px;
    border-radius: 8px;
    cursor: pointer
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_2 i {
    color: #888;
    font-size: 19px
}

.PhotoAdding_Body {
    width: 100%;
    border: 1px solid #d5d5d5;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    padding: 10px
}

.PhotoAdding_Body .AddPLaceHolder {
    text-align: center
}

.PhotoAdding_Body .AddPLaceHolder .AddPlace_icon {
    height: 80px;
    width: 80px;
    background-color: #ecebf6;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto
}

.PhotoAdding_Body .AddPLaceHolder .AddPlace_icon i {
    font-size: 30px;
    color: #888;
    line-height: 80px
}

.PhotoAdding_Body .AddPLaceHolder h4 {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    padding-top: 6px
}

.PhotoAdding_Body .AddPLaceHolder h6 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(94, 94, 94, .7254901961);
    line-height: 10px
}

.AddingLIst .AddingList_item {
    height: 50px;
    width: 50px;
    background-color: #ecebf6;
    border-radius: 5px
}

.modal-footer {
    justify-content: flex-start
}

.uploader .backdrop_contorller {
    z-index: 6
}

.uploader_dial {
    bottom: 90px !important
}

.media_content_body {
    margin-top: 12px
}

.folder_area {
    margin-bottom: 20px
}

.my_files_navigate {
    height: 50px;
    width: 100%;
    background-color: #fff;
    padding: 0 12px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px
}

.my_files_navigate .navigate_part_one {
    display: flex;
    align-items: center
}

.my_files_navigate .navigate_part_one .navigate_Back {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
    padding-right: 20px;
    cursor: pointer
}

.my_files_navigate .navigate_part_one .navigate_bar_icon {
    display: flex;
    align-items: center;
    justify-content: center
}

.my_files_navigate .navigate_part_one .navigate_bar_icon .MuiSvgIcon-root {
    font-size: 26px;
    color: #888;
    cursor: pointer
}

.my_files_navigate .navigate_part_one .navigate_bar_icon .MuiSvgIcon-root:hover {
    color: #333
}

.my_files_navigate .navigate_part_one .navigate_title {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    padding: 0 0 0 15px;
    cursor: pointer
}

.my_files_navigate .navigate_part_two .navigate_serachbar {
    height: 35px;
    width: 200px;
    position: relative
}

.my_files_navigate .navigate_part_two .navigate_serachbar .form_control {
    background-clip: padding-box;
    background-color: #f3f3f3;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    color: #333;
    height: 35px;
    padding: 0 10px 0 10px;
    position: relative;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    font-size: 18px
}

.my_files_navigate .navigate_part_two .navigate_serachbar .form_control::placeholder {
    color: #aba8a8;
    font-size: 15px;
    font-weight: 400
}

.Media_area {
    padding-top: 5px
}

.my_item {
    height: 220px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 10px;
    position: relative
}

.my_item .item_icon {
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center
}

.my_item .item_icon img {
    max-width: 100%;
    max-height: 100%
}

.my_item .myItemBookmark {
    position: absolute;
    top: 12px;
    right: 12px
}

.my_item .myItemBookmark .MuiSvgIcon-root {
    font-size: 20px;
    color: #888;
    cursor: pointer
}

.my_item .myItemBookmark .MuiSvgIcon-root:hover {
    color: #7d6be0
}

.my_item .item_name {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    line-height: 21px;
    padding: 10px 0 6px 0
}

.my_item .my_item_bootom {
    height: 40px;
    width: 100%;
    background-color: #ecebf6;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

.my_item .my_item_bootom .uploaded_date {
    font-size: 12px;
    color: #333;
    font-weight: 400
}

.my_item .Folder_list_activity {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    background-color: #ecebf6;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: end
}

.HomeBodyContent {
    padding: 15px 0 15px 0
}

.HomeBodyContent .store_Card {
    height: 130px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer
}

.HomeBodyContent .store_Card:hover {
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1)
}

.HomeBodyContent .store_Card .store_Card_top .overviewItem_icon {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecebf6;
    border-radius: 10px
}

.HomeBodyContent .store_Card .store_Card_top .overviewItem_icon img {
    max-width: 60%;
    max-height: 60%
}

.HomeBodyContent .store_Card .store_Card_top .card_name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    padding-top: 10px
}

.HomeBodyContent .store_Card .store_Card_top .card_file_quantity {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.my_file_item {
    height: 220px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden
}

.my_file_item .item_icon {
    height: 115px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden
}

.my_file_item .item_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.my_file_item .item_name {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 0 6px 0;
    word-wrap: break-word
}

.my_file_item .my_item_bootom {
    height: 38px;
    width: 100%;
    background-color: #ecebf6;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px
}

.my_file_item .my_item_bootom .uploaded_date {
    font-size: 12px;
    color: #333;
    font-weight: 400
}

.my_file_item .File_list_activity {
    position: absolute;
    bottom: 8px;
    right: 3px;
    z-index: 2
}

.my_file_item .File_list_activity i .MuiSvgIcon-root {
    color: rgba(75, 78, 81, .718);
    font-size: 20px;
    cursor: pointer
}

.my_file_item .File_list_activity i .MuiSvgIcon-root:hover {
    color: #333
}

.upload_dialouge_title {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #333
}

.upload_dialouge_title .uploader_dialogue_close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    cursor: pointer
}

.upload_dialouge_title .uploader_dialogue_close:hover {
    color: #333
}

.upload_dialouge_body {
    width: 500px
}

.upload_dialouge_body .uploader_Content {
    padding: 20px;
    width: 100%
}

.upload_dialouge_body .uploader_Content .uploader_icon {
    height: 100px;
    width: 100px;
    background-color: #ecebf6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
}

.upload_dialouge_body .uploader_Content .uploader_icon img {
    height: 55px;
    width: 55px;
    object-fit: contain
}

.upload_dialouge_body .uploader_Content h4 {
    font-size: 18px;
    color: #888;
    font-weight: 500;
    padding-top: 10px
}

.homeMainContent {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.homeMainContent .backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 20px;
    color: #888;
    cursor: pointer
}

.homeMainContent .backArrow:hover {
    color: #333
}

.homeMainContent .login_D_form {
    height: 500px;
    box-shadow: 0px 1px 1px 1px rgba(101, 101, 101, .1294117647);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden
}

.homeMainContent .login_D_form .login_d_left {
    height: 100%;
    width: 450px;
    padding: 20px 20px 15px 20px;
    position: relative;
    overflow-y: scroll
}

.homeMainContent .login_D_form .login_d_left .commuinity_name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #333
}

.homeMainContent .login_D_form .login_d_left .Community_img {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0
}

.homeMainContent .login_D_form .login_d_left .Community_img img {
    max-height: 100%;
    max-width: 100%
}

.homeMainContent .login_D_form .login_d_left .community_location {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.homeMainContent .login_D_form .login_d_left .community_location .MuiSvgIcon-root {
    color: #7d6be0;
    font-size: 20px
}

.homeMainContent .login_D_form .login_d_left .tag_line {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
    padding: 10px 0 5px 0
}

.homeMainContent .login_D_form .login_d_left p {
    font-size: 14px;
    color: #888;
    line-height: 22px;
    text-align: start;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_left .commnity_contactor {
    font-size: 15px;
    color: #888;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_left .createCommuinity {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 20px
}

.homeMainContent .login_D_form .login_d_left .createCommuinity .createCommunityBtn {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    text-align: center;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center
}

.homeMainContent .login_D_form .login_d_left .createCommuinity .createCommunityBtn:hover {
    background-color: #f3f3f3;
    color: #333
}

.homeMainContent .login_D_form .login_d_right {
    height: 500px;
    width: 360px;
    background-color: #7d6be0;
    padding: 30px 30px 15px 30px;
    overflow-y: scroll
}

.homeMainContent .login_D_form .login_d_right h6 {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    text-align: center
}

.homeMainContent .login_D_form .login_d_right .login_bday {
    padding-top: 22px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed {
    position: relative
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_btn {
    margin-top: 15px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loading_cercle {
    width: 100%;
    display: flex;
    justify-content: center
}

.homeMainContent .login_D_form .login_d_right .login_bday form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .login_bday form h3:hover {
    color: #e3e2e2
}

.homeMainContent .login_D_form .login_d_right .registerBody {
    padding-top: 12px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed {
    position: relative
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .emailBody {
    padding-top: 40px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .login_D_form .login_d_right .registerBody form .agrrementCheq {
    padding-top: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center
}

.homeMainContent .login_D_form .login_d_right .registerBody form .agrrementCheq .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .get_emailBtn {
    margin-top: 10px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .registerBody form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .registerBody form h3:hover {
    color: #e3e2e2
}

.homeMainContent .login_D_form .login_d_right .registerBody .coverImg {
    display: flex;
    justify-content: center;
    margin: 10px auto
}

.homeMainContent .login_D_form .login_d_right .registerBody .coverImg img {
    height: 90px;
    width: 90px;
    object-fit: contain;
    border-radius: 50%;
    border: 2px solid #fff
}

.homeMainContent .invite_D_form {
    height: 550px;
    box-shadow: 0px 1px 1px 1px rgba(101, 101, 101, .1294117647);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden
}

.homeMainContent .invite_D_form .invite_d_left {
    height: 100%;
    width: 450px;
    padding: 20px 20px 15px 20px;
    position: relative
}

.homeMainContent .invite_D_form .invite_d_left .commuinity_name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #333
}

.homeMainContent .invite_D_form .invite_d_left .Community_img {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0
}

.homeMainContent .invite_D_form .invite_d_left .Community_img img {
    max-height: 100%;
    max-width: 100%
}

.homeMainContent .invite_D_form .invite_d_left .community_location {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.homeMainContent .invite_D_form .invite_d_left .community_location .MuiSvgIcon-root {
    color: #7d6be0;
    font-size: 20px
}

.homeMainContent .invite_D_form .invite_d_left .tag_line {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
    padding: 10px 0 5px 0
}

.homeMainContent .invite_D_form .invite_d_left p {
    font-size: 14px;
    color: #888;
    line-height: 22px;
    text-align: start;
    font-weight: 400
}

.homeMainContent .invite_D_form .invite_d_left .commnity_contactor {
    font-size: 15px;
    color: #888;
    font-weight: 400
}

.homeMainContent .invite_D_form .invite_d_left .createCommuinity {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 20px
}

.homeMainContent .invite_D_form .invite_d_left .createCommuinity .createCommunityBtn {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    text-align: center;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center
}

.homeMainContent .invite_D_form .invite_d_left .createCommuinity .createCommunityBtn:hover {
    background-color: #f3f3f3;
    color: #333
}

.homeMainContent .invite_D_form .invite_d_right {
    height: 550px;
    width: 360px;
    background-color: #7d6be0;
    padding: 30px 30px 15px 30px
}

.homeMainContent .invite_D_form .invite_d_right h6 {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    text-align: center
}

.homeMainContent .invite_D_form .invite_d_right .login_bday {
    padding-top: 22px
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed {
    position: relative
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .form_control {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .login_btn {
    margin-top: 15px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form .loading_cercle {
    width: 100%;
    display: flex;
    justify-content: center
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .invite_D_form .invite_d_right .login_bday form h3:hover {
    color: #e3e2e2
}

.homeMainContent .invite_D_form .invite_d_right .registerBody {
    padding-top: 12px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody .invite_check label span {
    font-size: 14px;
    color: #fff !important
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed {
    position: relative;
    margin-top: -8px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .emailBody {
    padding-top: 40px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .agrrementCheq {
    padding-top: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .agrrementCheq .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form .get_emailBtn {
    margin-top: 10px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .invite_D_form .invite_d_right .registerBody form h3:hover {
    color: #e3e2e2
}

.homeMainContent .invite_D_form .invite_d_right .registerBody .coverImg {
    display: flex;
    justify-content: center;
    margin: 10px auto
}

.homeMainContent .invite_D_form .invite_d_right .registerBody .coverImg img {
    height: 90px;
    width: 90px;
    object-fit: contain;
    border-radius: 50%;
    border: 2px solid #fff
}

.loginPowerd {
    position: absolute;
    bottom: 20px;
    right: 50px;
    font-size: 16px;
    color: #888;
    font-weight: 400;
    font-family: "Open Sans", sans-serif
}

.creatCommuinity_form_body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.creatCommuinity_form_body .backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 20px;
    color: #888;
    cursor: pointer
}

.creatCommuinity_form_body .backArrow:hover {
    color: #333
}

.creatCommuinity_form_body .creatCommuinity_wrapper {
    background-color: #fff;
    padding: 80px 30px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper h5 {
    font-size: 24px;
    color: #333;
    font-weight: 500;
    text-align: center
}

.creatCommuinity_form_body .creatCommuinity_wrapper h6 {
    font-size: 15px;
    font-weight: 400;
    color: #888;
    text-align: center
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap {
    width: 500px;
    padding-top: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #ebebeb;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control:focus {
    outline: 0
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control::placeholder {
    font-size: 15px;
    color: #818181;
    font-weight: 400
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control:optional {
    color: #3a3a3a
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .agrrementCheq {
    padding-top: 5px;
    font-size: 15px;
    color: #888
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .agrrementCheq .chequeB {
    height: 16px;
    width: 16px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .login_btn {
    background-color: #7d6be0;
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
    cursor: pointer;
    margin-top: 5px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin-top: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form h3:hover {
    color: #333
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap textarea {
    min-height: 100px !important
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap .pass_left {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 20px;
    font-size: 14px;
    color: #888
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 10px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .CreateForm_lg {
    width: 700px
}

.genarel_login_bday {
    padding: 20px;
    height: 460px;
    width: 350px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.genarel_login_bday form .login_img {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0 15px 0
}

.genarel_login_bday form .login_img img {
    height: 45px;
    width: auto
}

.genarel_login_bday form h4 {
    font-size: 22px;
    font-weight: 500;
    color: #333;
    line-height: 27px;
    text-align: center;
    padding-bottom: 12px
}

.genarel_login_bday form .loginInput_filed {
    position: relative
}

.genarel_login_bday form .loginInput_filed .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-top: 4px
}

.genarel_login_bday form .loginInput_filed .form_control:focus {
    outline: 0
}

.genarel_login_bday form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.genarel_login_bday form .loginInput_filed .form_control:optional {
    color: #636262
}

.genarel_login_bday form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px
}

.genarel_login_bday form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #888
}

.genarel_login_bday form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 3px
}

.genarel_login_bday form .login_pass_save .pass_right {
    font-size: 14px;
    color: #888;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.genarel_login_bday form .login_pass_save .pass_right:hover {
    color: #333
}

.genarel_login_bday form .login_loader {
    width: 100%;
    display: flex;
    justify-content: center
}

.genarel_login_bday form h3 {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    margin-top: 26px
}

.genarel_login_bday form h3:hover {
    color: #333
}

.copy_right {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #888;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 15px
}

.Home_copy_right {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #888;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 15px
}

.uploadBox {
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, .02);
    margin: .5em auto;
    position: relative;
    border-radius: 10px;
    border: 1px dashed #d9d9d9
}

.uploadBox:hover {
    background: #ecebf6
}

.uploadBox input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.uploadBox h1 {
    text-align: center;
    color: #333;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: 400
}

.image_place_container {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin: 0 auto
}

.image_place_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px
}

.image_place_container .img_place_overly {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px
}

.image_place_container .img_place_overly .img_cross_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #888;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.image_place_container .img_place_overly .img_cross_btn .MuiSvgIcon-root {
    font-size: 16px
}

.userCommuinity_list_wrapper {
    padding: 10px
}

.entry_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: #f0f0f0
}

.entry_header .entry_header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0
}

.entry_header .entry_header_wrap .entryHeader_logo img {
    height: 60px;
    width: auto
}

.entry_header .entry_header_wrap .entryHeader_right {
    display: flex;
    align-items: center
}

.entry_header .entry_header_wrap .entryHeader_right .createBtn {
    height: 46px;
    width: auto;
    padding: 0 40px;
    border: 1px solid #1976d2;
    border-radius: 7px;
    color: #1976d2;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-left: 20px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed {
    margin-left: 10px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .MuiButtonBase-root {
    padding: 0px !important
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .profile_img {
    height: 50px;
    width: 50px;
    border: 5px solid rgba(225, 223, 223, .8235294118);
    border-radius: 50%
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper {
    width: 300px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    border-radius: 4px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_profile {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    background-color: rgba(207, 214, 223, .5019607843);
    margin-bottom: 12px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_profile .userName {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    padding-left: 8px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list {
    padding: 6px 6px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list:hover {
    background-color: #f3f3f3;
    border-radius: 5px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list i {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: rgba(207, 214, 223, .5019607843);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list i .MuiSvgIcon-root {
    font-size: 22px;
    color: #333
}

.entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list span {
    font-size: 15px
}

.entryWrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 50px 0;
    position: relative
}

.entryWrapper .entry_wrap {
    width: 100%
}

.entryWrapper .entry_wrap .home_title {
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    padding-bottom: 35px
}

.entryWrapper .entry_wrap .commuinity_search_form {
    width: 500px;
    margin: 0 auto
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar {
    position: relative;
    width: 100%
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control:focus {
    outline: 0
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control:optional {
    color: #636262
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.entryWrapper .entry_wrap .community_list_item {
    padding-top: 40px;
    padding-bottom: 40px
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item {
    height: 350px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 13px;
    padding: 12px;
    position: relative
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinty_img {
    height: 160px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f3f3f3;
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinty_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_name {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    line-height: 25px;
    padding-top: 15px;
    text-align: center
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_location {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px;
    text-align: center;
    padding-top: 5px
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_location span {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #333
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 12px;
    display: flex;
    justify-content: space-around;
    align-items: center
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer .viewBtn {
    height: 38px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
    width: 100%
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer .viewBtn:hover {
    background-color: #7d6be0;
    color: #fff;
    transition: all linear .2s
}

.community_list_item {
    padding-top: 100px
}

.community_list_item .commuinity_item {
    height: 350px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 13px;
    padding: 12px;
    position: relative
}

.community_list_item .commuinity_item .commuinty_img {
    height: 160px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
}

.community_list_item .commuinity_item .commuinity_name {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    line-height: 25px;
    padding-top: 15px;
    text-align: center
}

.community_list_item .commuinity_item .commuinity_location {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px;
    text-align: center;
    padding-top: 5px
}
.packages_details{
    padding-top: 20px;
}
.card-left{
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #027df2;
}
.community_list_item .commuinity_item .commuinity_location span {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #333
}

.community_list_item .commuinity_item .card_footer {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 12px
}

.community_list_item .commuinity_item .card_footer .viewBtn {
    height: 38px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    color: #888;
    cursor: pointer
}

.community_list_item .commuinity_item .card_footer .viewBtn:hover {
    background-color: #7d6be0;
    color: #fff;
    transition: all linear .2s
}

.btn_loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px
}

.login_qr_img {
    display: flex;
    justify-content: center;
    margin-top: 25px
}

.login_qr_img img {
    height: 130px;
    width: 130px;
    object-fit: contain
}

.searchLoading {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, .26)
}

.backArrow_head {
    position: absolute;
    left: 20px;
    top: 15px;
    height: 38px;
    width: 38px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333
}

.pass_eye_btn {
    position: absolute !important;
    top: 50% !important;
    right: 12px !important;
    transform: translateY(-50%) !important
}

.profileBody .nav {
    background-color: #fff;
    --bs-nav-link-padding-y: 1rem;
    --bs-nav-link-padding-x: 1rem;
    border-radius: 10px
}

.profileBody .nav .nav-item .nav-link {
    margin-left: 12px
}

.profileBody .nav .nav-item .nav-link:hover {
    isolation: isolate;
    border-color: #fff
}

.profileBody .nav .nav-item .active {
    border-bottom: 3px solid #7d6be0 !important
}

.profileBody .my_friend {
    height: 600px;
    width: 100%;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 13px;
    padding: 15px;
    margin-top: 10px
}

.profileBody .my_friend .myFriend_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.profileBody .my_friend .myFriend_top .heading {
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.profileBody .my_friend .myFriend_top .search_box {
    max-width: 210px;
    width: 210px;
    position: relative;
    height: 40px
}

.profileBody .my_friend .myFriend_top .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    padding: 12px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #dededf;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.profileBody .my_friend .myFriend_top .search_box form .form_control:focus {
    outline: 0
}

.profileBody .my_friend .myFriend_top .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.profileBody .my_friend .myFriend_top .search_box form .form_control:optional {
    color: #636262
}

.profileBody .my_friend .myFriend_top .search_box form i {
    font-size: 16px;
    color: #888;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer
}

.profileBody .my_friend .friend_item_List .friend_item {
    display: flex;
    align-items: center;
    height: 90px;
    background-color: #ecebf6;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 10px;
    margin-top: 8px;
    position: relative
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_left {
    height: 55px;
    width: 55px;
    border-radius: 50%
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right {
    padding-left: 10px
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right .friend_name {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    line-height: 22px
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right .connectro_commuinity_name {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px
}

.profileBody .my_friend .friend_item_List .friend_item .frined_droper {
    position: absolute;
    top: 10px;
    right: 10px
}

.profileBody .my_friend .friend_item_List .friend_item .frined_droper .dropdown-toggle {
    background-color: rgba(3, 40, 141, .062745098);
    color: rgba(51, 51, 51, .7725490196)
}

.user_profile .cover-photo {
    height: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative
}

.user_profile .cover-photo .overly_content {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    display: flex
}

.user_profile .cover-photo .overly_content i {
    background: #6d6b6a;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.user_profile .cover-photo .overly_content i .MuiSvgIcon-root {
    color: #fff;
    font-size: 22px
}

.user_profile .user_profile_content {
    padding: 20px
}

.user_profile .user_profile_content .content_top {
    display: flex
}

.user_profile .user_profile_content .content_top .user_profileImg {
    height: 100px;
    width: 100px;
    margin-left: 10px;
    position: relative;
    z-index: 2;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -15px
}

.user_profile .user_profile_content .content_top .user_profileImg img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.user_profile .user_profile_content .content_top .user_profileImg i {
    position: absolute;
    bottom: 20px;
    right: 15px;
    z-index: 3;
    background: #6d6b6a;
    padding: 5px;
    border-radius: 5px
}

.user_profile .user_profile_content .content_top .user_profileImg i .MuiSvgIcon-root {
    color: #fff
}

.user_profile .user_profile_content .content_top .profile-name {
    padding-left: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #333
}

.user_profile .user_profile_content .content_top .profile-name .user_name {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    display: flex
}

.user_profile .user_profile_content .content_top .profile-name .user_name i {
    height: 28px;
    width: 28px;
    border: 1px solid rgba(195, 195, 195, .6431372549);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px
}

.user_profile .user_profile_content .content_top .profile-name .user_name i .MuiSvgIcon-root {
    font-size: 14px;
    color: rgba(88, 88, 88, .9490196078)
}

.user_profile .user_profile_content .content_top .profile-name .userDesignation {
    font-size: 14px;
    color: #474747;
    font-weight: 400;
    display: flex
}

.user_profile .user_profile_content .content_top .profile-name .userDesignation i {
    height: 20px;
    width: 20px;
    border: 1px solid rgba(195, 195, 195, .6431372549);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px
}

.user_profile .user_profile_content .content_top .profile-name .userDesignation i .MuiSvgIcon-root {
    font-size: 12px;
    color: rgba(88, 88, 88, .9490196078)
}

.user_profile .user_profile_content .userAbout {
    border: 1px solid #d5d5d5;
    padding: 10px;
    border-radius: 8px;
    margin-top: 16px
}

.user_profile .user_profile_content .userAbout p {
    font-size: 13px;
    color: #333;
    line-height: 22px
}

.user_profile .user_profile_content .userAbout i {
    bottom: 20px;
    right: 15px;
    z-index: 3;
    background: #6d6b6a;
    padding: 5px;
    border-radius: 5px
}

.user_profile .user_profile_content .userAbout i .MuiSvgIcon-root {
    color: #fff
}

.user_profile .user_profile_content .user_icons_list {
    display: flex;
    justify-content: end;
    margin-top: 20px
}

.user_profile .user_profile_content .user_icons_list a {
    margin-left: 12px
}

.user_profile .user_profile_content .user_icons_list a i {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6d6b6a;
    padding: 5px
}

.user_profile .user_profile_content .user_icons_list a i .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #fff
}

.user_profile .user_profile_content .user_icons_list a .linkedin_clr {
    background-color: #0072b1
}

.user_profile .user_profile_content .user_icons_list a .github_clr {
    background-color: #171515
}

.user_profile .user_profile_content .user_icons_list a .youtube_clr {
    background-color: #c4302b
}

.user_profile .user_profile_content .user_icons_list a .twitter_clr {
    background-color: #00acee
}

.user_profile .user_profile_content .user_icons_list a .insta_clr {
    background-color: #e1306c
}

.user_profile .user_profile_content .user_icons_list a .website_clr {
    background-color: #525151
}

.user_profile .user_profile_content .user_icons_list a .whatsapp_clr {
    background-color: #45c755
}

.user_profile .user_profile_content .user_icons_list a .skype_clr {
    background-color: #00aff0;
    color: #fff;
    font-size: 26px
}

.user_dashBoard {
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px
}

.bannerImg {
    height: 200px;
    width: 100%
}

.bannerImg img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.profile-Loader {
    margin: 0 auto
}

.AddingList_item img {
    height: 100%;
    width: 100%
}

.errorComponentImge img {
    margin-left: 300px;
    margin-top: 100px
}

.ant-upload-list-item-error {
    border-color: #0b5ed7 !important
}

.ant-upload-list-item-name {
    color: #0b5ed7 !important
}

.anticon {
    color: #0b5ed7 !important
}

.anticon svg path[fill="#1890ff"] {
    fill: #0b5ed7 !important
}

.jobsearchButtonAndText {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.jobsearchButtonAndText .jobsearchButton {
    margin-left: 150px
}

.Card_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 170px
}

.uploadBannerBox {
    padding-top: 85px;
    width: 160px;
    height: 120px;
    margin: .5em auto;
    position: relative
}

.bannerImg img {
    width: 100%;
    height: 100%;
    padding: 5px
}

.cursorPointer {
    cursor: pointer
}

.user_profile .user_profile_content .user_join_date {
    display: flex;
    justify-content: start;
    margin-top: 20px
}

.user_profile .user_profile_content .user_join_date span {
    font-weight: 600;
    margin-left: 3px
}

.comments-container {
    width: 768px
}

.comments-container h1 {
    font-size: 36px;
    color: #283035;
    font-weight: 400
}

.comments-container h1 a {
    font-size: 18px;
    font-weight: 700
}

.comments-list {
    position: relative
}

.comments-list:before {
    content: "";
    width: 2px;
    height: 100%;
    background: #c7cacb;
    position: absolute;
    left: 32px;
    top: 0
}

.comments-list:after {
    content: "";
    position: absolute;
    background: #c7cacb;
    bottom: 0;
    left: 27px;
    width: 7px;
    height: 7px;
    border: 3px solid #dee1e3;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

.reply-list:before, .reply-list:after {
    display: none
}

.reply-list li:before {
    content: "";
    width: 60px;
    height: 2px;
    background: #c7cacb;
    position: absolute;
    top: 25px;
    left: -55px
}

.comments-list li {
    margin-bottom: 15px;
    display: block;
    position: relative;
    z-index: 1
}

.comments-list li:after {
    content: "";
    display: block;
    clear: both;
    height: 0;
    width: 0
}

.reply-list {
    padding-left: 88px;
    clear: both;
    margin-top: 15px
}

.comments-list .comment-avatar {
    width: 65px;
    height: 65px;
    position: relative;
    z-index: 99;
    float: left;
    border: 3px solid #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    overflow: hidden
}

.comments-list .comment-avatar img {
    width: 100%;
    height: 100%
}

.reply-list .comment-avatar {
    width: 50px;
    height: 50px
}

.comment-main-level:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    clear: both
}

.comments-list .comment-box {
    width: 680px;
    float: right;
    position: relative
}

.comments-list .comment-box:before, .comments-list .comment-box:after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    display: block;
    border-width: 10px 12px 10px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0) #fcfcfc;
    top: 8px;
    left: -11px
}

.comments-list .comment-box:before {
    border-width: 11px 13px 11px 0;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, .05);
    left: -12px
}

.reply-list .comment-box {
    width: 610px
}

.comment-box .comment-head {
    background: #fcfcfc;
    padding: 10px 12px;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}

.comment-box .comment-head i {
    float: right;
    margin-left: 14px;
    position: relative;
    top: 2px;
    color: #a6a6a6;
    cursor: pointer;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease
}

.comment-box .comment-head .MuiSvgIcon-root {
    font-size: 18px;
    color: #4f4e4e;
    margin-left: 12px
}

.comment-box .comment-head i:hover {
    color: #03658c
}

.comment-box .comment-name {
    color: #283035;
    font-size: 14px;
    font-weight: 700;
    float: left;
    margin-right: 10px
}

.comment-box .comment-name a {
    color: #283035
}

.comment-box .comment-head span {
    float: left;
    color: #999;
    font-size: 13px;
    position: relative;
    top: 1px
}

.comment-box .comment-content {
    background: #fff;
    padding: 12px;
    font-size: 15px;
    color: #595959;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
    white-space: pre-line
}

.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {
    color: #03658c
}

.comment-box .comment-name.by-author:after {
    content: "Author";
    background: #03658c;
    color: #fff;
    font-size: 12px;
    padding: 3px 5px;
    font-weight: 700;
    margin-left: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px
}

@media only screen and (max-width: 766px) {
    .comments-container {
        width: 480px
    }

    .comments-list .comment-box {
        width: 390px
    }

    .reply-list .comment-box {
        width: 320px
    }
}

.pro_img_container .pro_img {
    height: 120px;
    width: 100%;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3
}

.pro_img_container .pro_img img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.pro_img_container .pro_img i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    width: 35px;
    background-color: rgba(221, 221, 221, .95);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.bannerImg_complete {
    position: relative !important;
    height: 220px
}

.bannerImg_complete img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.bannerImg_complete i {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 35px;
    width: 35px;
    background-color: rgba(221, 221, 221, .95);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.xi-cropper-wrap {
    display: inline-block
}

.xi-cropper-wrap .xi-cropper-upload input {
    position: absolute;
    display: inline-block;
    width: 80px;
    height: 80px;
    opacity: 0;
    cursor: pointer;
    z-index: 10
}

.xi-cropper-wrap .xi-cropper-upload .xi-cropper-file {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    font-size: 24px;
    color: #ccc;
    border-radius: 6px;
    background-color: rgba(224, 222, 222, .2);
    border: 1px solid #d0cfcf
}

.xi-cropper-wrap .xi-cropper-upload .xi-cropper-file img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.xi-cropper-wrap .xi-cropper-upload .xi-cropper-file .xi-cropper-del {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    background-color: #ca3305;
    color: #fff;
    right: -6px;
    top: -6px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 11
}

.xi-cropper-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.xi-cropper-modal .xi-cropper-modalMask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .3)
}

.xi-cropper-modal .cropper-modal-content {
    position: relative;
    z-index: 10000;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    background-color: #fff
}

.xi-cropper-modal .cropper-modal-content .cropper-modal-header {
    padding: 0 16px;
    line-height: 2.4;
    font-size: 14px
}

.xi-cropper-modal .cropper-modal-content .cropper-modal-header .cropper-modal-close-btn {
    color: #ccc;
    float: right;
    cursor: pointer
}

.xi-cropper-modal .cropper-modal-content .crop-controlWrap {
    padding: 6px 12px;
    display: flex;
    justify-content: center
}

.xi-cropper-modal .cropper-modal-content .crop-controlWrap .crop-control {
    margin-right: 12px;
    font-size: 12px;
    display: flex;
    align-items: center
}

.xi-cropper-modal .cropper-modal-content .crop-controlWrap .crop-control .crop-control-item {
    margin-right: 6px;
    display: inline-block;
    padding: 1px 3px;
    border-radius: 2px;
    color: #828080;
    cursor: pointer
}

.xi-cropper-modal .cropper-modal-content .crop-controlWrap .crop-control .crop-control-item input {
    width: 68px
}

.xi-cropper-modal .cropper-modal-content .crop-controlWrap .crop-control .crop-control-item:hover {
    background-color: #f0f0f0
}

.xi-cropper-modal .cropper-modal-content .cropper-modal-footer {
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    border-top: 1px solid #f0f0f0
}

.xi-cropper-modal .cropper-modal-content .cropper-modal-footer .modal-ok, .xi-cropper-modal .cropper-modal-content .cropper-modal-footer .modal-cancel {
    color: #fff;
    background-color: #06c;
    border-radius: 6px;
    padding: 2px 10px;
    cursor: pointer
}

.xi-cropper-modal .cropper-modal-content .cropper-modal-footer .modal-cancel {
    margin-right: 12px;
    background-color: #f0f0f0;
    color: gray
}

.css-dvxtzn {
    background: blue !important;
    color: #fff !important
}

.css-pdct74-MuiTablePagination-selectLabel {
    margin-top: 12px !important
}

.css-levciy-MuiTablePagination-displayedRows {
    margin-top: 12px !important
}

.css-1chpzqh {
    margin-top: 12px !important
}

:where(.css-dev-only-do-not-override-1ij74fp).ant-tooltip .ant-tooltip-content {
    display: none !important
}

.cursor_denied:hover {
    cursor: not-allowed
}

.anticon svg {
    color: #fff !important
}

.update_btn {
    position: fixed;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2
}

.update_btn button {
    padding: 7px;
    border-radius: 5px;
    background-color: #8975f8;
    color: #fff
}

.content {
    white-space: pre-line
}

.previewFooter {
    background-color: #f5f5f5;
    padding: 10px
}

.previewFooter .domainName {
    font-size: 18px;
    color: gray
}

.previewFooter .title {
    font-size: 20px;
    color: #171717;
    font-weight: 800
}

.previewFooter .description {
    font-size: 13px;
    color: gray
}

.anchorWhite {
    color: #fff !important
}

.upload-content {
    padding: 20px 20px;
    background: #fff;
    border-radius: 10px
}

.input_box {
    position: relative
}

.input_box_dropper i {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer
}

.input_box_dropper {
    min-height: 220px;
    max-height: 380px;
    width: 100%;
    background-color: #fff;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.1294117647);
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 10;
    padding: 10px;
    overflow-y: scroll
}

.input_box_dropper ul li {
    padding: 5px 10px;
    cursor: pointer;
    color: #444
}

.input_box_dropper ul li:hover {
    background-color: #f3f3f3;
    border-radius: 5px
}

.uploader .backdrop_contorller {
    z-index: 6
}

.uploader_contianer {
    padding: 10px 10px 20px 10px;
    width: 100%
}

.uploader_contianer .folder_name_input {
    width: 100%;
    margin-bottom: 12px
}

.uploader_contianer .folder_name_input .css-1x5jdmq {
    min-width: 392px !important
}

.uploader_contianer .Upload_btn {
    width: 100%;
    margin-top: 12px;
    height: 40px;
    color: "#fff !important"
}

.css-lqq9ua {
    display: none !important
}

.css-5t8t4o {
    width: 420px !important
}

.css-13ijswq {
    background: #6ba4d1 !important;
    height: 200px !important;
    position: relative !important
}

.css-9ufzdr {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
    margin-top: 0px !important
}

.css-15bzofy img {
    display: none !important
}

.recycle_img {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 200px
}

.recycle_img img {
    max-width: 100%;
    max-height: 100%
}

.upload_progress_container {
    width: 300px;
    background-color: #fff;
    height: 70px;
    position: fixed;
    top: 30px;
    right: 0;
    z-index: 1302;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.upload_progress_container .upload_progress {
    width: 300px
}

.upload_progress_container .upload_progress h6 {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    padding-right: 10px;
    text-align: center;
    padding-bottom: 2px
}

.upload_progress_container .upload_progress .MuiBox-root {
    padding: 0 2px 0 4px
}

.upload_progress_container .upload_progress .MuiBox-root .MuiLinearProgress-root {
    height: 10px;
    background-color: #f1efef;
    border-radius: 35px
}

.upload_progress_container .upload_progress .MuiBox-root .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: #7d6be0
}

.upload_progress_container .upload_progress .MuiTypography-root {
    color: #333
}

.upload_progress_container .upload_progress .upload_cancle {
    position: absolute;
    right: 10px;
    top: 5px
}

.upload_progress_container .upload_progress .upload_cancle:hover .MuiSvgIcon-root {
    color: #333
}

.upload_progress_container .upload_progress .upload_cancle .MuiSvgIcon-root {
    color: #969393;
    cursor: pointer
}

.swal2-container {
    z-index: 1303 !important
}

.css-zq6grw {
    display: none !important
}

.chat {
    width: 100%;
    border-radius: 13px;
    display: flex;
    overflow: hidden;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff
}

.chat .chat_left {
    background-color: #fff;
    padding: 15px;
    height: 87vh;
    width: 330px;
    border-right: 1px solid rgba(192, 192, 192, .5803921569)
}

.chat .chat_left .chat_top_content {
    height: 170px
}

.chat .chat_left .chat_top_content .chat_left_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.chat .chat_left .chat_top_content .chat_left_top .chat_h {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    display: inline-block
}

.chat .chat_left .chat_top_content .chat_left_top .chat_h i .MuiSvgIcon-root {
    margin-right: 5px;
    cursor: pointer
}

.chat .chat_left .chat_top_content .chat_search .search_box {
    width: 100%;
    position: relative;
    height: 45px;
    margin-top: 15px
}

.chat .chat_left .chat_top_content .chat_search .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: #333;
    background-color: rgba(229, 233, 237, .7215686275);
    background-clip: padding-box;
    border-radius: 7px;
    border: 1px solid #e1e1e1;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.chat .chat_left .chat_top_content .chat_search .search_box form .form_control:focus {
    outline: 0
}

.chat .chat_left .chat_top_content .chat_search .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.chat .chat_left .chat_top_content .chat_search .search_box form i {
    font-size: 20px;
    color: #7d6be0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block
}

.chat .chat_left .chat_top_content .active_list {
    position: relative
}

.chat .chat_left .chat_top_content .active_list .swiper-button-next {
    height: 32px;
    width: 32px;
    background-color: rgba(255, 255, 255, .9411764706);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    right: 0;
    top: 55%
}

.chat .chat_left .chat_top_content .active_list .swiper-button-next::after {
    font-size: 16px;
    color: #333
}

.chat .chat_left .chat_top_content .active_list .swiper-button-prev {
    height: 32px;
    width: 32px;
    background-color: rgba(255, 255, 255, .9411764706);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    left: 0;
    top: 55%
}

.chat .chat_left .chat_top_content .active_list .swiper-button-prev::after {
    font-size: 16px;
    color: #333
}

.chat .chat_left .chat_top_content .active_list .swiper-button-disabled {
    display: none
}

.chat .chat_left .chat_top_content .active_list .active_title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px
}

.chat .chat_left .chat_top_content .active_list .activeMamber {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    position: relative
}

.chat .chat_left .chat_top_content .active_list .activeMamber img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.chat .chat_left .chat_top_content .active_list .activeMamber .active_btn {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #3ccf4e;
    position: absolute;
    bottom: 2px;
    right: 2px
}

.chat .chat_left .chat_list {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 175px;
    padding-top: 10px
}

.chat .chat_left .chat_list .chat_list_item {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: rgba(214, 214, 217, .339);
    cursor: pointer
}

.chat .chat_left .chat_list .chat_list_item:hover {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat .chat_left .chat_list .chat_list_item .profile_active {
    width: 20%
}

.chat .chat_left .chat_list .chat_list_item .profile_active .profile {
    position: relative
}

.chat .chat_left .chat_list .chat_list_item .profile_active .profile .massage_count {
    height: 23px;
    width: 23px;
    background-color: #e94560;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 0;
    right: -5px
}

.chat .chat_left .chat_list .chat_list_item .profile_active .active_online img {
    border: 3px solid #3ccf4e
}

.chat .chat_left .chat_list .chat_list_item .chat_overview {
    width: 75%;
    padding-left: 5px
}

.chat .chat_left .chat_list .chat_list_item .chat_overview .profile-name {
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat .chat_left .chat_list .chat_list_item .chat_overview .overview_massage {
    font-size: 13px;
    color: #7c7c7c;
    font-weight: 400;
    line-height: 16px
}

.chat .chat_left .chat_list .chat_list_item .lastMassage-time {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 10px
}

.chat .chat_left .chat_list .chat_list_item .senting {
    position: absolute;
    top: 5px;
    right: 10px
}

.chat .chat_left .chat_list .chat_list_item .senting i {
    font-size: 14px;
    color: #888
}

.chat .chat_left .chat_list .active {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat .chat_left .chat_list_mobile {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 175px;
    padding-top: 10px
}

.chat .chat_left .chat_list_mobile .chat_list_item {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: rgba(214, 214, 217, .339);
    cursor: pointer
}

.chat .chat_left .chat_list_mobile .chat_list_item:hover {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat .chat_left .chat_list_mobile .chat_list_item .profile_active {
    width: 20%
}

.chat .chat_left .chat_list_mobile .chat_list_item .profile_active .profile {
    position: relative
}

.chat .chat_left .chat_list_mobile .chat_list_item .profile_active .profile .massage_count {
    height: 23px;
    width: 23px;
    background-color: #e94560;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 0;
    right: -5px
}

.chat .chat_left .chat_list_mobile .chat_list_item .profile_active .active_online img {
    border: 3px solid #3ccf4e
}

.chat .chat_left .chat_list_mobile .chat_list_item .chat_overview {
    width: 75%;
    padding-left: 5px
}

.chat .chat_left .chat_list_mobile .chat_list_item .chat_overview .profile-name {
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat .chat_left .chat_list_mobile .chat_list_item .chat_overview .overview_massage {
    font-size: 13px;
    color: #7c7c7c;
    font-weight: 400;
    line-height: 16px
}

.chat .chat_left .chat_list_mobile .chat_list_item .lastMassage-time {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 10px
}

.chat .chat_left .chat_list_mobile .chat_list_item .senting {
    position: absolute;
    top: 5px;
    right: 10px
}

.chat .chat_left .chat_list_mobile .chat_list_item .senting i {
    font-size: 14px;
    color: #888
}

.chat .chat_left .chat_list_mobile .active {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat .chatter_box_container {
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 87vh;
    width: 590px
}

.chat .chatter_box_container .fixed_profilr_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    height: 60px;
    padding: 12px 15px 10px 15px;
    border-top-right-radius: 13px;
    z-index: 3
}

.chat .chatter_box_container .fixed_profilr_header .ct_left {
    display: flex;
    align-items: center
}

.chat .chatter_box_container .fixed_profilr_header .ct_left .profile {
    border-radius: 50%
}

.chat .chatter_box_container .fixed_profilr_header .ct_left .profile img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.chat .chatter_box_container .fixed_profilr_header .ct_left .name_T {
    padding-left: 8px
}

.chat .chatter_box_container .fixed_profilr_header .ct_left .name_T .name {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.chat .chatter_box_container .fixed_profilr_header .ct_left .name_T .active_status {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.chat .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed {
    display: flex;
    align-items: center
}

.chat .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .dashed_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: #f3f3f3;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px
}

.chat .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .dashed_btn .MuiSvgIcon-root {
    font-size: 22px;
    color: #585858
}

.chat .chatter_box_container .chat_body {
    padding: 12px 12px 150px 12px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    background-image: url(../image/sddd.png);
    background-repeat: repeat;
    background-position: center
}

.chat .chatter_box_container .chat_body .chat_massage_item {
    width: 100%;
    display: flex;
    margin-top: 6px
}

.chat .chatter_box_container .chat_body .chat_massage_item:hover .massage_D {
    opacity: 1
}

.chat .chatter_box_container .chat_body .chat_massage_item .profile_part {
    width: 32px
}

.chat .chatter_box_container .chat_body .chat_massage_item .profile_part .chatter_profile {
    border-radius: 50%
}

.chat .chatter_box_container .chat_body .chat_massage_item .profile_part .chatter_profile img {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border-radius: 50%
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat {
    padding-left: 7px;
    min-width: 160px;
    max-width: 70%;
    position: relative
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat:hover .massage_D {
    opacity: 1
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item {
    width: 100%;
    text-align: end;
    padding: 4px 7px 2px 4px
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content {
    text-align: end;
    width: auto;
    height: auto;
    border: 1px solid rgba(195, 195, 195, .5568627451);
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content .video_file_img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #333;
    padding: 0 6px
}

.senting_massage, .own_media_massage_item{
    border-radius: 6px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .07);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #050505;
    position: relative;
    word-wrap: break-word
}
.chat .chatter_box_container .chat_body .own_massage_item .own_chat {
    padding-left: 7px;
    min-width: 160px;
    max-width: 70%;
    position: relative; 
}

.chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #a6a1a1;
    padding: 0 2px;  
    width: 100%;
}


.chat .chatter_box_container .chat_body .chat_massage_item .massage_D {
    margin-left: 15px;
    margin-top: 10px;
    opacity: 0;
    display: flex
}

.chat .chatter_box_container .chat_body .chat_massage_item .massage_D i {
    height: 35px;
    width: 35px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #565656;
    margin: 0 4px;
    cursor: pointer
}

.chat .chatter_box_container .chat_body .chat_massage_item .massage_D i:hover {
    background-color: #fff
}

.chat .chatter_box_container .chat_body .own_massage_item {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 6px
} 
  
.chat .chatter_box_container .chat_body .own_massage_item .own_chat .senting_massage a {
    color: #fff
}

.chat .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -90px;
    z-index: 5;
    opacity: 0
}

.chat .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D ul li {
    display: inline-block;
    margin: 0 4px;
    height: 34px;
    width: 34px;
    background-color: rgba(3, 40, 141, .1568627451);
    text-align: center;
    border-radius: 50%;
    cursor: pointer
}

.chat .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D ul li i {
    line-height: 35px;
    font-size: 14px
}

.chat .chatter_box_container .chat_body .own_delet_massage_item {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 6px
}

.chat .chatter_box_container .chat_body .own_delet_massage_item .own_delet_message {
    display: inline-block;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 6px 8px;
    background-color: #f3f3f3;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400
}

.chat .chatter_box_container .chat_body .chat_delet_massage_item {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 6px
}

.chat .chatter_box_container .chat_body .chat_delet_massage_item .chat_delet_message {
    display: inline-block;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 6px 8px;
    background-color: #f3f3f3;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400
}

.chat .chatter_box_container .chat_body .own_media_massage_item {
    width: 70%;
    margin: 6px 0 0 auto;
    text-align: end;
    padding: 4px 7px 2px 4px
}

.chat .chatter_box_container .chat_body .own_media_massage_item .media_content {
    text-align: end;
    width: auto;
    height: auto;
    border: 1px solid rgba(195, 195, 195, .5019607843);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center
}

.chat .chatter_box_container .chat_body .own_media_massage_item .media_content img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat .chatter_box_container .chat_body .own_media_massage_item .media_content .video_file_img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat .chatter_box_container .chat_body .own_media_massage_item .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #333;
    padding: 0 6px
}

.chat .chatter_box_container .chatting_Input_container {
    background-color: #fff;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 0 10px 0;
    z-index: 1
}

.chat .chatter_box_container .chatting_Input_container .attachment_content { 
    background: #7d6be0;
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .photo_content {
    display: flex;
    align-items: center
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item {
    height: 65px;
    width: 66px;
    border-radius: 8px;
    border: 1px solid rgba(197, 197, 201, .568);
    overflow: hidden;
    position: relative;
    margin-right: 7px
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item .photo_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: end;
    padding: 2px;
    z-index: 2
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item .photo_overly .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content {
    display: flex;
    align-items: center
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item {
    height: 65px;
    width: 65px;
    border-radius: 8px;
    border: 1px solid rgba(197, 197, 201, .568);
    overflow: hidden;
    position: relative;
    margin-right: 7px
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .video_file_img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .vedio_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: end;
    padding: 2px;
    z-index: 2
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .vedio_overly .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .play_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.chat .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .play_icon .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer;
    font-size: 25px
}

.chat .chatter_box_container .chatting_Input_container .chating_path {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f3;
    border: 2px solid rgba(123, 106, 224, .7019607843);
    border-radius: 35px;
    margin: 0 auto
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 {
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 8px
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 i {
    cursor: pointer
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 i .MuiSvgIcon-root {
    font-size: 22px;
    color: #888
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__label i .MuiSvgIcon-root {
    font-size: 22px;
    color: #888
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__label .cursor_denied:hover {
    cursor: not-allowed
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_2 {
    width: 100%;
    height: 40px;
    max-height: 70px;
    overflow: visible !important
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_3 {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .cursor_denied_3 {
    cursor: not-allowed !important
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_3 i {
    font-size: 22px;
    color: #888;
    display: inline-block;
    cursor: pointer
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .send_icon {
    font-size: 22px !important;
    color: #3f00f2 !important;
    display: inline-block !important;
    cursor: pointer !important
}

.chat .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .send_btn {
    height: 28px;
    width: 28px;
    font-size: 17px;
    color: #fff;
    background-color: #7d6be0;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer
}

.chat_mobile {
    width: 100%;
    border-radius: 13px;
    display: flex;
    overflow: hidden;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff
}

.chat_mobile .chat_left {
    background-color: #fff;
    padding: 15px;
    height: 87vh;
    width: 100%;
    border-right: 1px solid rgba(192, 192, 192, .5803921569)
}

.chat_mobile .chat_left .chat_top_content {
    height: 170px
}

.chat_mobile .chat_left .chat_top_content .chat_left_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.chat_mobile .chat_left .chat_top_content .chat_left_top .chat_h {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    display: inline-block
}

.chat_mobile .chat_left .chat_top_content .chat_left_top .chat_h i .MuiSvgIcon-root {
    margin-right: 5px;
    cursor: pointer
}

.chat_mobile .chat_left .chat_top_content .chat_search .search_box {
    width: 100%;
    position: relative;
    height: 45px;
    margin-top: 15px
}

.chat_mobile .chat_left .chat_top_content .chat_search .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: #333;
    background-color: rgba(229, 233, 237, .7215686275);
    background-clip: padding-box;
    border-radius: 7px;
    border: 1px solid #e1e1e1;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.chat_mobile .chat_left .chat_top_content .chat_search .search_box form .form_control:focus {
    outline: 0
}

.chat_mobile .chat_left .chat_top_content .chat_search .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.chat_mobile .chat_left .chat_top_content .chat_search .search_box form i {
    font-size: 20px;
    color: #7d6be0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block
}

.chat_mobile .chat_left .chat_top_content .active_list {
    position: relative
}

.chat_mobile .chat_left .chat_top_content .active_list .swiper-button-next {
    height: 32px;
    width: 32px;
    background-color: rgba(255, 255, 255, .9411764706);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    right: 0;
    top: 55%
}

.chat_mobile .chat_left .chat_top_content .active_list .swiper-button-next::after {
    font-size: 16px;
    color: #333
}

.chat_mobile .chat_left .chat_top_content .active_list .swiper-button-prev {
    height: 32px;
    width: 32px;
    background-color: rgba(255, 255, 255, .9411764706);
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    left: 0;
    top: 55%
}

.chat_mobile .chat_left .chat_top_content .active_list .swiper-button-prev::after {
    font-size: 16px;
    color: #333
}

.chat_mobile .chat_left .chat_top_content .active_list .swiper-button-disabled {
    display: none
}

.chat_mobile .chat_left .chat_top_content .active_list .active_title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px
}

.chat_mobile .chat_left .chat_top_content .active_list .activeMamber {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    position: relative
}

.chat_mobile .chat_left .chat_top_content .active_list .activeMamber img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.chat_mobile .chat_left .chat_top_content .active_list .activeMamber .active_btn {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #3ccf4e;
    position: absolute;
    bottom: 2px;
    right: 2px
}

.chat_mobile .chat_left .chat_list {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 175px;
    padding-top: 10px
}

.chat_mobile .chat_left .chat_list .chat_list_item {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: rgba(214, 214, 217, .339);
    cursor: pointer
}

.chat_mobile .chat_left .chat_list .chat_list_item:hover {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat_mobile .chat_left .chat_list .chat_list_item .profile_active {
    width: 20%
}

.chat_mobile .chat_left .chat_list .chat_list_item .profile_active .profile {
    position: relative
}

.chat_mobile .chat_left .chat_list .chat_list_item .profile_active .profile .massage_count {
    height: 23px;
    width: 23px;
    background-color: #e94560;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 0;
    right: -5px
}

.chat_mobile .chat_left .chat_list .chat_list_item .profile_active .active_online img {
    border: 3px solid #3ccf4e
}

.chat_mobile .chat_left .chat_list .chat_list_item .chat_overview {
    width: 75%;
    padding-left: 5px
}

.chat_mobile .chat_left .chat_list .chat_list_item .chat_overview .profile-name {
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat_mobile .chat_left .chat_list .chat_list_item .chat_overview .overview_massage {
    font-size: 13px;
    color: #7c7c7c;
    font-weight: 400;
    line-height: 16px
}

.chat_mobile .chat_left .chat_list .chat_list_item .lastMassage-time {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 10px
}

.chat_mobile .chat_left .chat_list .chat_list_item .senting {
    position: absolute;
    top: 5px;
    right: 10px
}

.chat_mobile .chat_left .chat_list .chat_list_item .senting i {
    font-size: 14px;
    color: #888
}

.chat_mobile .chat_left .chat_list .active {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat_mobile .chat_left .chat_list_mobile {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 175px;
    padding-top: 10px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px 8px 10px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: rgba(214, 214, 217, .339);
    cursor: pointer
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item:hover {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .profile_active {
    width: 20%
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .profile_active .profile {
    position: relative
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .profile_active .profile .massage_count {
    height: 23px;
    width: 23px;
    background-color: #e94560;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 0;
    right: -5px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .profile_active .active_online img {
    border: 3px solid #3ccf4e
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .chat_overview {
    width: 75%;
    padding-left: 5px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .chat_overview .profile-name {
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .chat_overview .overview_massage {
    font-size: 13px;
    color: #7c7c7c;
    font-weight: 400;
    line-height: 16px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .lastMassage-time {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 10px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .senting {
    position: absolute;
    top: 5px;
    right: 10px
}

.chat_mobile .chat_left .chat_list_mobile .chat_list_item .senting i {
    font-size: 14px;
    color: #888
}

.chat_mobile .chat_left .chat_list_mobile .active {
    background-color: rgba(3, 40, 141, .1098039216)
}

.chat_mobile .chatter_box_container {
    background-color: #fff;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 82vh;
    width: 590px
}

.chat_mobile .chatter_box_container .fixed_profilr_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    height: 60px;
    border-top-right-radius: 13px;
    z-index: 3
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left {
    display: flex;
    align-items: center
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left .profile {
    border-radius: 50%
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left .profile img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left .name_T {
    padding-left: 8px
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left .name_T .name {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_left .name_T .active_status {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed {
    display: flex;
    align-items: center
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .dashed_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: #f3f3f3;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px
}

.chat_mobile .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .dashed_btn .MuiSvgIcon-root {
    font-size: 22px;
    color: #585858
}

.chat_mobile .chatter_box_container .chat_body {
    padding: 12px 12px 150px 12px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
    background-image: url(../image/sddd.png);
    background-repeat: repeat;
    background-position: center
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item {
    width: 100%;
    display: flex;
    margin-top: 6px
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item:hover .massage_D {
    opacity: 1
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .profile_part {
    width: 32px
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .profile_part .chatter_profile {
    border-radius: 50%
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .profile_part .chatter_profile img {
    height: 34px;
    width: 34px;
    object-fit: cover;
    border-radius: 50%
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat {
    padding-left: 7px;
    min-width: 160px;
    max-width: 70%;
    position: relative
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat:hover .massage_D {
    opacity: 1
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item {
    width: 100%;
    text-align: end;
    padding: 4px 7px 2px 4px
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content {
    text-align: end;
    width: auto;
    height: auto;
    border: 1px solid rgba(195, 195, 195, .5568627451);
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .media_content .video_file_img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .chatter_media_massage_item .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #333;
    padding: 0 6px
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .senting_massage {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 24px 8px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .07);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #050505;
    position: relative;
    word-wrap: break-word
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .chatter_chat .senting_massage .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #707070;
    padding: 0 2px;
    position: absolute;
    bottom: 3px;
    right: 7px
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .massage_D {
    margin-left: 15px;
    margin-top: 10px;
    opacity: 0;
    display: flex
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .massage_D i {
    height: 35px;
    width: 35px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #565656;
    margin: 0 4px;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chat_body .chat_massage_item .massage_D i:hover {
    background-color: #fff
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 6px
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat {
    padding-left: 7px;
    min-width: 160px;
    max-width: 70%;
    position: relative
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .senting_massage {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 26px 8px;
    background-image: linear-gradient(to right, #7d6be0, #8367da, #8964d4, #8e60cd, #925dc7);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    position: relative;
    text-align: start;
    word-wrap: break-word
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .senting_massage .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: rgba(238, 237, 237, .9098039216);
    padding: 0 2px;
    position: absolute;
    bottom: 3px;
    right: 7px
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .senting_massage a {
    color: #fff
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -90px;
    z-index: 5;
    opacity: 0
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D ul li {
    display: inline-block;
    margin: 0 4px;
    height: 34px;
    width: 34px;
    background-color: rgba(3, 40, 141, .1568627451);
    text-align: center;
    border-radius: 50%;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chat_body .own_massage_item .own_chat .massage_D ul li i {
    line-height: 35px;
    font-size: 14px
}

.chat_mobile .chatter_box_container .chat_body .own_delet_massage_item {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 6px
}

.chat_mobile .chatter_box_container .chat_body .own_delet_massage_item .own_delet_message {
    display: inline-block;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 6px 8px;
    background-color: #f3f3f3;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400
}

.chat_mobile .chatter_box_container .chat_body .chat_delet_massage_item {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 6px
}

.chat_mobile .chatter_box_container .chat_body .chat_delet_massage_item .chat_delet_message {
    display: inline-block;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 6px 8px;
    background-color: #f3f3f3;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400
}

.chat_mobile .chatter_box_container .chat_body .own_media_massage_item {
    width: 70%;
    margin: 6px 0 0 auto;
    text-align: end;
    padding: 4px 7px 2px 4px
}

.chat_mobile .chatter_box_container .chat_body .own_media_massage_item .media_content {
    text-align: end;
    width: auto;
    height: auto;
    border: 1px solid rgba(195, 195, 195, .5019607843);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content: center
}

.chat_mobile .chatter_box_container .chat_body .own_media_massage_item .media_content img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat_mobile .chatter_box_container .chat_body .own_media_massage_item .media_content .video_file_img {
    width: 100%;
    height: 100%;
    max-height: 330px;
    object-fit: contain
}

.chat_mobile .chatter_box_container .chat_body .own_media_massage_item .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #333;
    padding: 0 6px
}

.chat_mobile .chatter_box_container .chatting_Input_container {
    background-color: #fff;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 0 10px 0;
    z-index: 1
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content {
    padding: 0 10px 8px 20px
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .photo_content {
    display: flex;
    align-items: center
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item {
    height: 65px;
    width: 66px;
    border-radius: 8px;
    border: 1px solid rgba(197, 197, 201, .568);
    overflow: hidden;
    position: relative;
    margin-right: 7px
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item .photo_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: end;
    padding: 2px;
    z-index: 2
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .photo_content .photo_item .photo_overly .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content {
    display: flex;
    align-items: center
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item {
    height: 65px;
    width: 65px;
    border-radius: 8px;
    border: 1px solid rgba(197, 197, 201, .568);
    overflow: hidden;
    position: relative;
    margin-right: 7px
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .video_file_img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .vedio_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: end;
    padding: 2px;
    z-index: 2
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .vedio_overly .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .play_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.chat_mobile .chatter_box_container .chatting_Input_container .attachment_content .vedio_content .vedio_item .play_icon .MuiSvgIcon-root {
    color: #fff;
    cursor: pointer;
    font-size: 25px
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f3f3;
    border: 2px solid rgba(123, 106, 224, .7019607843);
    border-radius: 35px;
    margin: 0 auto
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 {
    width: 80px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 8px
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 i {
    cursor: pointer
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 i .MuiSvgIcon-root {
    font-size: 22px;
    color: #888
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__label i .MuiSvgIcon-root {
    font-size: 22px;
    color: #888
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_1 .file-input__label .cursor_denied:hover {
    cursor: not-allowed
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_2 {
    width: 100%;
    height: 40px;
    max-height: 70px;
    overflow: visible !important
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_3 {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .cursor_denied_3 {
    cursor: not-allowed !important
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_3 i {
    font-size: 22px;
    color: #888;
    display: inline-block;
    cursor: pointer
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .send_icon {
    font-size: 22px !important;
    color: #3f00f2 !important;
    display: inline-block !important;
    cursor: pointer !important
}

.chat_mobile .chatter_box_container .chatting_Input_container .chating_path .path_side_3 .send_btn {
    height: 28px;
    width: 28px;
    font-size: 17px;
    color: #fff;
    background-color: #7d6be0;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer
}

.chat_D .info_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: #f3f3f3;
    border-radius: 50%;
    cursor: pointer
}

.chat_D .info_btn .MuiSvgIcon-root {
    font-size: 20px
}

.chat_contentBody {
    margin-bottom: 0px !important
}

.chat_contentBody {
    height: 100vh
}

.chat_emojy_picker {
    position: absolute;
    bottom: 60px !important;
    left: 20px !important
}

.chat_group_body {
    width: 480px;
    min-height: 60vh;
    position: relative;
    z-index: 1300 !important
}

.chat_group_body .search_user {
    position: relative
}

.chat_group_modal {
    z-index: 2 !important
}

.list_title {
    margin-top: 15px;
    font-size: 20px;
    color: #333;
    font-weight: 600
}

.Aded_list {
    margin-top: 12px
}

.Aded_list .list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 8px;
    background-color: #f3f3f3;
    border-radius: 6px;
    margin-top: 12px;
    cursor: pointer
}

.Aded_list .list_item:hover {
    background-color: #e7e6e6
}

.Aded_list .list_item .item_left {
    display: flex;
    align-items: center
}

.Aded_list .list_item .item_left .u_name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-left: 5px
}

.Aded_list .list_item .item_right i {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%
}

.Aded_list .list_item .item_right i:hover {
    background-color: #f3f3f3
}

.Aded_list .list_item .item_right .MuiSvgIcon-root {
    color: #888
}

.room_null {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px
}

.room_null span {
    font-size: 15px;
    font-weight: 500;
    color: #888;
    text-transform: uppercase
}

.communityInfo_wrapper {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff
}

.communityInfo_wrapper .commuinity_banner {
    height: 300px;
    width: 100%;
    background: #7d6be0;
}
.leftsideCover{
    background: #7d6be0;
}
.communityInfo_wrapper .commuinity_banner img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.communityInfo_wrapper .community_top_info {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 8px 12px 12px 12px
}

.communityInfo_wrapper .community_top_info .com_one {
    display: flex
}

.communityInfo_wrapper .community_top_info .com_logo {
    margin-top: -40px
}

.communityInfo_wrapper .community_top_info .com_logo img {
    height: 105px;
    width: 105px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, .1) 0px 1px 3px 0px, rgba(0, 0, 0, .06) 0px 1px 2px 0px
}

.communityInfo_wrapper .community_top_info .com_top_left {
    padding-left: 10px
}

.communityInfo_wrapper .community_top_info .com_top_left .com_name {
    font-size: 22px;
    font-weight: 700;
    color: #1b1b1b;
    line-height: 30px
}

.communityInfo_wrapper .community_top_info .com_top_left .tagline {
    font-size: 18px;
    font-weight: 400;
    color: #3a3939
}

.communityInfo_wrapper .community_top_info .com_botttom {
    display: flex
}

.communityInfo_wrapper .community_top_info .edit_button {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    background-color: #209d56;
    border-radius: 4px;
    padding: 4px 20px;
    border-radius: 35px;
    height: 45px;
    display: flex;
    align-items: center
}

.communityInfo_wrapper .community_top_info .edit_button .MuiSvgIcon-root {
    font-size: 15px;
    margin-right: 3px
}

.communityInfo_wrapper .community_top_info .back_ic {
    font-size: 15px;
    font-weight: 400;
    color: #5a5a5a;
    background-color: #fff;
    border: 1px solid rgba(195, 195, 195, .6862745098);
    border-radius: 4px;
    padding: 4px 20px;
    border-radius: 35px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 10px
}

.communityInfo_wrapper .community_top_info .back_ic .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 3px
}

.communityInfo_wrapper .community_top_info .back_ic img {
    max-height: 24px;
    width: auto;
    object-fit: contain
}

.communityInfo_wrapper .community_top_info .community_view_btn {
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    background-color: #7d6be0;
    color: #fff;
    cursor: pointer;
    padding: 6px 25px;
    height: 42px
}

.communityInfo_wrapper .community_top_info .community_view_btn:hover {
    border: 1px solid #d5d5d5;
    background-color: #fff;
    color: #4a4a4a
}

.communityInfo_wrapper .com_info_body {
    padding: 12px
}

.communityInfo_wrapper .com_info_body .com_details {
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #343333;
    line-height: 24px;
    border: 1px solid rgba(195, 195, 195, .5058823529);
    border-radius: 10px
}

.com_info_wrap {
    padding: 20px 12px 20px 24px
}

.com_info_wrap .info_item {
    font-size: 13px;
    font-weight: 400;
    color: #6c6969
}

.com_info_wrap .info_item span {
    font-size: 14px;
    color: #6c6969;
    padding-left: 5px
}

.com_info_wrap .info_item_2 {
    display: flex;
    padding-top: 3px
}

.com_info_wrap .info_item_2 .item_lebel {
    font-size: 12px;
    font-weight: 400;
    color: #6c6969;
    width: 20%
}

.com_info_wrap .info_item_2 .info_item_bold {
    font-size: 13px;
    color: #6c6969;
    padding-left: 5px;
    width: 80%
}

.com_info_wrap .info_item_title {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    display: inline-block;
    border-bottom: 1px dashed rgba(195, 195, 195, .8274509804);
    margin-top: 10px;
    margin-bottom: 5px
}

.communityInfo_wrapper_small {
    width: 70%;
    margin: 0 auto
}

.qr_img {
    display: flex;
    justify-content: end;
    padding-top: 12px
}

.qr_img img {
    height: 130px;
    width: 130px;
    object-fit: contain
}

.report_detail {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 20px
}

.report_detail .report_text {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 5px
}

.report_detail .report_text .title {
    font-size: 26px;
    font-weight: 600;
    color: #333;
    line-height: 34px;
    padding-bottom: 10px
}

.report_detail .report_text p {
    font-size: 14px;
    font-weight: 400;
    color: #4e4e4e
}

.report_detail .report_reply_content {
    padding-top: 15px
}

.report_detail .report_reply_content .reply_content .main_reply {
    display: flex;
    margin-top: 20px
}

.report_detail .report_reply_content .reply_content .main_reply .reply_left .user_avtar img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%
}

.report_detail .report_reply_content .reply_content .main_reply .reply_right {
    padding-left: 6px
}

.report_detail .report_reply_content .reply_content .main_reply .reply_right .user_N {
    font-size: 16px;
    font-weight: 600;
    color: #333
}

.report_detail .report_reply_content .reply_content .main_reply .reply_right .reply_text {
    font-size: 13px;
    font-weight: 400;
    color: #6d6d6d;
    line-height: 18px
}

.report_detail .report_reply_content .reply_content .main_reply .reply_right .reply_btn {
    font-size: 13px;
    color: #353434;
    font-weight: 500;
    cursor: pointer;
    padding-top: 6px
}

.report_detail .report_reply_content .reply_content ul {
    padding-left: 25px
}

.report_detail .report_reply_content .reply_content ul li {
    margin-top: 12px
}

.report_detail .report_reply_content .reply_content ul li .sub_reply {
    display: flex
}

.report_detail .report_reply_content .reply_content ul li .sub_reply .reply_left .user_avtar img {
    height: 28px;
    width: 28px;
    object-fit: cover;
    border-radius: 50%
}

.report_detail .reply_right {
    padding-left: 6px
}

.report_detail .reply_right .user_N {
    font-size: 16px;
    font-weight: 600;
    color: #333
}

.report_detail .reply_right .reply_text {
    font-size: 13px;
    font-weight: 400;
    color: #6d6d6d;
    line-height: 18px
}

.report_detail .reply_right .reply_text .attached_img_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.report_detail .reply_right .reply_text .attached_img_container .img_attache {
    height: 120px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    margin-right: 10px
}

.report_detail .reply_right .reply_text .attached_img_container .img_attache img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.report_detail .reply_right .reply_btn {
    font-size: 13px;
    color: #353434;
    font-weight: 500;
    cursor: pointer;
    padding-top: 6px
}

.report_detail .reply_component {
    margin-top: 12px
}

.report_detail .reply_component .selected_img {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden
}

.report_detail .reply_component .selected_img img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.report_detail .reply_component .selected_img .img_overly {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.report_detail .reply_component .selected_img .img_overly i {
    cursor: pointer
}

.report_detail .reply_component .selected_img .img_overly i .MuiSvgIcon-root {
    color: #fff;
    font-size: 24px
}

.report_detail .reply_component .reply_sec {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 15px
}

.report_detail .reply_component .reply_sec .user_avtar img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%
}

.report_detail .reply_component .reply_sec .comment_input_area {
    max-height: 220px;
    width: 100%;
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: start
}

.report_detail .reply_component .reply_sec .comment_input_area .comment_textarea {
    padding: 5px 10px 10px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    border-bottom: 1px solid rgba(82, 82, 82, .8392156863);
    width: 100%;
    font-size: 14px;
    line-height: 20px
}

.report_detail .reply_component .reply_sec .comment_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.report_detail .reply_component .reply_sec .comment_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.report_detail .reply_component .reply_sec .submit_btn {
    height: 40px;
    padding: 0 20px;
    border-radius: 6px;
    background-color: #7d6be0;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.report_detail .reply_component .reply_sec .submit_btn:hover {
    background-color: #5746b9
}

.image_s {
    display: flex
}

.image_s .selected_img {
    margin-right: 10px
}

.d-flex {
    display: flex !important
}

.mr-12 {
    margin-right: 12px
}

.report_table {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll
}

.magazine {
    background-color: #fff;
    border-radius: 10px;
    min-height: 400px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px;
    position: relative
}

.magazine .magzine_slider_content {
    height: 300px;
    width: 100%;
    margin-top: 30px;
    border-radius: 12px;
    overflow: hidden;
    position: relative
}

.magazine .magzine_slider_content::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .1)
}

.magazine .magzine_slider_content img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.magazine .magzine_slider_content .mag_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center
}

.magazine .magzine_slider_content .mag_content .content_info {
    text-align: center;
    width: 100%
}

.magazine .magzine_slider_content .mag_content .content_info h2 {
    font-size: 23px;
    font-weight: 500;
    color: #fff;
    background-color: rgba(0, 0, 0, .2);
    padding: 8px 80px;
    line-height: 35px;
    margin-top: -15px
}

.magazine .main_contant {
    padding-top: 40px
}

.magazine .sec_head {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px
}

.magazine .main_content_item {
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .5098039216);
    background-color: rgba(243, 243, 243, .831372549);
    cursor: pointer;
    border-radius: 15px;
    height: 240px
}

.magazine .main_content_item .item_img {
    height: 130px;
    width: 100%
}

.magazine .main_content_item .item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px
}

.magazine .main_content_item .content_title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin: 10px 0 5px 0;
    text-align: center
}

.magazine .main_content_item p {
    text-align: start;
    color: #888;
    line-height: 19px;
    font-size: 14px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center
}

.magazine .sticky_section {
    padding-top: 30px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main {
    display: flex
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_img img {
    height: 180px;
    width: 180px;
    object-fit: cover;
    border-radius: 10px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text {
    padding-left: 10px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text h4 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    line-height: 32px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text p {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    text-align: start
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text .read_btn {
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text .read_btn:hover {
    color: #7d6be0
}

.magazine .sticky_section .sticky_wrapper .sticky_list {
    padding-top: 7px
}

.magazine .sticky_section .sticky_wrapper .sticky_list ul li {
    padding-top: 2px;
    cursor: pointer
}

.magazine .sticky_section .sticky_wrapper .sticky_list ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    cursor: pointer;
    display: flex
}

.magazine .sticky_section .sticky_wrapper .sticky_list ul li a:hover {
    color: #7d6be0
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .5098039216);
    background-color: rgba(243, 243, 243, .831372549);
    border-radius: 12px;
    margin-bottom: 10px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub .sub_img img {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 8px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub .sub_text {
    padding-left: 10px
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub .sub_text h4 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub .sub_text .read_btn {
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer
}

.magazine .sticky_section .sticky_wrapper .sticky_wrapper_sub .sub_text .read_btn:hover {
    color: #7d6be0
}

.magazine .exclusive_wrapper {
    padding: 12px;
    border: 1px solid rgba(195, 195, 195, .662745098);
    border-radius: 12px;
    margin-top: 35px
}

.magazine .exclusive_wrapper .exclusive_head {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: #7d6be0;
    padding: 10px 12px;
    border-radius: 6px;
    margin-bottom: 15px
}

.magazine .exclusive_wrapper ul li {
    cursor: pointer;
    background-color: rgba(157, 149, 161, .137);
    border-radius: 4px;
    padding: 6px 15px;
    margin-top: 7px
}

.magazine .exclusive_wrapper ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    cursor: pointer
}

.magazine .exclusive_wrapper ul li a:hover {
    color: #7d6be0
}

.magazine .front_section {
    padding-top: 35px
}

.magazine .front_section .front_main {
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .5098039216);
    background-color: rgba(243, 243, 243, .831372549);
    border-radius: 12px;
    height: 235px;
    cursor: pointer
}

.magazine .front_section .front_main .front_main_img img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px
}

.magazine .front_section .front_main .front_main_text {
    padding-top: 10px
}

.magazine .front_section .front_main .front_main_text h4 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center
}

.magazine .front_section .front_main .front_main_text p {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: start
}

.magazine .front_section .front_sub {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .5098039216);
    border-radius: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgba(243, 243, 243, .831372549)
}

.magazine .front_section .front_sub:hover {
    background-color: rgba(243, 243, 243, .831372549)
}

.magazine .front_section .front_sub .front_sub_img img {
    height: 90px;
    width: 90px;
    object-fit: cover;
    border-radius: 8px
}

.magazine .front_section .front_sub .front_sub_text {
    padding-left: 10px
}

.magazine .front_section .front_sub .front_sub_text h4 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.magazine .front_section .front_list {
    padding-top: 7px
}

.magazine .front_section .front_list ul li {
    padding-top: 2px;
    cursor: pointer
}

.magazine .front_section .front_list ul li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #888;
    cursor: pointer;
    display: flex
}

.magazine .front_section .front_list ul li a:hover {
    color: #7d6be0
}

.magazine_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 0 12px
}

.magazine_top::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: -1
}

.magazine_top .magzin_logo {
    width: auto;
    display: flex;
    align-items: center
}

.magazine_top .magzin_logo img {
    max-height: 45px;
    width: auto;
    object-fit: contain
}

.magazine_top .magazine_left {
    display: flex;
    align-items: center;
    width: 100%
}

.magazine_top .magazine_t {
    width: 100%;
    text-align: center
}

.magazine_top .magazine_t span {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    line-height: 32px
}

.magazine_top .top_left {
    display: flex;
    align-items: center;
    justify-content: flex-end
}

.magazine_top .top_left i {
    color: #fff !important
}

.magazine_nav {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.magazine_nav .nav_list li {
    padding-right: 25px;
    cursor: pointer;
    position: relative;
    display: inline-block
}

.magazine_nav .nav_list li:hover a {
    width: 100%
}

.magazine_nav .nav_list li:hover .sub_down {
    top: 38px;
    opacity: 1;
    margin-top: 0;
    visibility: visible;
    padding: 10px 10px 10px 10px
}

.magazine_nav .nav_list li a {
    font-size: 15px;
    color: #333;
    font-weight: 400
}

.magazine_nav .nav_list li a:hover {
    color: #7d6be0
}

.magazine_nav .nav_list li a span {
    padding-right: 10px;
    font-size: 18px
}

.magazine_nav .nav_list li a .droppper {
    font-size: 16px
}

.magazine_nav .nav_list li .sub_down {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    box-shadow: 1px 1.732px 20px 0px rgba(0, 0, 0, .3);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: absolute;
    visibility: hidden;
    display: block;
    width: 190px;
    border: none !important;
    opacity: 0;
    top: 40px;
    right: 0;
    padding: 0;
    z-index: 10
}

.magazine_nav .nav_list li .sub_down li {
    padding: 2px 8px;
    width: 100%
}

.magazine_nav .nav_list li .sub_down li:hover {
    background-color: #f3f3f3
}

.magazine_nav .nav_list li .sub_down li:hover a {
    color: #7d6be0
}

.magazine_nav .nav_list li .sub_down li a {
    font-size: 14px;
    color: #272626;
    font-weight: 400
}

.magazine_nav .mag_search {
    position: relative
}

.magazine_nav .mag_search .form_control {
    height: 40px;
    width: 220px;
    background-color: #f3f3f3;
    border-radius: 35px;
    padding: 0 35px 0 15px;
    border: 1px solid rgba(195, 195, 195, .5921568627)
}

.magazine_nav .mag_search .form_control:focus {
    outline: 0
}

.magazine_nav .mag_search i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer
}

.magazine_details {
    padding: 15px;
    border-radius: 12px;
    background-color: #fff
}

.magazine_details .magazine_details_wrapper .magzine_img {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.magazine_details .magazine_details_wrapper .magzine_img img {
    max-height: 440px;
    width: 100%;
    object-fit: contain
}

.magazine_details .magazine_details_wrapper .content_title {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    line-height: 38px;
    padding-top: 20px
}

.magazine_details .magazine_details_wrapper .content_text {
    padding-top: 10px
}

.magazine_details .magazine_details_wrapper .content_text p {
    font-size: 16px;
    color: #888;
    line-height: 23px;
    padding-top: 10px
}

.magazine_details .magzine_subItem_wrapper .magzine_subItem {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .6705882353);
    border-radius: 12px;
    margin-bottom: 10px;
    cursor: pointer
}

.magazine_details .magzine_subItem_wrapper .magzine_subItem:hover {
    background-color: rgba(243, 243, 243, .831372549)
}

.magazine_details .magzine_subItem_wrapper .magzine_subItem .magzine_subItem_img img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 8px
}

.magazine_details .magzine_subItem_wrapper .magzine_subItem .magzine_subItem_text {
    padding-left: 10px
}

.magazine_details .magzine_subItem_wrapper .magzine_subItem .magzine_subItem_text h4 {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.magazine_category_wrapper {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px
}

.mag_category_Item {
    display: flex;
    padding: 10px;
    border: 1px solid rgba(195, 195, 195, .6705882353);
    border-radius: 12px;
    margin-bottom: 10px;
    background-color: rgba(243, 243, 243, .831372549);
    cursor: pointer
}

.mag_category_Item:hover {
    background-color: rgba(243, 243, 243, .831372549)
}

.mag_category_Item .mag_category_Item_img img {
    height: 140px;
    width: 140px;
    object-fit: cover;
    border-radius: 8px
}

.mag_category_Item .mag_category_Item_text {
    padding-left: 10px
}

.mag_category_Item .mag_category_Item_text h4 {
    font-size: 22px;
    font-weight: 500;
    color: #333;
    line-height: 31px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.mag_category_Item .mag_category_Item_text p {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    line-height: 22px
}

.m_menu {
    display: none
}

.m_menu i {
    color: #fff !important
}

.matrimonial_card {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 10px
}

.matrimonial_card .card_top {
    text-align: center
}

.matrimonial_card .card_top .card_profil {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px
}

.matrimonial_card .card_top .card_profil img {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 50%
}

.matrimonial_card .card_top .person_name {
    font-size: 17px;
    color: #333;
    font-weight: 600;
    text-transform: uppercase
}

.matrimonial_card .card_top .DOB {
    font-size: 13px;
    font-weight: 400;
    color: #5c5c5c
}

.matrimonial_card .card_content {
    padding: 10px 15px 5px 15px
}

.matrimonial_card .card_content .content_box {
    width: 100%;
    border: 1px solid rgba(195, 195, 195, .7764705882);
    border-radius: 8px;
    padding: 5px;
    position: relative
}

.matrimonial_card .card_content .content_box::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: .7px;
    background-color: rgba(195, 195, 195, .6745098039)
}

.matrimonial_card .card_content .content_box .box_content_item {
    border-bottom: 1px solid rgba(195, 195, 195, .7764705882);
    display: flex;
    justify-content: center;
    padding: 5px 3px
}

.matrimonial_card .card_content .content_box .box_content_item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.matrimonial_card .card_content .content_box .box_content_item .box_item {
    width: 50%;
    text-align: center;
    font-size: 13px;
    color: #434141;
    font-weight: 400
}

.matrimonial_card .card_content .content_box .box_content_item .bold_text {
    font-weight: 500;
    font-size: 14px;
    color: #333
}

.matrimonial_card .card_content .view_details {
    height: 35px;
    width: 100%;
    background-color: rgba(233, 233, 233, .8156862745);
    color: #585757;
    border: 1px solid rgba(195, 195, 195, .7764705882);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-top: 12px;
    font-size: 14px;
    cursor: pointer
}

.matrimonial_card .card_content .view_details:hover {
    background-color: #7d6be0;
    color: #fff
}

.matrimonial_card_details {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 20px 50px
}

.matrimonial_card_details h4 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    text-align: center;
    padding-bottom: 20px
}

.matrimonial_card_details .person_information {
    width: 100%;
    border: 1px solid rgba(195, 195, 195, .7176470588);
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 20px 20px 0 20px;
    margin-bottom: 25px
}

.matrimonial_card_details .person_information::after {
    position: absolute;
    content: "";
    height: 200px;
    width: 100%;
    border-top: 4px solid #7d6be0;
    top: 0;
    left: 0
}

.matrimonial_card_details .person_information .person_image {
    height: 220px;
    width: 200px;
    margin: 0 0 0 auto;
    display: flex;
    background-color: #f3f3f3;
    border: 1px solid rgba(195, 195, 195, .7843137255);
    border-radius: 8px;
    justify-content: center
}

.matrimonial_card_details .person_information .person_image img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.matrimonial_card_details .information_table {
    width: 100%;
    padding: 12px 12px 0 12px
}

.matrimonial_card_details .information_table .section_title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(195, 195, 195, .6509803922);
    margin-bottom: 12px
}

.matrimonial_card_details .information_table .info_item_list {
    display: flex;
    padding-bottom: 15px
}

.matrimonial_card_details .information_table .info_item_list:last-child {
    padding-bottom: 5px
}

.matrimonial_card_details .information_table .info_item_list .info_lebel {
    width: 30%;
    font-size: 15px;
    font-weight: 400;
    color: #5c5c5c
}

.matrimonial_card_details .information_table .info_item_list .info_item {
    width: 70%;
    font-size: 17px;
    font-weight: 500;
    color: #333
}

.matrimonial_card_details .information_table .info_item_list .info_item p {
    font-size: 15px;
    font-weight: 400;
    color: #333;
    line-height: 22px;
    text-align: start
}

.matrimonial_card_details .information_table .gallery_wrap {
    margin-bottom: 12px
}

.matrimonial_card_details .information_table .gallery_wrap .ant-image img {
    height: 160px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.matromonial_search_form {
    width: 500px;
    margin: 0 auto 45px
}

.matromonial_search_form .searchBar {
    position: relative;
    width: 100%
}

.matromonial_search_form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5
}

.matromonial_search_form .searchBar .form_control:focus {
    outline: 0
}

.matromonial_search_form .searchBar .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.matromonial_search_form .searchBar .form_control:optional {
    color: #636262
}

.matromonial_search_form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.img_gallary_wrapper {
    margin: 20px 0
}

.img_gallary_wrapper .image_Item {
    height: 120px;
    width: 100%;
    position: relative;
    border: 1px solid rgba(195, 195, 195, .6392156863);
    border-radius: 8px;
    overflow: hidden
}

.img_gallary_wrapper .image_Item img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.img_gallary_wrapper .image_Item::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2)
}

.img_gallary_wrapper .image_Item i {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    height: 30px;
    width: 30px;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.img_gallary_wrapper .image_Item i .MuiSvgIcon-root {
    color: #fff;
    font-size: 17px
}

.image_lists img {
    height: 200px;
    width: 100%;
    padding: 10px
}

.metromonial_theme {
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(195, 195, 195, .1450980392);
    border-radius: 13px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .129);
    position: relative;
    padding: 20px 20px;
    z-index: 1;
    overflow: hidden
}

.metromonial_theme .shape_one {
    display: none
}

.metromonial_theme .shape_two {
    display: none
}

.metromonial_theme .shape_three {
    display: none
}

.metromonial_theme .theme_heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px
}

.metromonial_theme .theme_heading h4 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    text-align: center;
    border-bottom: 3px solid rgba(30, 29, 29, .6156862745);
    padding-bottom: 8px
}

.metromonial_theme .part_one {
    margin-top: 20px;
    border-radius: 10px
}

.metromonial_theme .part_one .feature_img {
    background-color: #f3f3f3;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    height: 220px;
    width: 220px;
    margin: 0 auto
}

.metromonial_theme .part_one .feature_img img {
    height: 220px;
    width: 220px;
    object-fit: cover;
    border-radius: 50%
}

.metromonial_theme .part_one .user_personal_data {
    padding: 16px 12px 12px 12px;
    border: 1px solid rgba(195, 195, 195, .6509803922);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .7921568627)
}

.metromonial_theme .part_one .user_personal_data .data_item {
    font-size: 15px;
    color: #514d4d
}

.metromonial_theme .part_one .user_personal_data .data_item span {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px
}

.metromonial_theme .others_data {
    padding: 12px;
    border: 1px solid rgba(195, 195, 195, .5254901961);
    background-color: rgba(255, 255, 255, .7921568627);
    border-radius: 10px;
    margin-top: 15px;
    position: relative;
    overflow: hidden
}

.metromonial_theme .others_data:after {
    content: "";
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.metromonial_theme .others_data .data_section {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid rgba(195, 195, 195, .5254901961);
    padding: 8px 0 12px 0;
    text-align: center;
    margin-bottom: 10px
}

.metromonial_theme .others_data .met_gallery_wrap {
    height: 150px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px
}

.metromonial_theme .others_data .met_gallery_wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.metromonial_theme .others_data .m_data_item {
    padding: 10px 10px
}

.metromonial_theme .others_data .m_data_item .data_title {
    font-size: 15px;
    color: #504f4f;
    font-weight: 500
}

.metromonial_theme .others_data .m_data_item .data_text {
    font-size: 14px;
    color: #2f2e2e;
    line-height: 23px
}

.bridal-1 {
    background-image: url(../image/bridal-1Bg.webp)
}

.bridal-1 .part_one .feature_img {
    background-color: #f3f3f3;
    height: 220px;
    width: 220px;
    margin: 0 auto 16px
}

.bridal-1 .part_one .feature_img img {
    border: 5px solid #e49ea8
}

.bridal-1 .part_one .user_personal_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.bridal-1 .part_one .user_personal_data .data_item {
    color: #514d4d
}

.bridal-1 .part_one .user_personal_data .data_item span {
    color: #895e62
}

.bridal-1 .others_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.bridal-1 .others_data:after {
    border-top: 2px solid #d0a8ab
}

.bridal-1 .others_data .data_section {
    color: #9e6e72
}

.bridal-1 .others_data .m_data_item:nth-child(odd) {
    background-color: rgba(208, 168, 171, .287)
}

.bridal-1 .others_data .m_data_item .data_title {
    color: #504f4f
}

.bridal-1 .others_data .m_data_item .data_text {
    color: #514d4d
}

.groom-1 {
    background-image: url(../image/grool-1Bg.webp)
}

.groom-1 .part_one .feature_img {
    background-color: #f3f3f3;
    margin: 0 auto 16px
}

.groom-1 .part_one .feature_img img {
    border: 5px solid rgba(68, 143, 153, .9450980392)
}

.groom-1 .part_one .user_personal_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.groom-1 .part_one .user_personal_data .data_item {
    color: #514d4d
}

.groom-1 .part_one .user_personal_data .data_item span {
    color: #2a6972
}

.groom-1 .others_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.groom-1 .others_data:after {
    border-top: 2px solid rgba(47, 112, 122, .9450980392)
}

.groom-1 .others_data .data_section {
    color: rgba(49, 116, 126, .9450980392)
}

.groom-1 .others_data .m_data_item:nth-child(odd) {
    background-color: rgba(53, 121, 131, .1803921569)
}

.groom-1 .others_data .m_data_item .data_title {
    color: #504f4f
}

.groom-1 .others_data .m_data_item .data_text {
    color: #514d4d
}

.bridal-2 {
    background-image: url(../image/bridal-2Bg.webp)
}

.bridal-2 .part_one {
    display: flex
}

.bridal-2 .part_one .feature_img {
    border-radius: 10px;
    overflow: hidden;
    height: auto;
    width: 30%;
    margin: 0 auto;
    padding: 0 10px
}

.bridal-2 .part_one .feature_img img {
    max-height: 350px;
    width: auto;
    object-fit: contain;
    border-radius: 10px
}

.bridal-2 .part_one .user_personal_data {
    background-color: rgba(255, 255, 255, .8352941176);
    width: 70%;
    margin-left: 15px
}

.bridal-2 .part_one .user_personal_data .data_item {
    color: #514d4d
}

.bridal-2 .part_one .user_personal_data .data_item span {
    color: #895e62
}

.bridal-2 .others_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.bridal-2 .others_data:after {
    border-top: 2px solid #d0a8ab
}

.bridal-2 .others_data .data_section {
    color: #9e6e72
}

.bridal-2 .others_data .m_data_item:nth-child(odd) {
    background-color: rgba(208, 168, 171, .287)
}

.bridal-2 .others_data .m_data_item .data_title {
    color: #504f4f
}

.bridal-2 .others_data .m_data_item .data_text {
    color: #514d4d
}

.bridal-3 {
    background-image: url(../image/bridal-3bg.webp)
}

.bridal-3 .part_one {
    display: flex
}

.bridal-3 .part_one .feature_img {
    border-radius: 10px;
    overflow: hidden;
    height: auto;
    width: 30%;
    margin: 0 auto;
    padding: 0 10px
}

.bridal-3 .part_one .feature_img img {
    max-height: 350px;
    width: auto;
    object-fit: contain;
    border-radius: 10px
}

.bridal-3 .part_one .user_personal_data {
    background-color: rgba(255, 255, 255, .8352941176);
    width: 70%;
    margin-left: 15px
}

.bridal-3 .part_one .user_personal_data .data_item {
    color: #514d4d
}

.bridal-3 .part_one .user_personal_data .data_item span {
    color: #895e62
}

.bridal-3 .others_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.bridal-3 .others_data:after {
    border-top: 2px solid #d0a8ab
}

.bridal-3 .others_data .data_section {
    color: #9e6e72
}

.bridal-3 .others_data .m_data_item:nth-child(odd) {
    background-color: rgba(208, 168, 171, .287)
}

.bridal-3 .others_data .m_data_item .data_title {
    color: #504f4f
}

.bridal-3 .others_data .m_data_item .data_text {
    color: #514d4d
}

.groom-2 .shape_one {
    position: absolute;
    top: -80px;
    left: -80px;
    z-index: -1;
    display: block
}

.groom-2 .shape_one img {
    max-height: 500px;
    max-width: 500px;
    object-fit: contain
}

.groom-2 .shape_two {
    position: absolute;
    top: 240px;
    right: -80px;
    z-index: -1;
    display: block
}

.groom-2 .shape_two img {
    max-height: 500px;
    max-width: 500px;
    object-fit: contain
}

.groom-2 .shape_three {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    display: block
}

.groom-2 .shape_three img {
    max-height: 600px;
    max-width: 600px;
    object-fit: contain
}

.groom-2 .part_one {
    display: flex
}

.groom-2 .part_one .feature_img {
    border-radius: 10px;
    overflow: hidden;
    height: auto;
    width: 30%;
    margin: 0 auto;
    padding: 0 10px
}

.groom-2 .part_one .feature_img img {
    max-height: 350px;
    width: auto;
    object-fit: contain;
    border-radius: 10px
}

.groom-2 .part_one .user_personal_data {
    background-color: rgba(255, 255, 255, .7019607843);
    width: 70%;
    margin-left: 15px
}

.groom-2 .part_one .user_personal_data .data_item {
    color: #514d4d
}

.groom-2 .part_one .user_personal_data .data_item span {
    color: rgba(68, 143, 153, .9450980392)
}

.groom-2 .others_data {
    background-color: rgba(255, 255, 255, .8352941176)
}

.groom-2 .others_data:after {
    border-top: 2px solid rgba(51, 119, 128, .9450980392)
}

.groom-2 .others_data .data_section {
    color: rgba(57, 132, 142, .9450980392)
}

.groom-2 .others_data .m_data_item:nth-child(odd) {
    background-color: rgba(53, 121, 131, .1803921569)
}

.groom-2 .others_data .m_data_item .data_title {
    color: #504f4f
}

.groom-2 .others_data .m_data_item .data_text {
    color: #514d4d
}

.theme_select_wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px 20px
}

.theme_select_wrapper .page_head {
    text-align: center
}

.theme_select_wrapper .page_head h4 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    padding: 12px 0 12px 0;
    text-align: center;
    display: inline-block;
    border-bottom: 2px solid #333
}

.theme_select_wrapper .theme_selet_wrap {
    margin-top: 40px
}

.theme_select_wrapper .theme_selet_wrap .theme_item {
    height: 350px;
    width: 100%;
    background-color: #f3f3f3;
    border: 1px solid rgba(195, 195, 195, .3568627451);
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    transition: all linear 5s;
    z-index: 1;
    padding-bottom: 40px;
    cursor: pointer
}

.theme_select_wrapper .theme_selet_wrap .theme_item:hover .theem_img {
    background-position: bottom;
    transition: all linear 5s
}

.theme_select_wrapper .theme_selet_wrap .theme_item:hover::after {
    background-color: rgba(0, 0, 0, .1)
}

.theme_select_wrapper .theme_selet_wrap .theme_item::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .2)
}

.theme_select_wrapper .theme_selet_wrap .theme_item .theem_img {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover
}

.theme_select_wrapper .theme_selet_wrap .theme_item .theme_content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    z-index: 1
}

.theme_select_wrapper .theme_selet_wrap .theme_item .theme_content .themName {
    font-size: 16px;
    font-weight: 500;
    color: #313131;
    text-align: center
}

.purchase_body {
    padding-top: 80px
}

.purchase_body .purchase_card {
    border-radius: 12px;
    background-color: #f9f9f9;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    height: auto;
    min-height: 290px;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding-top: 70px;
    padding-left: 40px;
    padding-right: 40px
}

.purchase_body .purchase_card:hover {
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1)
}

.paybill_form {
    padding: 40px 30px;
    background-color: #fff;
    width: 550px;
    margin: 40px auto;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.paybill_form .card_content {
    height: 60px;
    border: 1px solid rgba(150, 150, 150, .7137254902);
    padding: 20px 10px;
    border-radius: 4px
}

.paybill_form .pay_button {
    height: 52px;
    width: 100%;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 20px
}

.paybill_form .pay_button:hover {
    background-color: #ff4500
}

.payment_wrapper {
    padding: 20px 0 110px 0
}

.payment_wrapper .payment_option_card {
    height: 420px;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    border: 1px dashed #d5d5d5;
    line-height: 60px;
    text-align: center;
    padding: 30px 25px
}

.payment_wrapper .payment_option_card h3 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding-bottom: 45px;
    display: inline-block
}

.payment_wrapper .payment_option_card h6 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    text-align: center;
    line-height: 32px
}

.payment_wrapper .payment_option_card h6 span {
    font-weight: 400;
    font-size: 18px
}

.payment_wrapper .payment_option_card h5 {
    font-size: 18px;
    font-weight: 400;
    color: #888;
    text-align: center;
    line-height: 28px
}

.payment_wrapper .donate_option_card {
    height: 280px;
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    border: 1px dashed #d5d5d5;
    line-height: 60px;
    text-align: center;
    padding: 30px
}

.payment_wrapper .donate_option_card h3 {
    font-size: 26px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding-bottom: 45px
}

.broadcast-Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px
}

.broadcast-Top .broadTop-logo img {
    max-height: 60px;
    max-width: auto
}

.broadcast-Top .broadTop-logo p {
    font-size: 10px;
    font-weight: 400
}

.broadcast-Top .top-action {
    display: flex;
    align-items: center
}

.broadcast-Top .top-action .top-action-btn {
    height: 42px;
    width: 42px;
    background-color: rgba(124, 106, 224, .9254901961);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 8px;
    cursor: pointer
}

.broadcast-Top .top-action .top-action-btn:hover {
    background-color: #5e4dbe
}

.broadcast-category {
    margin-top: 30px
}

.broadcast-category h4 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px
}

.broadcast-category .swiper-button-disabled {
    opacity: 0
}

.broadcast-category .swiper-button-next {
    height: 38px;
    width: 38px;
    background-color: rgba(139, 139, 139, .498);
    margin-top: -16px;
    margin-right: -10px
}

.broadcast-category .swiper-button-prev {
    height: 38px;
    width: 38px;
    background-color: rgba(139, 139, 139, .498);
    margin-top: -16px;
    margin-left: -10px
}

.broadcast-category .swiper-button-prev::after {
    font-size: 16px;
    color: #fff
}

.broadcast-category .swiper-button-next::after {
    font-size: 16px;
    color: #fff
}

.broadcast_search_form {
    width: 320px;
    position: relative
}

.broadcast_search_form .searchBar {
    position: relative;
    width: 100%;
    z-index: 3
}

.broadcast_search_form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 12px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    border: 1px solid #d5d5d5
}

.broadcast_search_form .searchBar .form_control:focus {
    outline: 0
}

.broadcast_search_form .searchBar .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.broadcast_search_form .searchBar .form_control:optional {
    color: #636262
}

.broadcast_search_form .searchBar i {
    font-size: 17px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer
}

.broadcast_search_form .search-plate {
    max-height: 350px;
    overflow-y: scroll;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1);
    position: absolute;
    left: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 5px
}

.broadcast_search_form .search-plate ul li {
    padding: 5px 10px;
    font-size: 14px;
    color: #4b4949;
    cursor: pointer;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.broadcast_search_form .search-plate ul li:hover {
    color: #333;
    background-color: #f3f3f3
}

.broadcast-category-item {
    cursor: pointer
}

.broadcast-category-item .item-img img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 12px
}

.broadcast-category-item .item-title {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-top: 5px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    background: #d3d3d3;
    padding: 5px 5px;
    border-radius: 10px;
    font-weight: 800;
    letter-spacing: .2px;
    text-align: center
}

.broadcast-item-section {
    margin-top: 20px
}

.broadcast-item-section h4 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px
}

.broadcast-item {
    margin-bottom: 10px;
    cursor: pointer
}

.broadcast-item .broadcast-img {
    position: relative
}

.broadcast-item .broadcast-img i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.broadcast-item .broadcast-img i img {
    object-fit: contain;
    max-width: 35px;
    max-height: 35px
}

.broadcast-item .item-content {
    padding: 5px
}

.broadcast-item .item-content h4 {
    font-size: 16px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.broadcast-item .item-content .broadcast-item-bottom {
    display: flex;
    justify-content: space-between
}

.broadcast-item .item-content .broadcast-item-bottom .bottom-left-content .userName {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #333;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-top: 4px
}

.broadcast-item .item-content .broadcast-item-bottom .bottom-left-content .overView span {
    font-size: 12px;
    color: #888;
    margin-right: 10px
}

.broadcast-item .item-content .broadcast-item-bottom .bottom-left-content .left-content {
    padding-left: 10px
}

.broadcast-video .video-react {
    height: 380px !important;
    padding-top: 0px !important;
    border-radius: 12px;
    overflow: hidden
}

.broadcast-video .video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 380px !important
}

.broadcast-video-container .broadcast-video-content {
    margin-top: 10px
}

.broadcast-video-container .broadcast-video-content h4 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    line-height: 28px
}

.broadcast-video-container .broadcast-video-content .broadcast-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px
}

.broadcast-video-container .broadcast-video-content .broadcast-user-info .broadcast-user-info-left {
    display: flex;
    align-items: center
}

.broadcast-video-container .broadcast-video-content .broadcast-user-info .broadcast-user-info-left .userName {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin-left: 6px
}

.broadcast-video-container .broadcast-video-content .broadcast-video-details .video-use-info {
    display: flex
}

.broadcast-video-container .broadcast-video-content .broadcast-video-details .video-use-info span {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-right: 12px
}

.broadcast-video-container .broadcast-video-content .broadcast-video-details .video-details-text {
    margin-top: 5px;
    margin-bottom: 10px
}

.broadcast-video-container .broadcast-video-content .broadcast-video-details .video-details-text p {
    font-size: 13px;
    color: #333;
    line-height: 21px;
    text-align: start
}

.broadcast-comment-wrapper .total-comment {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.broadcast-comment-wrapper .broadcast_comment_input_container {
    width: 100%;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    margin-top: 20px
}

.broadcast-comment-wrapper .broadcast_comment_input_container .comment_input_area {
    max-height: 220px;
    overflow-y: scroll
}

.broadcast-comment-wrapper .broadcast_comment_input_container .comment_input_area .comment_textarea {
    padding: 10px 10px 5px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    width: 100%;
    font-size: 14px
}

.broadcast-comment-wrapper .broadcast_comment_input_container .comment_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.broadcast-comment-wrapper .broadcast_comment_input_container .comment_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.broadcast-comment-wrapper .broadcast_comment_input_container .comment_attachment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 8px 2px 8px;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
}

.broadcast-comment-content .broadcast-comment_container {
    padding-right: 18px;
    max-height: 330px;
    overflow-x: hidden;
    overflow-y: scroll
}

.broadcast-comment-content .broadcast-comment_container ul {
    margin-left: 20px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    width: 20px;
    height: 40px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li:after {
    position: absolute;
    content: "";
    top: 29px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
    width: 20px;
    height: 100%
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li:last-child:after {
    display: none
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper {
    width: 100%;
    height: auto;
    border-radius: 8px;
    padding: 10px 10px 6px 10px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_wrap {
    display: flex
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_wrap .comment_wrap_left {
    padding-left: 10px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_wrap .comment_wrap_left .left_info {
    display: flex;
    align-items: center
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_wrap .comment_wrap_left .left_info .userName {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 16px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_wrap .comment_wrap_left .left_info .createDate {
    font-size: 11px;
    font-weight: 400;
    color: #888;
    line-height: 16px;
    margin-left: 6px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_box {
    font-size: 14px;
    color: #605d5d;
    font-weight: 400;
    line-height: 21px;
    padding-top: 5px;
    white-space: pre-line
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_footer {
    margin-top: 10px
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_footer .footer_react_item {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-right: 15px;
    cursor: pointer
}

.broadcast-comment-content .broadcast-comment_container .comment_ul li .comment_wrapper .comment_footer .footer_react_item:hover {
    color: #000
}

.broadcast-sub-item {
    height: 85px;
    width: 100%;
    display: flex;
    overflow: hidden;
    margin-bottom: 10px
}

.broadcast-sub-item:hover {
    background-color: rgba(191, 191, 191, .3843137255);
    border-radius: 10px
}

.broadcast-sub-item .item-img {
    height: 100%;
    width: 45%;
    border-radius: 10px;
    overflow: hidden;
    position: relative
}

.broadcast-sub-item .item-img i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.broadcast-sub-item .item-img i img {
    height: 30px;
    width: 30px;
    object-fit: contain
}

.broadcast-sub-item .item-img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.broadcast-sub-item .item-content {
    width: 55%;
    height: 100%;
    padding: 5px 0 5px 5px
}

.broadcast-sub-item .item-content h4 {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.broadcast-sub-item .item-content .userName {
    font-size: 13px;
    font-weight: 400;
    color: #333;
    line-height: 12px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.broadcast-sub-item .item-content .overView span {
    font-size: 11px;
    color: #888;
    margin-right: 6px
}

.lookBack-list {
    margin-top: 10px
}

.lookBack-item {
    height: 155px;
    width: 100%;
    margin-bottom: 44px;
    display: flex
}

.lookBack-item .lookBack-item-img {
    height: 100%;
    width: 30%
}

.lookBack-item .lookBack-item-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px
}

.lookBack-item .lookBack-item-content {
    padding-left: 10px;
    width: 70%
}

.lookBack-item .lookBack-item-content h4 {
    font-size: 16px;
    font-weight: 500;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.lookBack-item .lookBack-item-content .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px
}

.lookBack-item .lookBack-item-content .user-info .user-details {
    display: flex
}

.lookBack-item .lookBack-item-content .user-info .user-details .user-name {
    font-size: 13px;
    padding-left: 6px
}

.lookBack-item .lookBack-item-content p {
    font-size: 13px;
    color: #3f3d3d;
    line-height: 20px;
    font-weight: 400
}

.lookBack-item .lookBack-item-content .overView span {
    font-size: 11px;
    color: #888;
    margin-right: 6px
}

.library-content {
    margin-top: 25px
}

.library-content h4 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 16px
}

.dragger-item {
    background-color: #fff;
    border-radius: 10px
}

.uploaded-media {
    position: relative;
    width: 100px
}

.uploaded-media .uploaded-media-image {
    width: 100%;
    height: auto
}

.uploaded-media .uploaded-media-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px
}

.uploaded-media .uploaded-media-ion {
    position: absolute;
    top: 2px;
    right: 5px;
    cursor: pointer
}

.broadcast-single-category {
    display: flex
}

.broadcast-single-category .item-img {
    max-width: 130px
}

.broadcast-single-category .item-img img {
    border-radius: 10px
}

.broadcast-single-category .item-details {
    margin-left: 10px
}

.active {
    background-color: rgba(191, 191, 191, .3843137255);
    border-radius: 10px
}

.media-form {
    background-color: #fff;
    border-radius: 10px;
    min-height: 220px;
    border: 2px dotted;
    position: relative
}

.media-form .camera-icon {
    margin: 10px;
    background-color: #e9e9e9;
    padding: 30px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer
}

.media-form .camera-icon:hover {
    background-color: #d6d4d4;
    border: 2px dotted
}

.media-form .video-icon {
    margin: 10px;
    background-color: #e9e9e9;
    padding: 30px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer
}

.media-form .video-icon:hover {
    background-color: #d6d4d4;
    border: 2px dotted
}

.media-form .camcoder-icon {
    margin: 10px;
    background-color: #e9e9e9;
    padding: 43px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    cursor: pointer;
    position: relative
}

.media-form .camcoder-icon .inputFile {
    position: absolute;
    visibility: hidden
}

.media-form .camcoder-icon .videoIcon {
    position: absolute;
    left: 32px;
    top: 30px
}

.media-form .camcoder-icon:hover {
    background-color: #d6d4d4;
    border: 2px dotted
}

.deviceRecorder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px
}

.deviceRecorder .deviceRecorderBody .recorder-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.deviceRecorder .deviceRecorderBody .recorder-btn p {
    position: absolute;
    bottom: 25px;
    color: #e9e9e9
}

.deviceRecorder .deviceRecorderBody .recorder-btn h1 {
    position: absolute;
    bottom: 250px;
    color: #750606;
    font-size: 200px
}

.deviceRecorder .deviceRecorderBody .recorder-btn button {
    position: absolute;
    bottom: 50px;
    font-size: 18px;
    font-weight: bold;
    background-color: #e9e9e9;
    padding: 30px 23px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0);
    cursor: pointer
}

.swal2-confirm, .swal2-cancel {
    color: #fff !important
}

.readmoreless {
    border: none
}

.web-broadcast-none {
    display: none
}

.broadcast-body .MuiTabPanel-root {
    padding: 10px !important
}

.broadcast-body .MuiTabs-flexContainer {
    overflow-y: scroll
}

.broadcastItem_footer_status {
    margin-top: 2px;
    padding: 5px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%
}

.broadcastItem_footer_status .footer_status_one {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    cursor: pointer
}

.broadcastItem_footer_status .footer_status_one .MuiSvgIcon-root {
    font-size: 19px
}

.broadcastItem_footer_status .footer_status_one:hover {
    background-color: rgba(3, 40, 141, .062745098);
    transition: all linear .5s;
    border-radius: 4px
}

.broadcastItem_footer_status .footer_status_one span {
    padding-left: 5px;
    font-size: 14px
}

.broadcastItem_footer_status .footer_status_one .emoji_icon {
    padding-left: 0px;
    font-size: 28px
}

.divDisabled {
    pointer-events: none !important;
    cursor: not-allowed
}

.fade-in {
    opacity: 1;
    transition: opacity 1s ease-in-out
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out
}

.poll_options_main {
    max-width: 30%;
    display: flex
}

.poll_options_main .green {
    display: flex;
    margin-top: 5px;
    margin-bottom: 18px;
    cursor: pointer
}

.poll_options_main .green .right {
    padding: 7px;
    width: 140px;
    max-height: 40px;
    border-radius: 5px;
    border-left: 7px solid #0aa936;
    border-top: 2px solid #0aa936;
    border-right: 2px solid #0aa936;
    border-bottom: 2px solid #0aa936
}

.poll_options_main .red {
    display: flex;
    margin-top: 5px;
    margin-bottom: 18px;
    cursor: pointer
}

.poll_options_main .red .right {
    padding: 7px;
    width: 140px;
    max-height: 40px;
    border-radius: 5px;
    border-left: 7px solid #dc0000;
    border-top: 2px solid #dc0000;
    border-right: 2px solid #dc0000;
    border-bottom: 2px solid #dc0000
}

.poll_options_main .grey {
    display: flex;
    margin-top: 5px;
    margin-bottom: 18px;
    cursor: pointer
}

.poll_options_main .grey .right {
    padding: 7px;
    width: 140px;
    max-height: 40px;
    border-radius: 5px;
    border-left: 7px solid #989898;
    border-top: 2px solid #989898;
    border-right: 2px solid #989898;
    border-bottom: 2px solid #989898
}

.broadcast-poll-item {
    height: 990px;
    border-left: 2px solid #c2c2c2;
    overflow-y: scroll
}

.broadcast-poll-item .polls_right .poll-card {
    margin: 10px;
    cursor: pointer;
    padding: 8px;
    border-radius: 10px
}

.broadcast-poll-item .polls_right .poll-card:hover {
    background-color: #d0d0d0
}

.broadcast-poll-item .polls_right .poll-card .active {
    background-color: #d0d0d0
}

.broadcast-poll-item .polls_right .poll-card .details {
    margin-top: 5px;
    margin-bottom: 5px
}

.broadcast-poll-item .polls_right .poll-card .avater-profile-title {
    display: flex;
    align-items: center
}

.broadcast-poll-item .polls_right .poll-card .avater-profile-title p {
    margin-left: 10px
}

.broadcast-poll-item .polls_right .poll-card .ratio-poll {
    display: flex
}

.poll_store {
    padding: 10px;
    background: #efefef;
    border-radius: 10px
}

.poll_store li {
    margin: 7px 0px
}

.poll_list {
    position: relative;
    height: 45px;
    width: 100%;
    z-index: 999
}

.poll_list .poll_list_ratio {
    position: absolute;
    height: 45px;
    background-color: #f0f8ff;
    color: #f0f8ff;
    border-radius: 10px
}

.poll_list .poll_list_contents {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9999
}

.MuiBtn_create {
    text-transform: capitalize !important;
    padding: 8px !important;
    color: #fff !important;
    background: #024fc1 !important;
    margin: 10px !important;
    border-radius: 5px !important
}

.MuiBtn_create_disabled {
    text-transform: capitalize !important;
    padding: 8px !important;
    color: #fff !important;
    background: #d6d6d6 !important;
    margin: 10px !important;
    border-radius: 5px !important;
    cursor: not-allowed !important
}

.notAllowed {
    cursor: not-allowed !important
}

.antLabel {
    margin-left: 10px
}

.css-1ytufz-MuiAvatarGroup-root .MuiAvatar-root {
    width: 30px !important;
    height: 30px !important
}

.readmoreless {
    font-size: smaller
}

.smaller {
    font-size: 12px !important
}

.poll_card_image {
    width: 150px;
    height: auto;
    margin-left: 20px
}

.poll_card_image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: contain
}

.pollCards {
    position: relative;
    min-height: 380px
}

.pollCards .sticky_bottom {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0
}

.pollQuestionShow {
    border: 1px solid #a5a5a5;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer
}

.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 25px !important;
    padding-right: 0 !important;
    padding-bottom: 8px !important;
    padding-left: 12px !important
}

.poll_leader_board {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: 20px
}

.poll_leader_board .firstPrize {
    background-color: #f75435;
    min-height: 180px;
    width: 96px;
    border-radius: 10px 10px 0px 0px;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: #fff;
    position: relative
}

.poll_leader_board .firstPrize .prizeTitle {
    margin-top: 10px
}

.poll_leader_board .firstPrize .prizePosition {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 30px
}

.poll_leader_board .secondPrize {
    background-color: #fdae38;
    min-height: 146px;
    width: 96px;
    border-radius: 10px 10px 0px 0px;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: #fff;
    position: relative
}

.poll_leader_board .secondPrize .prizeTitle {
    margin-top: 10px
}

.poll_leader_board .secondPrize .prizePosition {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 25px
}

.poll_leader_board .thirdPrize {
    background-color: #4fa3a5;
    min-height: 112px;
    width: 96px;
    border-radius: 10px 10px 0px 0px;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    color: #fff;
    position: relative
}

.poll_leader_board .thirdPrize .prizeTitle {
    margin-top: 10px
}

.poll_leader_board .thirdPrize .prizePosition {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 20px
}

.rewardBtn {
    margin-left: 16px;
    margin-top: 16px
}

.rewardBtn .btn {
    min-width: 278px
}

.poll_reward {
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    z-index: -1
}

.poll_reward .reward-header {
    display: flex;
    flex-direction: column;
    align-items: center
}

.poll_reward .reward-header .img {
    width: 262px;
    height: auto
}

.poll_reward .reward-header .img img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.poll_reward .reward-header .text {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 12px
}

.poll_reward .reward-card {
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
    background: #e7eaf4
}

.poll_reward .reward-card .reward-card-single {
    padding: 5px 0px 10px 0px
}

.poll_reward .reward-card .reward-card-single .reward-card-elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .card-image {
    width: 192px;
    height: 88px;
    padding: 2px;
    border-radius: 10px
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .compnay-logo {
    width: 62px;
    height: 30px;
    margin: 20px 0px 10px 0px
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .compnay-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .price {
    font-size: 16px;
    font-weight: 500
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .qr-code {
    width: 72px;
    height: 72px;
    margin: 10px 0px 10px 0px
}

.poll_reward .reward-card .reward-card-single .reward-card-elements .qr-code img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.wallet-card {
    max-width: 450px;
    height: 280px;
    background-color: #fff;
    background-color: #2a304c;
    border-radius: 20px;
    margin: 40px auto;
    box-shadow: rgba(17, 12, 46, .15) 0px 48px 100px 0px;
    position: relative;
    overflow: hidden
}

.wallet-card .wallet-card-top {
    padding: 20px 20px 60px 20px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    z-index: 2
}

.wallet-card .wallet-card-top h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    color: #eeba69;
    letter-spacing: 1.4px;
    width: 100%
}

.wallet-card .wallet-card-top .main-balance {
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    text-align: center
}

.wallet-card .wallet-card-top .main-balance span {
    font-size: 18px;
    color: #efeded;
    letter-spacing: 1.2px
}

.wallet-card .wallet-card-top .wallet-number {
    color: #e6e6e6;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    text-align: end
}

.wallet-card .card-shape-1 {
    height: 120px;
    width: 120px;
    background-color: #1c223a;
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%) rotate(-45deg)
}

.wallet-card .card-shape-2 {
    height: 80px;
    width: 80px;
    background-color: #1c223a;
    position: absolute;
    right: 100px;
    top: 0;
    transform: rotate(45deg)
}

.wallet-card .card-shape-3 {
    height: 50px;
    width: 50px;
    background-color: #1c223a;
    position: absolute;
    right: 60px;
    top: 25%;
    transform: rotate(45deg)
}

.wallet-card .wallet-card-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    background-color: #161c33;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.wallet-card .wallet-card-bottom h5 {
    color: #f2f0f0;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase
}

.wallet-history {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
    padding: 20px 20px 10px 20px;
    max-height: 600px;
    overflow-y: scroll
}

.wallet-history .history-top {
    border-bottom: 1px solid rgba(195, 195, 195, .5764705882);
    margin-bottom: 10px
}

.wallet-history .history-top h5 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.wallet-history .transation-container .transation-item {
    display: flex;
    justify-content: space-between;
    padding: 13px 12px 6px 12px;
    border-radius: 5px
}

.wallet-history .transation-container .transation-item:nth-child(even) {
    background-color: #dfe6eb
}

.wallet-history .transation-container .transation-item .item-left-content {
    width: 50%
}

.wallet-history .transation-container .transation-item .item-left-content h5 {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 15px
}

.wallet-history .transation-container .transation-item .item-left-content h6 {
    font-size: 12px;
    font-weight: 300;
    color: #3c3c3c;
    line-height: 12px
}

.wallet-history .transation-container .transation-item .item-right-content {
    width: 50%;
    text-align: end
}

.wallet-history .transation-container .transation-item .item-right-content h5 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    line-height: 15px
}

.wallet-history .transation-container .transation-item .item-right-content h5 .MuiSvgIcon-root {
    font-size: 16px
}

.wallet-history .transation-container .transation-item .item-right-content h6 {
    font-size: 12px;
    font-weight: 300;
    color: #3c3c3c;
    line-height: 12px
}

.t-out {
    color: #d72f2f !important
}

.t-in {
    color: #389838 !important
}

.wallet-redeem-card {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
    padding: 20px
}

.wallet-redeem-card h4 {
    font-size: 22px;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    color: #333
}

.redeem-btn {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    padding: 8px 30px;
    border-radius: 35px;
    background-color: #2a304c;
    margin-top: 20px;
    text-align: center;
    cursor: pointer
}

.redeem-btn:hover {
    background-color: #030303
}

.redeem-dialouge-content {
    min-width: 500px;
    height: auto;
    padding: 10px
}

.redeem-dialouge-content h4 {
    font-size: 16px;
    margin-top: 20px
}

.redeem-card {
    height: 100px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px dashed rgba(195, 195, 195, .5725490196);
    cursor: pointer
}

.redeem-card .card-logo {
    width: 35%;
    height: 100%
}

.redeem-card .card-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.redeem-card .card-content {
    width: 75%;
    padding-left: 6px
}

.redeem-card .card-content h5 {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.redeem-card .card-content p {
    font-size: 13px;
    font-weight: 400;
    color: #6a6969;
    line-height: 16px;
    text-align: inherit;
    display: block;
    display: -webkit-box;
    height: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.active-redeem-card {
    border: 1px solid #1976d2
}

.post-business-container {
    background-color: #fff;
    border-radius: 16px;
    min-height: 400px;
    width: 100%; 
    box-shadow: rgba(0, 0, 0, .1) 0px 1px 2px 0px;
    position: relative
}

.busines-plan-content {
    margin-top: 30px
}

.busines-plan-content h2 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.busines-plan-content .plan-card {
    height: auto;
    width: 100%;
    border-radius: 12px;
    padding: 30px 20px 20px 20px;
    color: #fff;
    background-image: linear-gradient(to right, #d88ffd, #cd87fc, #c180fa, #b479f9, #a672f8)
}

.busines-plan-content .plan-card .plan-card-top {
    display: flex;
    justify-content: space-between
}

.busines-plan-content .plan-card .plan-card-top .crad-left img {
    max-width: 35px;
    max-height: 35px;
    object-fit: contain
}

.busines-plan-content .plan-card .plan-card-top .crad-left span {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    padding-left: 10px;
    letter-spacing: 1.3px
}

.busines-plan-content .plan-card .plan-card-top .card-right {
    text-align: end
}

.busines-plan-content .plan-card .plan-card-top .card-right h3 {
    color: #fff;
    font-weight: 400;
    font-size: 32px
}

.busines-plan-content .plan-card .plan-card-top .card-right p {
    font-size: 14px;
    font-weight: 300
}

.busines-plan-content .plan-card ul {
    margin-left: 12px;
    margin-top: 20px
}

.busines-plan-content .plan-card ul li {
    list-style: square;
    font-weight: 300;
    font-size: 14px;
    color: #fff;
    margin-top: 5px
}

.busines-plan-content .plan-card .plan-select-btn {
    width: 100%;
    padding: 8px 0;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 15px;
    cursor: pointer
}

.busines-plan-content .plan-card .plan-select-btn:hover {
    background-color: #333;
    color: #fff
}

.busines-plan-content .plan-card-1 {
    background-image: linear-gradient(to right, #d88ffd, #cd87fc, #c180fa, #b479f9, #a672f8)
}

.busines-plan-content .plan-card-2 {
    background-image: linear-gradient(to right, #fec199, #ffb295, #ffa496, #fe969a, #f889a1)
}

.busines-plan-content .plan-card-3 {
    background-image: linear-gradient(to right, #a8ddda, #8ddcd5, #70dbcd, #4edac2, #1cd8b5)
}

.choose-commuinity {
    margin-top: 30px
}

.choose-commuinity h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.choose-commuinity .choose-commuinity-content {
    max-width: 500px;
    margin: 10px auto 0
}

.choose-commuinity .choose-commuinity-content p {
    text-align: center;
    color: #585858;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px
}

.preview-commuinity-item {
    padding: 6px 0;
    display: flex;
    align-items: center
}

.preview-commuinity-item img {
    max-height: 35px;
    width: 40px;
    object-fit: contain
}

.preview-commuinity-item span {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px
}

.business-setup {
    margin-top: 30px; 
}
.underlined-text {
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray; /* Light gray underline */
    width: 100%; /* Full width for the underline */
    padding-bottom: 4px; /* Adds padding below the text */
  }
  .map_height{
    height: 168px;
  }
.business-setup h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.business-banner-upload .ant-upload {
    height: 280px !important;
    width: 100% !important
}

.business-banner-upload .ant-upload-list-item-container {
    height: 280px !important;
    width: 100% !important
}

.business-payment {
    margin-top: 30px;
    padding: 0 20px
}

.business-payment h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.business-payment-wrap {
    max-width: 600px;
    margin: 40px auto 0
}

.business-payment-wrap .pay-card {
    max-width: 400px;
    margin: 0 auto
}

.business-payment-wrap .pay-card img {
    max-height: 250px;
    width: 100%;
    object-fit: contain
}

.business-payment-wrap .pay-other-line {
    border-top: 1px solid rgba(195, 195, 195, .6117647059);
    text-align: center;
    margin-top: 25px
}

.business-payment-wrap .pay-other-line span {
    font-size: 15px;
    color: #4a4a4a;
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    top: -13px
}

.business-payment-wrap .pay-other-line .other-method {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center
}

.business-payment-wrap .pay-other-line .other-method .method-item {
    height: 35px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6ecef;
    border: 1px solid rgba(195, 195, 195, .4039215686);
    border-radius: 5px;
    margin: 0 8px;
    padding: 5px 10px;
    cursor: pointer
}

.business-payment-wrap .pay-other-line .other-method .method-item:hover {
    background-color: #d5e1e9
}

.business-payment-wrap .pay-other-line .other-method .method-item img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.business-payment-wrap .pay-content {
    border: 1px solid rgba(195, 195, 195, .5098039216);
    padding: 30px;
    max-width: 500px;
    margin: 60px auto 0;
    background-color: #f3f3f3
}

.business-payment-wrap .pay-content .total-amount {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.business-payment-wrap .pay-content .total-amount h5 {
    font-size: 22px;
    font-weight: 500;
    color: #333
}

.business-payment-wrap .pay-content .total-amount h6 {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.payment-msg {
    margin-top: 30px;
    padding: 0 20px
}

.payment-msg h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.payment-msg .msg-content {
    margin-top: 100px;
    text-align: center
}

.payment-msg .msg-content img {
    max-height: 120px;
    object-fit: contain
}

.payment-msg .msg-content h3 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin: 12px 0
}

.business-type {
    margin-top: 30px;
    padding: 0 20px
}

.business-type h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.4px
}

.business-type .business-type-content {
    max-width: 500px;
    margin: 20px auto 0
}

.business-type .business-type-content p {
    text-align: center;
    color: #585858;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px
}

.business-type .business-type-content .type-content {
    margin: 50px 0 60px 0
}

.business-type .business-type-content .type-content form {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.business-type .business-type-content .type-content .card-input-element {
    display: none
}

.business-type .business-type-content .type-content .card-input {
    font-size: 15px;
    color: #444;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 3px 20px;
    border-radius: 35px;
    border: 1px solid rgba(175, 174, 174, .7411764706)
}

.business-type .business-type-content .type-content .card-input:hover {
    cursor: pointer
}

.business-type .business-type-content .type-content .card-input-element:checked+.card-input {
    box-shadow: 0 0 1px 1px #1976d2;
    background-color: #1976d2;
    border: #1976d2;
    color: #fff
}

.business-theme {
    height: 180px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 12px;
    position: relative;
    margin-bottom: 8px;
    overflow: hidden
}

.business-theme img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.business-theme .overly-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 12px
}

.business-theme .overly-content .top-content {
    width: 100%;
    display: flex;
    justify-content: space-between
}

.business-theme .overly-content .top-content span {
    padding: 1px 13px;
    background-color: #fff;
    border-radius: 35px;
    color: #333;
    font-size: 12px;
    font-weight: 400
}

.business-theme .overly-content .middle-content {
    margin-bottom: 40px
}

.them-subscription-container {
    width: 420px;
    min-height: 500px
}

.them-subscription-container .theme-img {
    width: 100%;
    display: flex;
    justify-content: center
}

.them-subscription-container .theme-img img {
    max-height: 140px;
    width: 100%;
    object-fit: contain
}

.them-subscription-container .theme-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px
}

.them-subscription-container .theme-info h4 {
    font-size: 18px;
    font-weight: 500
}

.them-subscription-container .type-content {
    margin: 10px 0 10px 0
}

.them-subscription-container .type-content h5 {
    color: #1976d2
}

.them-subscription-container .type-content .card-input-element {
    display: none
}

.them-subscription-container .type-content .card-input {
    color: #444;
    margin-top: 8px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(175, 174, 174, .7411764706);
    width: 100%
}

.them-subscription-container .type-content .card-input h3 {
    font-size: 17px;
    font-weight: 600;
    color: #535252;
    line-height: 20px
}

.them-subscription-container .type-content .card-input p {
    font-size: 13px;
    color: #5d5d5d;
    font-weight: 400;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%
}

.them-subscription-container .type-content .card-input:hover {
    cursor: pointer
}

.them-subscription-container .type-content .card-input-element:checked+.card-input {
    box-shadow: 0 0 1px 1px #1976d2;
    border: 1px solid #1976d2
}

.skip-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #5e5d5d;
    cursor: pointer
}

.skip-btn:hover {
    color: #1976d2
}

.business-card-view {
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    padding: 15px;
    margin-top: 20px
}

.business-card-view .logo-img {
    width: 120px;
    height: 120px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.business-card-view .logo-img img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.business-card-view .card-content {
    padding-left: 8px
}

.business-card-view .card-content h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.business-card-view .card-content h6 {
    font-size: 13px;
    font-weight: 400;
    color: #444;
    line-height: 18px
}

.business-set-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    background-color: #f3f3f3;
    padding: 12px 14px
}

.business-set-item .MuiSvgIcon-root {
    font-size: 16px;
    color: #4e4d4e
}

.business-set-item .MuiSvgIcon-root:hover {
    color: #1976d2
}

.business-set-item .item-text {
    color: #636363
}

.business-set-item .item-text i {
    margin-right: 6px
}

.business-set-item .item-text i .MuiSvgIcon-root {
    font-size: 18px;
    color: #818081
}

.business-image-set-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    width: 100%;
    background-color: #f3f3f3;
    position: relative
}

.business-image-set-item img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.business-image-set-item .overly-content {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: flex-end
}

.business-image-set-item .overly-content .MuiSvgIcon-root {
    font-size: 20px;
    color: #000
}

.business-image-set-item .overly-content .MuiSvgIcon-root:hover {
    color: #1976d2
}

.job_searchBar {
    padding: 15px 15px;
    background-color: #4a9367;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px
}

.job_searchBar h4 {
    font-size: 24px;
    color: #fff;
    font-weight: 500
}

.job_searchBar p {
    font-size: 15px;
    color: #e9e9e9;
    font-weight: 400
}

.job_searchBar .job_plate {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.job_searchBar .job_plate li {
    position: relative
}

.job_searchBar .job_plate li i {
    font-size: 15px;
    color: #333;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)
}

.job_searchBar .job_plate .job_srach_item-1 {
    width: 80%;
    margin-right: 12px
}

.job_searchBar .job_plate .job_srach_item-1 .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: rgba(255, 255, 255, .4901960784);
    background-clip: padding-box;
    border-radius: 4px;
    border: 1px solid rgba(222, 222, 223, 0);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.job_searchBar .job_plate .job_srach_item-1 .form_control:focus {
    outline: 0
}

.job_searchBar .job_plate .job_srach_item-1 .form_control::placeholder {
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.job_searchBar .job_plate .job_srach_item-1 .form_control:optional {
    color: #636262;
    font-size: 15px;
    padding: 10px
}

.job_searchBar .job_plate .job_btn {
    width: 20%;
    height: 49px;
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    cursor: pointer
}

.job_searchBar .job_plate .job_btn span {
    line-height: 50px;
    color: #333;
    font-weight: 500
}

.homeJob_cart {
    height: 340px;
    width: 100%;
    background-color: #ecebf6;
    cursor: pointer;
    border-radius: 12px;
    padding: 30px 15px 15px 15px;
    position: relative;
    overflow: hidden;
    border: 1px solid #d5d5d5;
    margin-top: 20px
}

.homeJob_cart:hover {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.homeJob_cart::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #7d6be0
}

.homeJob_cart .cart_title {
    font-size: 15px;
    color: #7d6be0;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.homeJob_cart .cart_heading {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 26px
}

.homeJob_cart .candidate {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    padding-top: 15px
}

.homeJob_cart .candidate_activity {
    height: 95px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #d5d5d5;
    margin: 8px 0;
    display: flex;
    align-items: center
}

.homeJob_cart .candidate_activity .activity_item {
    width: 50%;
    text-align: center
}

.homeJob_cart .candidate_activity .activity_item .activity_title {
    font-size: 14px;
    color: #888;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 7px
}

.homeJob_cart .candidate_activity .activity_item .activity_Count {
    font-size: 30px;
    font-weight: 700;
    color: #333
}

.homeJob_cart .candidate_activity .activity_item .new_activity {
    color: #7d6be0 !important
}

.homeJob_cart .job_status {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    padding-top: 20px
}

.homeJob_cart .job_status span {
    color: #333;
    font-weight: 500;
    padding-left: 12px
}

.homeJob_cart .cart_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d5d5d5;
    padding: 12px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
}

.homeJob_cart .cart_footer .cartFooter_item {
    font-size: 15px;
    color: #888;
    font-weight: 500;
    cursor: pointer
}

.homeJob_cart .cart_footer .cartFooter_item:hover {
    color: #7d6be0
}

.homeJob_cart .cart_footer .cartFooter_item i {
    padding-right: 5px
}

.job_item_info {
    margin: 5px 0;
    background-color: #fff;
    border-radius: 8px;
    height: auto;
    width: 100%;
    padding: 13px 15px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 10px;
    position: relative
}

.job_item_info .job_item_info_top {
    display: flex
}

.job_item_info .job_item_info_top .company_logo {
    height: 65px;
    width: 90px;
    cursor: pointer
}

.job_item_info .job_item_info_top .company_logo img {
    max-width: 100%;
    max-height: 100%
}

.job_item_info .job_item_info_top .job_item_infoTop_right {
    margin-left: 10px;
    width: 100%;
    padding-right: 40px
}

.job_item_info .job_item_info_top .job_item_infoTop_right h4 {
    font-size: 17px;
    color: #333;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul {
    display: flex
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul li {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul li .MuiSvgIcon-root {
    font-size: 15px;
    color: #5c5c5c;
    margin-right: 3px
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .workType {
    padding: 1px 15px;
    background-color: rgba(74, 147, 103, .1607843137);
    color: #4a9367;
    font-size: 11px;
    border-radius: 35px;
    margin-left: 10px;
    font-weight: 500
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .workType .MuiSvgIcon-root {
    color: #4a9367
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .postedTime {
    font-size: 13px;
    color: #5c5c5c;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: 400
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .postedTime .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 3px
}

.job_item_info .job_item_info_middle {
    cursor: pointer;
    padding-top: 8px
}

.job_item_info .job_item_info_middle p {
    font-size: 13px;
    color: #5c5c5c;
    line-height: 20px
}

.job_item_info .job_fotter {
    display: flex;
    justify-content: space-between;
    align-items: end;
    cursor: pointer
}

.job_item_info .job_fotter ul li {
    font-size: 13px;
    color: #5c5c5c;
    font-weight: 400
}

.job_item_info .job_fotter ul li i {
    font-size: 15px;
    margin-right: 3px
}

.job_item_info .job_fotter .dead-line {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    font-style: normal !important
}

.job_item_info .job_info_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer
}

.JobItemDetails {
    background-color: #fff;
    width: 100%;
    border-radius: 13px;
    padding: 20px 13px 13px 13px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.JobItemDetails .history_item {
    cursor: pointer
}

.JobItemDetails .history_item .job_info {
    display: flex;
    justify-content: space-between
}

.JobItemDetails .history_item .job_info .info_1 {
    width: 68%
}

.JobItemDetails .history_item .job_info .info_1 h4 {
    font-size: 22px;
    color: #333;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase
}

.JobItemDetails .history_item .job_info .info_1 h5 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    line-height: 18px
}

.JobItemDetails .history_item .job_info .info_1 .job_text h6 {
    font-size: 17px;
    color: #333;
    font-weight: 600
}

.JobItemDetails .history_item .job_info .info_2 {
    width: 30%
}

.JobItemDetails .history_item .job_info .info_2 .job_summary {
    width: 100%;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    overflow: hidden
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_title {
    height: 42px;
    width: 100%;
    background-color: rgba(123, 106, 224, .7019607843);
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-weight: 500;
    font-size: 17px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .job_logo {
    height: auto;
    width: 95px;
    margin: 10px auto
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .job_logo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info {
    padding: 12px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info ul li {
    font-size: 15px;
    color: #5c5c5c;
    font-weight: 500;
    margin-bottom: 6px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info ul li span {
    font-weight: 400;
    color: #5c5c5c
}

.JobItemDetails .history_item .job_text {
    padding: 10px 0
}

.JobItemDetails .history_item .job_text h6 {
    font-size: 17px;
    color: #333;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center
}

.JobItemDetails .history_item .job_text h6 .MuiSvgIcon-root {
    font-size: 10px;
    margin-right: 8px
}

.JobItemDetails .history_item .job_text p {
    font-size: 15px;
    color: #5c5c5c;
    line-height: 24px;
    font-weight: 400;
    padding-left: 20px;
    text-align: start
}

.JobItemDetails .history_item .apply_btn {
    text-align: center;
    border-bottom: 1px solid #d5d5d5;
    padding: 30px 0;
    margin: 0 100px
}

.JobItemDetails .history_item .apply_btn a {
    padding: 11px 26px;
    background-color: #089808;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    font-size: 15px
}

.JobItemDetails .history_item .other_option {
    text-align: center;
    padding-top: 15px
}

.JobItemDetails .history_item .other_option h5 {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.JobItemDetails .history_item .other_option h6 {
    font-size: 16px;
    color: #5c5c5c
}

.JobItemDetails .history_item .other_option h6 span {
    color: #089808;
    font-weight: 400
}

.top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}.notification {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #323232;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    z-index: 1000;
  }
  
  .notification.bottom-left {
    opacity: 1;
    transform: translateY(0);
  }
  
  .notification.bottom-left.hide {
    opacity: 0;
    transform: translateY(20px);
  } 
  .verified_badge {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color:#eac241;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .verified_icon {
    width: 16px;
    height: 16px;
    fill: white;
  }
  /* Premium Badge */
.premium_badge {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    background-color: #ff9800; /* Orange */
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .premium_badge .icon {
    font-size: 14px;
    margin-right: 5px;
  }
  .media_content img{
    max-width: 100%;
    max-height: 350px;
  }
    
  .preview-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
  }
  
  .preview-content {
    display: flex;
    align-items: center;
  }
  
  .preview-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .preview-text {
    max-width: 200px;
  }
  
  .preview-title {
    font-weight: bold;
    color: #000;
  }
  
  .preview-description {
    font-size: 14px;
    color: gray;
  }
  
  .preview-domain {
    font-size: 12px;
    color: #555;
  } 
  .upload_input_area .link-preview{
    max-height: 300px;
    overflow: scroll;
  }
  .search-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .search-container .form-control {
    flex-grow: 1;
  }
  
  .search-container .btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  