.business-section {
    overflow: hidden;
    border-radius: 16px;
}

.business-section-banner {
    position: relative;
    z-index: 1;
}


.banner-edit {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
}

.banner-edit-btn {
    border-radius: 35px !important;
    background-color: #fff !important;
}

.profile-info{
    margin-top: -70px;
    margin-left: 20px;
}

.profile-avatar {
    position: relative;
    z-index: 4;
    display: inline-block;
}
.avatar-edit{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.avatar-edit-btn{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #1976d289;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.avatar-edit-btn:hover{
    color: #3487d9fe;
}

.profile-info{
    display: flex;
    align-items: center;
}
.profile-info .user-name{
    font-size: 24px;
    font-weight: 600;
    margin-top: -25px;
    display: flex;
    align-items: center;
}
.profile-info .user-name span{
    font-size: 10px;
    color: #0761bafe;
    background-color: #3487d941;
    border-radius: 35px;
    padding: 0px 10px;
    font-weight: 400;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.business-section-content{
    padding: 30px 30px 20px 30px;
}

.user-data{
    margin-top: 110px;
}
.user-data h6{
    font-size: 14px;
    color: #5e5d5d;
    font-weight: 400;
    line-height: 18px;
}
.microsite-tagline{
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-top: 10px;
    color: #333 !important; 
}

.microsite-details{
    padding: 20px;
    border: 1px solid #c3c3c375;
    margin: 20px 20px 0 20px;
    background-color: #f3f3f3;
    border-radius: 12px;
    color: #525151;
    font-size: 15px;
}
.image_container{
    height: 250px !important;
}

.image_container .ant-image {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.single-big-img {
    height: 350px !important;
}
.busines-plan-content{
    padding: 30px 20px;
}
.choose-commuinity {
    padding: 40px 20px;
}
.post-business-container {
    padding: 10px 10px 30px 10px;
}
.business-setup-content{
    padding: 20px 20px;
}

.share-close-icon{
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    height: 35px;
    width: 35px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.share-back-icon{
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 2;
    height: 35px;
    width: 35px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.share-back-icon:hover{
    background-color: #e2e0e0;
}
.share-close-icon:hover{
    background-color: #e2e0e0;
}
.share-close-icon .MuiSvgIcon-root{
   font-size: 18px;
}
.share-back-icon .MuiSvgIcon-root{
    font-size: 18px;
 }

.share-body{
    width: 100%;
    min-width: 500px;
}

.profile-container{

}

.profile-container .profile-content{
   margin-left: 10px;
}
.profile-container .profile-content h5{
    line-height: 15px;
}
.profile-container .post-content span{
  font-size: 12px;
  font-weight: 500;
  padding: 5px 12px;
  color: #4d4b4b;
  background-color: #e8e8e8;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}
.profile-container .post-content span:hover{
    background-color: #e0e0e0 ;
}
.profile-container .post-content span .MuiSvgIcon-root{
  font-size: 15px;
}

.post-writer-content{
  margin-top: 30px;
}
.post-writer-content textarea{
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 14px;
  color: #545151;
}
.post-writer-content textarea:focus{
    box-shadow: none;
    outline: 0;
}
.post-writer-content textarea::placeholder{
    color: #4c4c4ea9;
}
.send-content{
    margin-top: 30px;
}
.send-content-item{
    cursor: pointer;
    border: 1px solid #c3c3c357;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 4px 0 4px;
    border-radius: 6px;
    background-color: #f5f5f5;
}
.send-content-item:hover{
    background-color: #e6e5e5;
}
.share-content-item{
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 4px 0 4px;
}

.share-content-item .share-icon{
    height: 62px;
    width: 62px;
    background-color: rgb(222, 219, 219);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.share-content-item .share-icon img{
    max-height: 50%;
    max-width: 50%;
    object-fit: contain;
}
 .share-content-item:hover{
    background-color: #f3f3f3;
    border-radius: 5px;
 }
.share-content-item h6{
    font-size: 13px;
    font-weight: 400;
    color: #282727;
    margin-top: 4px;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.send-content-item h6{
    font-size: 12px;
    font-weight: 400;
    color: #282727;
    margin-top: 4px;
    line-height: 20px;
    display: block;
    display: -webkit-box;
    height: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
.share-content-item .share-icon .MuiSvgIcon-root{
  font-size: 32px;
  color: #565656;
}

.send-content .swiper-button-disabled{
    display: none;
}
.send-content .swiper-button-next{
  height: 40px;
  width: 40px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1),0 0 0 1px rgba(0, 0, 0, 0.1);
}
.send-content .swiper-button-prev{
    height: 40px;
    width: 40px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1),0 0 0 1px rgba(0, 0, 0, 0.1);
  }
.send-content .swiper-button-next::after {
    font-size: 15px;
    color: #343434;
}

.send-content .swiper-button-prev::after {
    font-size: 15px;
    color: #343434;
}
.modal-friends-body p{
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #65686C;
}

.audiance-lebel{
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 8px 0 8px 8px  !important;
    border-radius: 8px;
    cursor: pointer;
    /* background-color: red; */
}
.audiance-lebel:hover{
    background-color: #e5e4e467;
}
.audiance-lebel label{
    margin: 0px !important;
}
.audiance-text i{
    height: 62px;
    width: 62px;
    background-color: rgb(225, 222, 222);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.audiance-text i .MuiSvgIcon-root{
    font-size: 34px;
}
.audiance-text h5{
    line-height: 14px;
}
.audiance-text h6{
    line-height: 14px;
}
.audiance-text p{
    font-size: 13px;
    line-height: 12px;
}

.share-dialog .MuiDialog-paper{
    overflow: hidden !important;
    overflow-y: scroll !important;
}
.specificFriends-serachbar{
    min-width: 500px;
    position: relative;
}
.specificFriends-serachbar i{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.specificFriends-serachbar i .MuiSvgIcon-root{
  color: #6a6969;
}
.specificFriends-serachbar input{
    height: 45px;
    border: 1px solid #c3c3c344;
    background-color: #efeded;
    width: 100%;
    padding: 0 20px;
    border-radius: 35px;
}
.specificFriends-serachbar input::placeholder{
    color: #7a7979;
    font-weight: 400;
}
.specificFriends-serachbar input:focus{
    outline: 0;
    box-shadow: none;
}
.justify-between{
    justify-content: space-between !important;
}
.them-subscription-container {
    width: 500px !important;
    margin: 0 auto !important;
}

.type-content label{
  display: block !important;
}

.copy-link-content{
    padding: 20px 20px;
    background-color: #f3f3f3;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    border-radius: 10px;
    border: 1px solid #c3c3c328;
    margin: 20px 30px;
}
.senting_massage a{
    color: #4d4d4d !important;
}
.link-preview a{ 
    padding: 8px;
}
.link-preview .preview-content{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.link-preview .preview-content img{
    width: 100% !important;
    height: auto !important;
    margin-right: 0px !important;
    max-height: 300px;
}
.attachment_content svg{
    cursor: pointer; 
    color: #fff;
}
.link-preview .preview-content .preview-text{ 
  max-width: 100% !important;
  padding-top: 10px;
}
.link-preview .preview-content .preview-text p{ 
    line-height: 21px;
}

.chat .chatter_box_container .chat_body .own_media_massage_item .media_content{
    margin-bottom: 2px;
}
.chat .chatter_box_container .chat_body .own_media_massage_item .chat_timing {
    color: #a6a1a1 !important;
}
.chat .chatter_box_container .chat_body .own_media_massage_item{
    padding: 12px !important;
}
.chat .chatter_box_container .chat_body .chat_massage_item .media-chat {
    margin-left: 7px;
    min-width: 160px;
    max-width: 70%;
    position: relative;
    border-radius: 6px;
    padding: 12px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .07);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #050505;
    position: relative;
    word-wrap: break-word;
}
.chat_timing i{
    margin-right: 4px !important;
}

.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .media_content{
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 2px;

}
.chat .chatter_box_container .chat_body .own_media_massage_item .media_content {
    border: none !important;
    border-radius: 0px !important;
}
.chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat{
    padding-left: 12px !important;
}
.search-container .form-control{
    font-size: 14px;
}
.search-container .form-control::placeholder{
    font-size: 13px;
}
.search-container .form-control:focus{
    box-shadow: none;
    outline: 0;
}
.send-content-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chat-list-menu{
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 3;
}

.chat-list-dot{
    height: 35px;
    width: 35px;
    background-color: #ffffffea;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.115) 0px 8px 24px;
    opacity: 0;
}
.chat .chat_left .chat_list .chat_list_item:hover .chat-list-dot{
    opacity: 1;
    transition: all linear .1s;
}
.chat-list-dot .MuiSvgIcon-root{
    font-size: 17px;
    color: #817e7e;
}
.ant-dropdown .MuiSvgIcon-root{
    font-size: 18px !important;
}
.ant-dropdown-menu-title-content a{
    color: #333 !important;
}
.ant-dropdown-menu-item .MuiSvgIcon-root{
    color: #626161;
}
.own-message-menu{
    display: flex;
    align-items: center;
}

/* .chat .chatter_box_container .chat_body .own_massage_item{
    align-items: center;
} */
.own-messeage-dropdown{
    height: 30px;
    width: 30px;
    background-color: #ffffffc9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.115) 0px 8px 24px;
    cursor: pointer;
    opacity: 0;
}
.sender-message-menu{
    height: 30px;
    width: 30px;
    background-color: #ffffffc9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.115) 0px 8px 24px;
    cursor: pointer;
    margin-left: 7px;
    opacity: 0;
    position: absolute;
    right: -37px;
    top: 50%;
    transform: translateY(-50%);
}
.own-media-dropdown{
    height: 30px;
    width: 30px;
    background-color: #ffffffc9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.115) 0px 8px 24px;
    cursor: pointer;
}
.own-media-dropdown .MuiSvgIcon-root{
    font-size: 16px;
    color: #817e7e;
}
.chatter_chat:hover .sender-message-menu{
    opacity: 1;
    transition:  all linear .1s;
}

.sender-message-menu .MuiSvgIcon-root{
    font-size: 16px;
    color: #817e7e;
}
.own_massage_item:hover .own-messeage-dropdown{
    opacity: 1;
}
.own-messeage-dropdown:hover{
    background-color: #fff;
}
.own-messeage-dropdown .MuiSvgIcon-root{
    font-size: 16px;
    color: #817e7e;
}
.own-media-menu{
    position: absolute;
    top: 50%;
    left: -37px;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0;
}

.own_media_massage_item:hover .own-media-menu{
    opacity: 1;
}

.sender-media-menu{
    position: absolute;
    top: 50%;
    right: -37px;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0;
}
.sender-media-dropdown{
    height: 30px;
    width: 30px;
    background-color: #ffffffc9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.115) 0px 8px 24px;
    cursor: pointer;
}
.sender-media-dropdown .MuiSvgIcon-root{
    font-size: 16px;
    color: #817e7e;
}

.media-chat:hover .sender-media-menu{
    opacity: 1;
    transition: all linear .1s;
}

.emoji-react{
    position: absolute;
    bottom: -18px;
    right: 6px;
    transform: translateY(-45%);
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border-radius: 35px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 2;
    opacity: 0;
}
.emoji-react span{
    font-size: 22px;
    cursor: pointer;
}

.media-chat:hover .emoji-react{
  opacity: 1;
  transition: all linear .2s;
  bottom: 0px;
}
.chatter_chat:hover .emoji-react{
    opacity: 1;
    transition: all linear .2s;
    bottom: -10px;  
}
.react-emotion{
    position: absolute;
    bottom: -4px;
    right: 0;
}
.react-emotion span{
    font-size: 18px;
}
.own_massage_item{
    position: relative;
}
.own_massage_item:hover .emoji-react{
    opacity: 1;
    transition: all linear .2s;
    bottom: -10px;  
}

.selct-box{
    height: 22px;
    width: 22px; 
    background-color: #ffffffe0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
}
.selct-box:hover{
    background-color: #fff;
}
.check-box{
  height: 12px;
  width: 12px;
  cursor: pointer;
  color: #7d6be0;
  border-color: #7d6be0;
}

.message-option-plate{
    background-color: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}
.message-option-plate span{
    font-size: 14px;
    color: #504e4e;
}
.plate-option{
    display: flex;
    align-items: center;
    gap: 22px;
}
.option-cancel-btn{
    padding: 2px 12px;
    border-radius: 5px;
    font-size: 13px;
    color: #5d5b5b;
    border: none;
}
.option-cancel-btn:hover{
    background-color: #e0dee4;
    color: #333;
}
.option-item{
    text-align: center;
    font-size: 11px;
    cursor: pointer;
    color: #726e6e;

}
.option-item:hover{
    color: #333;
}
.option-item div{
    line-height: 16px;
}

.typing-indicator{
    position: absolute;
    bottom: 63px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    z-index: 3;
}
.typing-indicator span{
    color: #818080;
    font-size: 14px;
}