@media (min-width: 200px) and (max-width: 991.98px) {
    .business-setup {
        padding: 0 0px;
    }

    .choose-commuinity h2 {
        font-size: 22px;
        letter-spacing: 1.2px;
        padding-top: 10px;
    }
    .busines-plan-content h2 {
        font-size: 25px;
        letter-spacing: 1.2px;
        line-height: 34px;
    }
    .business-setup {
        padding: 0 0px;
    }
    .them-subscription-container {
        width: auto;
        min-height: auto;
    }
    .business-payment {
        padding: 0 0px;
    }
    .business-type {
        padding: 0 0px;
    }
    /* Broadcast */
    .broadcast-Top .broadTop-logo p {
        display: none;
    }
    .broadcast-Top .broadTop-logo img {
        max-height: 50px;
        max-width: auto;
    }
    .broadcast-Top {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .broadcast_search_form {
        width: 300px;
        margin: 12px auto 0;
    }
    .mob-broadcast-none{
        display: none !important;
    }
    .broadcast-category-item .item-img .wrapper {
        height: 130px;
    }
    .broadcast-category-item .item-img img {
        height: 130px;
    }
    .broadcast-category {
        margin-top: 15px;
    }
    .broadcast-item-section {
        margin-top: 10px;
    }
    .broadcast-item .broadcast-img .wrapper {
        height: 220px;
    }
    .broadcast-item .broadcast-img img {
        height: 220px;
    }
    .broadcast-video .video-react {
        height: 320px !important;
    }
    .broadcast-sub-item .item-img {
        width: 40%;
    }
    .broadcast-sub-item .item-content {
        width: 60%;
    }
    .broadcast-single-category {
        display: grid;
    }
    .broadcast-single-category .item-details {
        margin-left: 0px;
    }
    .broadcast-category h4 {
        font-size: 20px;
        margin-top: 12px;
    }
    .lookBack-item .wrapper{
     height: 150px !important;
    }
    .lookBack-item .lookBack-item-content .user-info {
        display: grid;
    }

    .media-form {
        min-height: 220px;
        width: 100%;
    }








    .chat_group_body{
        width: 100%;
    }
    .mob_margin_top{
        margin-top: 30px;
    }
    .header .header_content .header_item_wrapper {
        justify-content: flex-start;
    }
    .update_btn {
        position: fixed;
        right: 20px;
        top: auto;
        bottom: 75px;
        transform: translateY(-50%);
        z-index: 2;
    }
    .creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control {
        height: 45px;
    }
    .my_files_navigate .navigate_part_one .navigate_title {
        display: none;
    }
    .order_info_wrapper .order_info_wrap .info_item .info_left {
        font-size: 15px;
        width: 25%;
    }
    .business_details {
        padding: 20px 15px;
    }
    .business_item .business_item_img {
        height: 80px;
    }
    .recomment_overview .MuiAvatar-root{
       height: 30px;
       width: 30px;
    }
    .com_info_wrap {
        padding: 19px 10px 20px 10px;
    }
    .business_item .business_item_img  img{
       width: 70px;
       height: 80px;
    }

    .rating_container .section_top .rating_add span {
        display: none;
    }
    .rating_container .rating_item {
        display: list-item;
        list-style: none;
    }
    /* .MuiPaper-root{
        margin: 25px 10px !important;
    } */
    .MuiDialog-paper {
        margin: 32px 10px !important;
    }
    .rating_container .rating_item .user_avatar{
        display: none;
    }
    .rating_container .rating_item .rating_content {
        padding-left: 0px;
    }
    .rating_container .section_top .sec_title {
        font-size: 17px;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        height: 40px;
        width: 40px;
    }

    .swiper-button-prev::after,
    .swiper-rtl .swiper-button-next::after {
        font-size: 16px;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        height: 40px;
        width: 40px;
    }

    .swiper-button-next::after,
    .swiper-rtl .swiper-button-prev::after {
        font-size: 16px;
    }

    .mag_category_Item .mag_category_Item_text h4 {
        font-size: 18px;
    }

    .overflow_card {
        overflow: visible !important;
        overflow-y: scroll !important;
    }

    .uploader_contianer .folder_name_input .css-1x5jdmq {
        min-width: 220px !important;
    }

    .file_selecter .css-5t8t4o {
        width: 240px;
    }

    .sticky_wrapper_main .main_img {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .report_detail .report_text .title {
        font-size: 18px;
        line-height: 27px;
    }

    .report_detail .reply_component .reply_sec {
        display: list-item;
        list-style: none;
    }

    .report_detail .reply_component .reply_sec .user_avtar {
        display: none;
    }

    .comments-list .comment-avatar {
        display: none;
    }
    .Home_copy_right {
        height: 50px;
        font-size: 13px;
    }
    .Card_container {
        padding-top: 90px;
    }

    .Card_container .card_wrap {
        border-radius: 12px !important;
        height: 140px;
        width: 100%;
        padding: 10px;
    }

    .Card_container .card_wrap span {
        font-size: 15px;
        font-weight: 500;
        color: #585858;
    }

   

    .login_qr_img img {
        height: 120px;
        width: 120px;
        object-fit: contain;
    }

    .creatCommuinity_form_body .creatCommuinity_wrapper h5 {
        font-size: 18px;
    }

    .creatCommuinity_form_body .creatCommuinity_wrapper h6 {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }

    .entryWrapper .entry_wrap .commuinity_search_form .searchBar i {
        padding: 0 0;
    }

    .order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id {
        font-size: 16px;
    }

    .order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id .MuiSvgIcon-root {
        font-size: 22px;
    }

    .comments-list .comment-box {
        width: 100%;
    }

    .comments-list:before {
        display: none;
    }

    .mob_padding_75 {
        margin-top: 75px;
    }
    .community_list_item {
        padding-top: 140px;
    }
    .uploader_dial {
        bottom: 100px !important;
        right: 20px  !important;
    }
    .postView_container .postView_container_Top .postViewModal_container_Image img {
        max-height: 450px;
        max-width: 100%;
    }
    .profile_bio .profile_info .userName a {
        font-size: 15px;
    }
    .profile_bio .profile_info {
        padding-left: 5px;
        width: 100%;
    }
    .video-react {
        height: 320px !important;
    }
    .video-react .video-react-video {
        max-height: 320px !important;
    }
    .longue_item .uploaded_img_container .single_img img {
        max-height: 420px;
    }
    .backArrow_head {
        position: relative;
        left: 15px;
        top: 20px;
        margin-bottom: 20px;
    }

   
   

    .lounge .upload_box_container .upload_box_bottom {
        padding-top: 10px;
    }

    .business_item {
        height: auto;
        margin-top: 8px;
    }
    .com_top_left .tagline {
        padding-top: 5px;
    }

    .com_top_left .tagline p {
        font-size: 14px;
        line-height: 18px;
    }
    .mob_none {
        display: none !important;
    }

    .community_list_item .commuinity_item {
        height: 320px;
        width: 280px;
        margin: 0 auto;
    }

    .community_list_item .commuinity_item {
        width: 280px;
        margin: 0 auto;
    }
    .drawer_Wrapper .logout_btn {
        margin: 30px 0;
        width: 100%;
        height: 45px;
        font-size: 15px;
    }
    .drawer_Wrapper .profile_dashed_mob .user_name {
        font-size: 15px;
    }
    .drawer_Wrapper .profile_dashed_mob .profile_img img {
        height: 40px;
        width: 40px;
    }

    .drawer_Wrapper {
        width: 245px;
    }
    .entryWrapper .entry_wrap .home_title {
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        padding-bottom: 35px;
    }
    .order_list_wrapper .order_list_wrap .list_top {
        display: list-item;
        list-style: none;
    }
    .order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id {
        font-size: 15px;
    }

    .my_item {
        height: 200px;
    }

    .my_files_navigate .navigate_part_two .navigate_serachbar {
        width: auto;
    }

    .business_item .resource_title {
        font-size: 17px;
        line-height: 22px;
    }
    .b_type_list{
        display: none;
    }

    .my_item .item_icon {
        height: 80px;
        width: 80px;
    }

    .order_list_wrapper .order_list_wrap .order_content .item_info .item_name {
        font-size: 14px;
    }

    .order_list_wrapper .order_list_wrap .list_top .list_top_right {
        font-size: 24px;
        padding-top: 5px;
    }

    .order_list_wrapper .order_list_wrap .update_item_info .item_update_right {
        justify-content: end;
        display: flex;
        padding-top: 7px;
    }

    .order_list_wrapper .order_list_wrap .order_content {
        display: flex;
        align-items: center;
        padding-left: 0px;
        padding-top: 10px;
    }

    .order_list_wrapper .order_list_wrap .update_item_info {
        display: list-item;
        list-style: none;
        justify-content: space-between;
        align-items: center;
        padding-top: 8px;
        padding-left: 0px;
    }

  

    .copy_right {
        position: relative;
        width: 100%;
        height: 50px;
        font-size: 13px;
    }
    .creatCommuinity_form_body .backArrow {
        left: 0;
        top: 20px;
    }
    .creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap {
        width: 100%;
        padding-top: 12px;
    }

    .creatCommuinity_wrapper {
        margin: 110px 0 40px 0;
        padding: 30px 15px !important;
    }

    /* Inside Commuinity */
    .side-menu {
        display: none;
    }

    

    .lounge_right_fixed {
        display: none;
    }

   

    .css-1oqqzyl-MuiContainer-root {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .longue_item {
        margin-bottom: 7px;
    }

    .content_body {
        padding-top: 55px;
        margin-bottom: 40px;
    }



    .profile_bio {
        padding: 8px 8px;
    }


    /* .MuiDialog-paper {
        border-radius: 0px !important;
        overflow: visible !important;

    } */
    .mob_media_dialouge{
        padding: 5px !important;
    }
    .Media_dilouge .MuiPaper-root{
      margin: 10px !important;
      max-width: 280px !important;
    }
    .upload_input_area .Upload_textarea {
        width: 100%;
        border: none !important;
    }
    .dialogContent-2 .mediaAdd_Body .mediaAdder {
        width: 100%;
    }

    .dialogContent-2 .TagBody {
        width: 100%;
    }
    .classified_item_detail .bidding_container .bidding_btn {
        width: 100%;
        font-size: 15px;
    }
    .userName {
        line-height: 15px !important;
        font-size: 15px !important;
    }
    .comment_input_main {
        padding-right: 9px;
        padding-top: 20px;
    }
    .comment_input_main .comment_input_container .comment_attachment ul li .comment_emoji_picker {
        bottom: -270px;
        right: -210px;
        height: 260px;
        max-width: 300px;
    }
    .emojis_container .emojiTab {
        padding: 5px 5px;
        border-radius: 12px;
        top: 5px;
        left: 10px;
        width: 240px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .emojis_container .emojiTab .singleEmoji {
        font-size: 30px;
        padding: 18px 10px;
    }

    .postView_container .postView_container_Top .postViewModal_container_Image {
        min-height: 400px;
        height: auto;
        width: 100%;
    }

    .bidding_container .bidding_item {
        width: 100%;
    }

    .lounge .upload_box_container {
        margin-bottom: 10px;
    }
    .MemberSearch_body {
        width: 100%;
    }

    .MemberSearch_body .memberSearch_wrapper {
        width: 100%;
    }

    .addMember_card {
        width: 100%;
    }
    #simple-tabpanel-0 .MuiBox-root {
        padding: 20px 0px;
    }

    .backArrow {
        margin-bottom: 10px;
    }
    .media_content_body {
        padding-top: 45px;
    }

    .Media_area {
        padding-top: 10px;
    }
    .entryWrapper .entry_wrap .commuinity_search_form {
        width: 90%;
        margin: 0 auto;
    }

    .home_banner {
        height: 180px;
        border-radius: 8px;
        background: #fff;
    }

    .home_banner img {
        object-fit: contain;
    }

    .entryWrapper {
        padding: 70px 0;
    }

    .entryWrapper .entry_wrap .community_list_item .commuinity_item {
        height: 350px;
        width: auto;
        max-width: 300px;
        margin: 0 auto;
    }   
    /* ------------------------------------------------------------------------
                        Community Info Chat Start 
    ---------------------------------------------------------------------------*/
    .communityInfo_wrapper .community_top_info .com_botttom {
        display: flex;
        justify-content: flex-end;
    }
    .communityInfo_wrapper .community_top_info {
        display: list-item;
        list-style: none;
    }
    .communityInfo_wrapper .community_top_info .com_top_left .com_name {
        font-size: 18px;
        line-height: 21px;
    }

    .communityInfo_wrapper .community_top_info .com_top_left .tagline {
        font-size: 15px;
    }
   
    .communityInfo_wrapper .community_top_info .edit_B {
        display: flex;
        justify-content: flex-end;
    }

    .communityInfo_wrapper .community_top_info .edit_button {
        position: relative;
        top: 0;
        right: 0;
        font-size: 13px;
        border-radius: 35px;
        display: flex;
        padding: 1px 20px;
        height: 38px;
    }
    .communityInfo_wrapper .community_top_info .back_ic {
        height: 38px;
        margin-right: 10px;
    }

    .communityInfo_wrapper .community_top_info .back_ic img {
        max-height: 20px;
    }

    .classified_cetegory_list {
        padding-bottom: 0px;
    }

    .communityInfo_wrapper .community_top_info .edit_button .MuiSvgIcon-root {
        font-size: 14px;
    }
    .communityInfo_wrapper .commuinity_banner {
        height: 140px;
        width: 100%;
    }

    .communityInfo_wrapper .community_top_info .com_logo img {
        height: 80px;
        width: 80px;
    }
     /* ------------------------------------------------------------------------
                       Home Content Chat Start 
    ---------------------------------------------------------------------------*/
    .homeMainContent .login_D_form .login_d_right .registerBody form .get_emailBtn {
        font-size: 15px;
    }

    .homeMainContent .login_D_form .login_d_right h6 {
        font-size: 20px;
    }
    .homeMainContent .backArrow {
        left: 0;
        top: 20px;
    }
    .homeMainContent .login_D_form .login_d_right {
        height: auto;
        min-height: auto;
        max-width: 100%;
        width: 100%;
        padding: 30px 20px 15px 20px;
    }

    .homeMainContent .login_D_form .login_d_right .login_bday form h3 {
        padding-top: 60px;
    }

    .homeMainContent .login_D_form .login_d_left {
        height: 100%;
        width: 100%;
        padding: 20px 20px 15px 20px;
        position: relative;
    }

    .homeMainContent .login_D_form .login_d_left {
        padding: 10px;
    }

    .homeMainContent .login_D_form {
        height: auto;
        display: list-item;
        list-style: none;
        margin-top: 135px;
        margin-bottom: 50px;
        padding: 15px;
    }

    .homeMainContent {
        width: 100%;
        height: auto;
    }


     /* ------------------------------------------------------------------------
                        matrimonial Start 
    ---------------------------------------------------------------------------*/

    .matrimonial_card_details {
        padding: 20px 10px;
    }

    .matrimonial_card_details .person_information .person_image {
        height: 160px;
        width: 200px;
        margin: 12px auto;
    }
    .matrimonial_card_details .information_table {
        padding: 12px 0px 0 0px;
    }
    .matrimonial_card_details .information_table .info_item_list .info_item {
        padding-left: 10px;
    }
    .matrimonial_card_details .information_table .gallery_wrap .ant-image img {
        height: 90px;
        width: 100%;
    }

    .matrimonial_card_details .person_information {
        padding: 10px 10px 0 10px;
        margin-bottom: 25px;
    }

    .matromonial_search_form {
        width: 100%;
    }
     /* ------------------------------------------------------------------------
                       Job Item Details Chat Start 
    ---------------------------------------------------------------------------*/
    .job_item_info .job_item_info_middle {
        display: none;
    }
    .JobItemDetails .history_item .job_info .info_1 p {
        font-size: 14px;
    }

    .JobItemDetails .history_item .job_text h6 {
        font-size: 16px;
    }

    .JobItemDetails .history_item .job_text p {
        font-size: 14px;
        line-height: 22px;
    }

    .JobItemDetails .history_item .job_info .info_1 {
        width: 100%;
    }

    .JobItemDetails .history_item .other_option h5 {
        font-size: 16px;
    }

    .JobItemDetails .history_item .other_option h6 {
        font-size: 15px;
    }
    .JobItemDetails .history_item .job_info .info_2 {
        display: none;
    }

    .JobItemDetails .history_item .job_info .info_1 {
        width: 100%;
    }
    .job_filter {
        display: none;
    }

    .job_searchBar h4 {
        font-size: 18px;
    }

    .job_searchBar .job_plate .job_btn {
        width: 30%;
        height: 39px;
    }
    .job_searchBar .job_plate .job_btn span {
        line-height: 39px;
    }

    .job_searchBar .job_plate .job_srach_item-1 .form_control {
        height: 40px;
    }

    .job_searchBar p {
        font-size: 14px;
        text-align: start;
    }
    .job_item_info .job_item_info_top {
        display: list-item;
        list-style: none;
    }

    .job_item_info .job_fotter {
        display: list-item;
        list-style: none;
    }

   

    .job_item_info .job_fotter .dead-line {
        font-size: 14px;
        font-style: normal !important;
    }

    .job_item_info .job_info_btn .MuiAvatar-root {
        height: 24px;
        width: 24px;
    }

    .job_item_info .job_info_btn .MuiSvgIcon-root {
        font-size: 15px;
    }

    .job_item_info .job_item_info_top .job_item_infoTop_right {
        margin-left: 0px;
        width: 100%;
        padding-right: 0px;
        padding-top: 5px;
    }

    .job_item_info .job_item_info_top .company_logo {
        height: 50px;
        width: 100px;
    }


     /* ------------------------------------------------------------------------
                      Mamber  Chat Start 
    ---------------------------------------------------------------------------*/


    .mamber .mamber_wrapper .memberItem .user_info .user_name {
        font-size: 15px;
        padding: 2px 0 4px 0;
    }
    .mamber .mamber_wrapper .memberItem .icon_list {
        justify-content: center;
    }
    .mamber .mamber_wrapper .memberItem {
        padding: 14px 8px;
        height: auto;
    }

    .mamber_hedingSIde .headingSection {
        font-size: 16px;
    }
    .mamber_hedingSIde .mamber_hedingSide_right {
        flex-wrap: wrap;
        margin-top: 12px;
        justify-content: center;
    }

    .mamber .mamber_wrapper .memberItem .user_info .user_img img {
        height: 70px;
        width: 70px;

    }
    .mamber_hedingSIde {
        display: list-item;
        list-style: none;
    }
    .mamber_hedingSide_right button {
        font-size: 12px;
    }
    .mamber .mamber_wrapper .memberItem .memberApproved_btn button {
        width: 95%;
    }
    .mamber .mamber_wrapper .memberItem .memberApproved_btn button span{
        display: none;
    }
    .mamber .mamber_wrapper .memberItem .user_info .join_date {
        font-size: 11px;
        padding-bottom: 0px;
        line-height: 18px;
    }
    .mamber .mamber_wrapper .memberItem .user_info .user_name .MuiSvgIcon-root{
        font-size: 15px;
        margin-right: 2px;
    }
    .mamber .mamber_wrapper .memberItem .owner_member {
        width: 95%;
    }
    .mamber .mamber_wrapper .memberItem .icon_list .icon {
        height: 32px;
        width: 32px;
    }
    .mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate {
        width: 120px;
    }
    .mamber .mamber_wrapper .memberItem .icon_list .icon .MuiSvgIcon-root {
        font-size: 18px;
    }
    .mamber .mamber_wrapper .memberItem .icon_list .icon img {
        height: 20px;
        width: 20px;
    }
    .mamber .mamber_wrapper .memberItem .memberCard_footer {
        margin-top: 10px;
    }
    .mamber_hedingSIde .mamber_hedingSide_right .addMamber_btn {
        padding: 4px 16px;
        font-size: 13px;
        margin-bottom: 10px;
    }
    .mamber_hedingSIde .mamber_hedingSide_right .addMamber_btn span {
        display: none;
    }
    .mamber_hedingSIde .mamber_hedingSide_right .MuiButtonBase-root {
        margin-bottom: 10px;
    }
    .lounge_modal_mobile .MuiDialog-paper{
        margin: 0px !important;
        border-radius: 0px !important;
    }

    /* ------------------------------------------------------------------------
                      Dialogue Chat Start 
    ---------------------------------------------------------------------------*/

    .dialog_footer .emoji_picker {
        top: 60px;
        z-index: 99;
        right: 10px;
        max-width: 300px;
    }
    .dialog_footer .upload_attached ul li .small-text {
        display: none;
    }
    /* .dialog_footer .upload_attached ul .list_item_desktop {
        display: none;
    } */

    .dialog_footer .upload_attached ul li {
        cursor: pointer;
        font-size: 20px;
        padding: 0px 0px;
        border-radius: 10px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        height: 45px;
        width: 45px;
        justify-content: center;
    }

    .dialog_footer .upload_attached ul li .MuiSvgIcon-root {
        font-size: 22px;
        margin-right: 0px;
    }

    /* ------------------------------------------------------------------------
                       Resource Chat Start 
    ---------------------------------------------------------------------------*/
    .resource_item_2 {
        width: auto;
        max-width: 300px;
        margin: 0 auto;
    }
    .resource_item .resource_footer {
        position: relative;
        padding: 15px 0 0 0;
    }

    .resource_item .resource_footer .res_footer_left {
        display: none;
    }

    .resource_details .details_file_container .file_tab .ant-image {
        height: 130px;
        width: 120px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .resource_details h4 {
        font-size: 20px;
        line-height: 30px;
        padding-bottom: 10px;
    }

    .resource_details {
        padding: 25px 15px;
    }


    /* ------------------------------------------------------------------------
                      Event  Chat Start 
    ---------------------------------------------------------------------------*/
    .event_create {
        padding: 25px 15px;
    }

    .selector_body {
        width: 100%;
        min-width: 300px;
        min-height: 350px;
    }
    .event_locat{
        margin-left: 0px;
    }

    .event_details_body .invite_section .invite_top {
        display: list-item;
        list-style: none;
    }
    .event_details_body .invite_section .invite_top .invite_top_left span {
        font-size: 14px;
        color: #494949;
    }
    .guestList_ShortView .MuiAvatar-root{
      height: 32px;
      width: 32px;
    }
    .event_details_body .invite_section {
        padding: 10px 8px;
    }
    .event_details_body .invite_section .invite_top .invite_top_left {
        display: list-item;
        list-style: none;
    }
    .event_details_body .invite_section .invite_top .invite_top_left .MuiDivider-root{
        display: none;
    }
    .event_details_body .invite_section .invite_top .invite_top_right .invite_btn {
        margin-top: 15px;
    }
    .event_item .event_title {
        font-size: 17px;
        line-height: 24px;
    }
    .event_item {
        margin-top: 8px;
        height: auto;
    }
    .event_details_body .event_details_top .event_info .event_title {
        font-size: 20px;
        line-height: 28px;
        padding-top: 15px;
    }
    .event_details_body .event_details_top .event_info .eventTime {
        display: list-item;
        list-style: none;
    }


    /* ------------------------------------------------------------------------
                     Section header Chat Start 
    ---------------------------------------------------------------------------*/
    .section_headear h4 {
        font-size: 17px;
    }
    .section_headear .btns_row .Btn_one {
        margin-left: 0px;
    }

    .section_headear .btns_row {
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 15px;
    }
    .section_headear .btns_row .Btn_one {
        height: 38px;
        padding: 0 12px;
        font-size: 14px;

    }
    .section_headear .btns_row .Btn_two {
        height: 35px;
        padding: 0 6px;
        font-size: 12px;
        margin-left: 15px;
    }
    .section_headear {
        
        padding-bottom: 20px;
    }
    .section_headear .btns_row .Btn_one span {
        display: none;
    }

    .section_headear .btns_row .Btn_two span {
        display: none;
    }

    /* ------------------------------------------------------------------------
                      Classified  Chat Start 
    ---------------------------------------------------------------------------*/
    .classified_order_form {
        width: 100%;
    }
    .classified_item .post_bottom .classified_option {
        height: 30px;
        width: 30px;
    }

    .classified_item .post_bottom .classified_option .MuiSvgIcon-root {
        font-size: 17px;
    }
    .classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control {
        height: 45px;
        padding: 0 20px;
    }
    .classified_heading .section_name {
        font-size: 16px;
    }

    .classified_top_section .classified_top_img {
        height: 200px;
    }
    .classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search {
        width: auto;
        min-width: 280px;
        max-width: 320px;
    }

    .classified_cetegory_list .list_category_item {
        display: none;
    }

    .classified_item .classified_item_img {
        height: 120px;
        width: 100%;
    }
    .classified_create .classified_create_form .form_rowing .MuiFormLabel-root {
        margin-right: 10px;
    }
    .classified_item .post_bottom .cart_icon {
        height: 30px;
        width: 30px;
        background-color: transparent;
    }
    .bidding_container .bidding_item .item_top {
        display: list-item;
        list-style: none;
        padding: 12px 0px;
        position: relative;
    }
    .bidding_container .bidding_item .item_top .top_left .use_info .time {
        font-size: 11px;
        line-height: 23px;
    }
    .bidding_container .bidding_item .item_top .top_right{
        position: absolute;
        top: 5px;
        right: 0;

    }
    .bidding_container .bidding_item .item_top .top_left .user_img img {
        height: 40px;
        width: 40px;
    }
    .bidding_container .bidding_item .item_top .bidamount {
        font-size: 18px;
        text-align: end;
    }
    .classified_item_detail {
        padding: 15px 12px;
    }
    .top_classified_navigate {
        display: none;
    }
    .classified_orderList_container {
        padding: 22px 13px 20px 13px;
    }
    .classified_item_detail .swipper_item_content .product_price {
        font-size: 26px;
        padding-top: 4px;
    }
    .classified_item .item_content .post_title {
        font-size: 15px;
        line-height: 22px;
    }

    .classified_item .post_bottom .post_amount {
        font-size: 14px;
    }

    .classified_item {
        height: 225px;
    }

    .classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide {
        height: 300px;
    }

    .classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .navigate_img {
        height: 60px;
        height: 60px;
        width: 100%;
        object-fit: cover;
    }

    /* ------------------------------------------------------------------------
                        User Profile  Start 
    ---------------------------------------------------------------------------*/

    .user_profile .user_profile_content .user_join_date {
        margin-top: 15px;
    }
    .user_profile .cover-photo {
        height: 200px;
    }

    .user_profile .user_profile_content .content_top .user_profileImg {
        height: 100px;
        width: 100px;
        margin-top: -60px;
        margin-left: 0px;
    }

    .user_profile .user_profile_content .content_top .profile-name {
        padding-left: 10px;
        font-size: 20px;
    }

    .user_profile .user_profile_content {
        padding: 10px 10px;
    }
    .user_profile .user_profile_content .user_icons_list {
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .user_profile .user_profile_content .user_icons_list a {
        margin-top: 10px;
    }

    /* ------------------------------------------------------------------------
                        Header Start 
    ---------------------------------------------------------------------------*/
    .header .header_content .header_item .commuinity_name {
        display: none;
    }
    .entry_header .entry_header_wrap .entryHeader_right .createBtn {
        height: 36px;
        padding: 0 10px;
        font-size: 12px;
        margin-left: 10px;
    }

    .entry_header .entry_header_wrap .entryHeader_logo img {
        height: 45px;
    }

    .entry_header .entry_header_wrap .entryHeader_logo img {
        height: 40px;
    }

    .entry_header .entry_header_wrap .entryHeader_right .profile_dashed .profile_img {
        height: 40px;
        width: 40px;
    }

    .entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list i .MuiSvgIcon-root {
        font-size: 18px;
    }

    .entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list span {
        font-size: 14px;
    }

    .entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper .menu_wrapper_list i {
        height: 32px;
        width: 32px;
    }
    .entry_header .entry_header_wrap .entryHeader_right .profile_dashed .menu_wrapper {
        width: 220px;
    }
    .header .header_content .header_item_wrapper .header_item_list .MuiSvgIcon-root {
        font-size: 20px;
    }
    .header .header_content .header_item .commuinity_name {
        margin-left: 5px;
        font-weight: 400;
        font-size: 14px;
    }
    .header .header_content .header_item_wrapper .header_item_list {
        margin: 0 5px;
        height: 32px;
        width: 32px;
    }
    .header .header_content .header_item_wrapper2 .profile_dashed {
        display: none;
    }

    .header .header_content .header_item_wrapper2 .mobile_hamburger_icon {
        display: flex;
        height: 40px;
        width: 40px;
    }
    .header .header_content .header_item {
        padding-top: 6px;
    }

    .header .header_content .header_item .logo {
        height: 38px;
    }

    /* ------------------------------------------------------------------------
                        Magazine Start 
    ---------------------------------------------------------------------------*/
    .mui_table{
        width: auto;
        min-width: auto !important;
    }
    /* .top_left{
        display: none !important;
    } */
    .bidding_container .bidding_item .item_top .top_left {
        display: flex;
    }
    .m_menu{
        display: flex !important;
        position: absolute;
        top: -50px;
        right: 0;
        z-index: 2;
    }
    .mag_category_Item .mag_category_Item_img img {
        height: 80px;
        width: 70px;
        border-radius: 8px;
    }
    .magazine_category_wrapper {
        padding: 20px 10px;
    }
    .magazine_nav .nav_list li {
        padding-right: 15px;
    }
    .magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_text {
        padding-left: 0px;
        padding-top: 10px;
    }

    .magazine_top .magazine_t span {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
    }

    .magazine .sticky_section .sticky_wrapper .sticky_wrapper_main .main_img img {
        height: 220px;
        width: 220px;
    }

    .magazine_details .magazine_details_wrapper .content_title {
        font-size: 26px;
        line-height: 35px;
        padding-top: 20px;
    }
    .magazine .main_content_item .item_img {
        height: 200px;
    }

    .magazine .main_content_item {
        height: auto;
    }

    .magazine .front_section .front_main .front_main_img img {
        height: 220px;
    }

    .magazine .front_section .front_main {
        height: auto;
    }


    .magazine .magzine_slider_content .mag_content .content_info h2 {
        font-size: 20px;
        font-weight: 500;
        padding: 8px 8px;
        line-height: 26px;
    }

    .magazine_top .magazine_t {
        text-align: start;
    }
    .magazine {
        padding: 20px 10px;
    }
    /* .magazine_top .top_left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: -76px;
        right: 0;
    } */

    .magazine_top .magzin_logo img {
        max-height: 45px;
        width: 100%;
        object-fit: contain;
    }
    .magazine .magzine_slider_content {
        height: 220px;
        margin-top: 25px;
    }

    .magazine .magzine_slider_content .mag_content .content_info h2 {
        font-size: 18px;
        padding: 8px 8px;
    }

    .magazine_top {
        margin-top: 12px;
    }

    .magazine .sticky_section .sticky_wrapper .sticky_wrapper_main {
        display: list-item;
        list-style: none;
    }

    .magazine_top {
        display: list-item;
        list-style: none;
        height: 100px;
    }

    .magazine_top .top_left {
        display: flex;
        justify-items: center;
        justify-content: end;
        margin-top: 12px;
    }
    .magazine_nav{
        display: list-item;
        list-style: none;
    }
    .magazine_nav .mag_search {
        width: 200px;
        margin: 20px 0 0 auto;
    }
    .magazine_nav .mag_search .form_control {
        height: 38px;
        width: 200px;
    }
    /* ------------------------------------------------------------------------
                        Chat Start 
    ---------------------------------------------------------------------------*/
    .chat_box_icon .chat_button img {
        height: 30px;
        width: 30px;
    }

    .chat_box_icon {
        bottom: 30px;
        height: 50px;
        width: 50px;
    }
    .chat_box_icon .chat_button span {
        display: none;
    }
    .chat_contentBody {
        height: 94vh;
    }
    .chat .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .dashed_btn {
        display: none;
    }
    .chat {
        width: 100%;
        border-radius: 13px;
        display: list-item;
        list-style: none;
        overflow: visible;
        padding-bottom: 10px;
    }
    .chat .chatter_box_container .chat_body::after {
       display: none;
    }

    .chat .chatter_box_container {
        overflow: hidden;
        height: 68vh;
        width: 95%;
        border: 1px solid #c3c3c354;
        border-radius: 13px;
        margin: 0 auto;
        background-color: #f3f3f379;
    }
    .chat .chat_left .chat_top_content .chat_left_top {
        padding: 0 10px 0 0;
    }
    .chat .chatter_box_container .fixed_profilr_header {
        background-color: rgba(3,40,141,.1098039216);
        height: 50px;
        border-top-left-radius: 13px;
        padding: 12px 15px 10px 15px;
    }
    .chat .chatter_box_container .fixed_profilr_header .ct_left .name_T .active_status {
        font-size: 10px;
    }
    .chat .chatter_box_container .fixed_profilr_header .ct_left .name_T .name {
        font-size: 14px;
    }
    .chat .chatter_box_container .fixed_profilr_header .ct_right .chat_title_dashed .vid_icon {
        display: none;
    }

    .chat .chatter_box_container .fixed_profilr_header .ct_left .profile img {
        height: 38px;
        width: 38px;
    }
    
    .chat .chat_left .chat_top_content .chat_left_top .chat_h {
        font-size: 16px;
    }

    .chat .chat_left .chat_top_content .chat_search .search_box {
        /* display: none; */
    }

    .chat .chat_left .chat_top_content .active_list {
        /* display: none; */
    }
    .chat .chatter_box_container .chat_body {
        padding: 12px 12px 130px 12px;
    }

    .chat .chat_left .chat_top_content {
        height: auto;
    }

    .chat .chat_left .chat_list .chat_list_item .chat_overview .overview_massage {
        display: none;
    }

    .chat .chat_left .chat_list .chat_list_item {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 70px;
        height: 80px;
        margin-right: 6px;
        padding: 2px;
    }

    .chat .chat_left .chat_list .chat_list_item .chat_overview .profile-name {
        margin-top: 3px;
        font-size: 10px;
        font-weight: 400;
        word-wrap: break-word;
        line-height: 12px;
        text-align: center;
        display: block;
        display: -webkit-box;
        height: 2;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .chat .chat_left .chat_list .chat_list_item .profile_active {
        width: auto;
    }

    .chat .chat_left .chat_list .chat_list_item .lastMassage-time {
        display: none;
    }

    .chat .chat_left .chat_list .chat_list_item .senting {
        display: none;
    }

    .chat .chat_left {
        width: 100%;
        height: auto;
        padding: 15px 3px 15px 10px;
        border-top-right-radius: 13px;
        border-top-left-radius: 13px;

    }

    .chat .chat_left .chat_list {
        padding-bottom: 0;
        padding-top: 12px;
        display: flex;
        overflow-x: auto;
        scrollbar-width:none;
    }
    .chat .chat_left .chat_list::-webkit-scrollbar {
        display: none;
      }

   

    .chat .chatter_box_container .chat_body .chat_massage_item .profile_part .chatter_profile img {
        height: 30px;
        width: 30px;
    }

    .chat .chatter_box_container .chat_body .chat_massage_item .chatter_chat .senting_massage {
        font-size: 14px;
        word-wrap: break-word;
    }

    .chat .chatter_box_container .chat_body .own_massage_item .own_chat .senting_massage {
        word-wrap: break-word;
    }

    .chat .chat_left .chat_list .chat_list_item .lastMassage-time {
        display: none;
    }

    .chat .chatter_box_container .chat_body .chat_massage_item .massage_D {
        display: none;
    }

    .chat .chatter_box_container .chat_body .own_massage_item .own_chat {
        max-width: 85%;
    }

    /* .chat .chatter_box_container {
       overflow: visible;
    } */
    .chat_emojy_picker {
        bottom: 60px !important;
        left: 5px !important;
    }
    .section_header_2{
         display: list-item;
         list-style: none;
    }
    .xs-none{
        display: none !important;
    }
    .lg-none{
      display: block !important;
    }
    .sm_none{
        display: none !important;
    }
    .magazine_top .top_left {
       
        display: none;
    }
    .invited_list_container {
        padding: 20px 12px 2px 12px;
    }
    .Card_container .card_wrap ul li {
        font-size: 15px;
        color: #333;
    }
    .display-list{
        display: list-item ;
        list-style: none ;
    }
}


/* Small None */

@media (min-width: 200px) and (max-width: 309.98px) {
    .mamber .mamber_wrapper .memberItem .user_info .join_date {
        display: none;
    }
}



@media (min-width: 480px) and (max-width: 576.98px) {
    .css-1oqqzyl-MuiContainer-root {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

@media (min-width: 900px) and (max-width: 1199.98px) {
    .home_banner {
        height: auto;
    }

    .entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_name {
        font-size: 16px;
    }

    .ConnectWith {
        display: none;
    }

    .postView_container .postView_container_Top .postViewModal_container_Image {
        height: 450px;
    }

    .comment_input_main {
        padding-left: 9px;
        padding-right: 9px;
    }

    .JobItemDetails .history_item .job_info .info_2 {
        display: none;
    }

    .JobItemDetails .history_item .job_info .info_1 {
        width: 100%;
    }

    .classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search {
        width: 400px;
        margin: 0 auto;
    }

    .mamber_hedingSIde .mamber_hedingSide_right .addMamber_btn {
        padding: 12px 20px;
        font-size: 14px;
    }

    .mamber .mamber_wrapper .memberItem .user_info .user_name {
        font-size: 16px;
        padding: 4px 0 0 0;
    }

    .mamber .mamber_wrapper .memberItem .user_info .join_date {
        padding-bottom: 4px;
    }

    .mamber .mamber_wrapper .memberItem .active_member {
        font-size: 11px;
        height: 32px;
    }

    .mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown {
        height: 31px;
        width: 18px;
    }

    .mamber .mamber_wrapper .memberItem {
        height: 250px;
    }

    .user_profile .cover-photo {
        height: 200px;
    }

    .user_profile .user_profile_content .content_top .user_profileImg {
        height: 120px;
        width: 120px;
        margin-top: -85px;
    }

    .user_profile .user_profile_content {
        padding: 8px 20px;
    }

    .user_profile .user_profile_content .content_top .profile-name {
        padding-left: 10px;
        font-size: 20px;
    }

    .user_profile .user_profile_content .content_top .profile-name .userDesignation {
        font-size: 13px;
        padding-top: 0px;
    }

    .user_profile .user_profile_content .userAbout {
        padding: 10px;
        font-size: 14px;
        line-height: 23px;
        margin-top: 16px;
    }

    .invited_list_container .table_content .table_content_item .table_Item_name .item_name {
        display: none;
    }

    .invited_list_container .table_content .table_content_item .table_Item_email {
        font-size: 13px;
    }

    .resource_item .resource_footer .res_footer_right .resource_date {
        display: none;
    }

    .section_headear .btns_row .Btn_one span {
        display: none;
    }

    .section_headear h4 {
        display: none;
    }

    .section_headear {
        justify-content: end;
        padding-bottom: 10px;
    }

    
    
}


@media (min-width: 900px) and (max-width: 1299.98px) {
    .MuiContainer-root {
        max-width: 1260px !important;
    }
}